import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Carousel, Image } from 'antd';

import { servicesRequest } from '../../Utils';
import { EVENT_DETAIL_URL } from '../../Utils/serviceUrl.utils';
import {
  CardEventContainer,
  Container,
  DetailCard,
  DetailDate,
  DetailDateValue,
  RegisterButton,
  Title
} from './EventDetail.styled';
import RegisterComponent from './Components/RegisterComponent.js';
import { checkValidURLImage } from '../../Utils/general.utils';

const EventDetailContainer = () => {
  const [event, setEvent] = React.useState(null);
  const [isRegister, setIsRegister] = React.useState(false);
  const { t } = useTranslation();

  const { id } = useParams();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(EVENT_DETAIL_URL + id);

      setEvent(data);
    } catch (e) {}
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  const title = get(event, 'title', 'Event Title');
  const content = get(event, 'content', 'Event Description');
  const date = get(event, 'event_date', new Date());
  const time = get(event, 'event_time', new Date());
  const createdAt = get(event, 'createdAt', new Date());
  const isActive = get(event, 'isActive', false);
  const status = get(event, 'status', false);
  const venue = get(event, 'venue', '');
  const price = get(event, 'price', 0);
  let banner = get(event, 'banner', []);
  let gallery = get(event, 'gallery', []);

  if (banner.length > 0 && banner !== '') {
    banner = banner.split(',');
  }

  if (gallery.length > 0) {
    gallery = gallery.split(',');
  }

  if (isRegister) {
    return (
      <RegisterComponent
        event={event}
        onSuccess={() => {
          setTimeout(() => {
            setIsRegister(false);
          }, [2000]);
        }}
      />
    );
  }

  return (
    <>
      {banner && banner.length > 0 && (
        <Carousel
          arrows={true}
          autoplay
          dots={true}
          className="align-items-center mb-3">
          {banner?.map((item, index) => (
            <img key={index} alt={index} src={checkValidURLImage(item)} />
          ))}
        </Carousel>
      )}

      <Container>
        {/*<Banner src={image} alt="img-banner" className="img-fluid" />*/}
        <CardEventContainer>
          <Col md={8}>
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {gallery && gallery.length > 0 && (
              <div className="container mt-5">
                <Image.PreviewGroup
                  preview={{
                    onChange: (current, prev) =>
                      console.log(
                        `current index: ${current}, prev index: ${prev}`
                      )
                  }}>
                  {gallery.map((item, index) => (
                    <Image width={200} height={200} src={item} key={index} />
                  ))}
                </Image.PreviewGroup>
              </div>
            )}
          </Col>
          <Col>
            <DetailCard>
              <Row>
                <Col md={12} lg={12} className="mb-3">
                  <Title>{title}</Title>
                </Col>
                <Col md={12} lg={12}>
                  <Row>
                    <Col md={4}>
                      <DetailDate className="fw-bolder">
                        {t('event-date')}
                      </DetailDate>
                    </Col>
                    <Col md={8}>
                      <DetailDateValue>
                        {moment(date).format('MMMM, Do YYYY')}
                      </DetailDateValue>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} lg={12}>
                  <Row>
                    <Col md={4}>
                      <DetailDate className="fw-bolder">
                        {t('event-time')}
                      </DetailDate>
                    </Col>
                    <Col md={8}>
                      <DetailDateValue>
                        {moment(time, 'HH:mm:ss').format('hh:mm A')}
                      </DetailDateValue>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} lg={12}>
                  <Row>
                    <Col md={4}>
                      <DetailDate className="fw-bolder">
                        {t('venue')}
                      </DetailDate>
                    </Col>
                    <Col md={8}>
                      <DetailDateValue>{venue}</DetailDateValue>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} lg={12}>
                  <Row>
                    <Col md={4}>
                      <DetailDate className="fw-bolder">
                        {t('published-date')}
                      </DetailDate>
                    </Col>
                    <Col md={8}>
                      <DetailDateValue>
                        {moment(createdAt).format('MMMM, Do YYYY')}
                      </DetailDateValue>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} lg={12}>
                  <Row>
                    <Col md={4}>
                      <DetailDate className="fw-bolder">
                        {t('price')}
                      </DetailDate>
                    </Col>
                    <Col md={8}>
                      <DetailDateValue>HK${price}</DetailDateValue>
                    </Col>
                  </Row>
                </Col>
                {isActive && status && (
                  <Col md={12} lg={12}>
                    <div className="text-center">
                      <RegisterButton onClick={() => setIsRegister(true)}>
                        {t('register-now')}
                      </RegisterButton>
                    </div>
                  </Col>
                )}
              </Row>
            </DetailCard>
          </Col>
        </CardEventContainer>
      </Container>
    </>
  );
};

export default EventDetailContainer;

import styled, { css } from 'styled-components';
import { mobile } from '../../Assets/layout';

import { DisplayFlex } from '../../Assets/GlobalStyled';
import { BlogScreenImageExample } from '../../Assets/Images';

export const Container = styled.div``;

export const ContentContainer = styled.div`
  padding: 5vw;
`;

export const Image = styled.div`
  background-image: url(${BlogScreenImageExample});
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  margin-bottom: 5vh;
`;

export const FirstTitle = styled.h6`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #cd9f51;
`;

export const SecondTitle = styled.h3`
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
`;

export const Description = styled.p`
  margin: 1.1em 0 0 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #667085;
`;

export const SearchContainer = styled(DisplayFlex)`
  margin-top: 5vh;
  justify-content: center;
  gap: 1vw;
  ${mobile(css`
    flex-direction: column;
  `)};
`;

export const SearchInput = styled.input`
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 1.5vh 1em;
  width: 25%;

  ::-webkit-input-placeholder {
    color: #667085;
  }

  :-ms-input-placeholder {
    color: #667085;
  }

  &:focus {
    outline: none;
    border: 1px solid #c99843;
  }
  ${mobile(css`
    width: 100%;
  `)};
`;

export const SearchButton = styled.button`
  background: #c99843;
  border: 1px solid #ffffff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 1.5vh 1.3em;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
   ${mobile(css`
   margin-top: 10px;
    width: 100%;
  `)};
`;

import { css } from 'styled-components';

const size = {
  mobile: '480px',
  tablet: '768px',
  laptop: '1026px',
  desktop: '2560px',
  largeDesktop: '6000px'
};

export const mobile = inner => css`
  @media (max-width: ${size.mobile}) {
    ${inner};
  }
`;

export const largeDesktop = inner => css`
  @media (min-width: ${size.largeDesktop}) {
    ${inner};
  }
`;

export const tablet = inner => css`
  @media (max-width: ${size.tablet}) {
    ${inner};
  }
`;

export const laptop = inner => css`
  @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
    ${inner};
  }
`;

export const desktop = inner => css`
  @media (min-width: ${size.laptop}) and (max-width: ${size.desktop}) {
    ${inner};
  }
`;

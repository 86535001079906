import React from 'react';
import { useParams } from 'react-router-dom';

import { servicesRequest } from '../../Utils';
import { BLOG_DETAIL_URL } from '../../Utils/serviceUrl.utils';
import { getContent } from '../../Utils/general.utils';

import {
  Container,
  Content,
  Readtime,
  Title,
  Image,
  Writer
} from './BlogDetail.styled';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const BlogDetailScreen = () => {
  const [blog, setBlog] = React.useState(null);
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(BLOG_DETAIL_URL + id);

      setBlog(data);
    } catch (e) {}
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [id]);

  React.useEffect(() => {
    const adjustImageSize = () => {
      const images = document.querySelectorAll('.blog-detail-screen img');
      images.forEach(image => {
        image.style.width = '90vw';
        image.style.height = 'auto';
      });
    };

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        adjustImageSize();
      } else {
        // Reset image size for non-mobile screens
        const images = document.querySelectorAll('.blog-detail-screen img');
        images.forEach(image => {
          image.style.width = '';
          image.style.height = '';
        });
      }
    };

    window.addEventListener('resize', handleResize);
    adjustImageSize(); // Call the function immediately after adding the event listener

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      <Title className="mb-4">{blog?.title}</Title>
      <Image src={blog?.thumbnail} className="img-fluid" />
      <p className="mt-2">
        <Writer>{blog?.author}</Writer>{' '}
        <Readtime>- {blog?.readtime_estimation} {t('mins-read')}</Readtime>
      </p>
      <Content
        className="mt-4 blog-detail-screen"
        dangerouslySetInnerHTML={{ __html: getContent(blog, i18n.language) }}
        />
    </Container>
  );
};

export default BlogDetailScreen;

import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Col } from 'react-bootstrap';
import { Checkbox } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import i18n from '../../../i18n';
import {
  Container,
  InputContainer,
  LabelForm,
  RegisterButton,
  TextInput,
  Title
} from '../EventDetail.styled';
import { servicesRequest } from '../../../Utils';
import { EVENT_REGISTER_URL } from '../../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';

const COL = 2;
const TYPE = {
  PARAGRAPH: 'PARAGRAPH',
  MULTIPLE: 'MULTIPLE'
};

const RegisterComponent = ({ event, onSuccess }) => {
  const dynamicForm = get(event, 'dynamicForm', []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const onRegister = async data => {
    const payload = { eventId: event.id, guestData: data };
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.post(EVENT_REGISTER_URL, payload);

      showNotification(
        'Your application has been sent. We will email the details soon'
      );
      onSuccess();
    } catch (error) {
      showNotification('Sorry, application failed to sent. Please try again');
    }
  };

  const renderParagraph = item => {
    return (
      <TextInput
        type="text"
        placeholder={item?.name}
        {...register(item?.name, { required: item?.isRequired })}
        error={errors?.[item?.name]}
      />
    );
  };

  const renderMultipleChoice = item => {
    let option = get(item, 'option', '');
    option = option.split(',');

    const onChange = checkedValues => {
      setValue(item?.name, checkedValues);
    };

    return <Checkbox.Group options={option} onChange={onChange} />;
  };

  return (
    <Container>
      <Title className="text-center">{t('event-registration-form')}</Title>

      {dynamicForm?.map((item, index) => {
        return (
          <InputContainer key={index}>
            <Col xs={COL}>
              <LabelForm>{item?.name}</LabelForm>
            </Col>
            <Col>
              {item?.type === TYPE.PARAGRAPH
                ? renderParagraph(item)
                : renderMultipleChoice(item)}
            </Col>
          </InputContainer>
        );
      })}
      <div className="text-center">
        <RegisterButton onClick={handleSubmit(onRegister)}>
          {t('register-now')}
        </RegisterButton>
      </div>
    </Container>
  );
};

export default RegisterComponent;

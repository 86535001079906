import {
  FILTER_ACTIVE
} from '../Actions/action.types';

const filterActiveReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_ACTIVE:
      return {filterActive: action.payload}
    default:
      return state
  }
};
export default filterActiveReducer;

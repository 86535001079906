import styled, { css } from 'styled-components';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';

import { Col, Button } from 'react-bootstrap';

export const StyledSlider = styled(Slider)({
  color: '#cda257',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    height: '7px',
    backgroundColor: '#cda257'
  },
  '& .MuiSlider-rail': {
    height: '7px;',
    backgroundColor: '#cda257',
    opacity: '0.3'
  },
  '& .MuiSlider-thumb': {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '2px solid #cda257',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
});


export const TextFieldStyled = styled(TextField)`
  width: 100%;
  & .MuiInputBase-input {
    border-radius: 4px;
    position: relative;
    border: 1px solid #cda257;
    font-size: 12px;
    width: auto;
    min-width: 60px;
    padding: 5px;
  }
  & .MuiInput-underline:before {
    border-bottom: none !important;
  }
  & .MuiInput-underline:after {
    border-bottom: none !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    color: #000000;
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
  -moz-appearance: textfield;
`;

export const ColRight = styled(Col)`
  text-align: right;
`;

export const SliderContainer = styled(Col)`
  padding-right: 10px;
`;

export const ButtonStyled = styled(Button)`
  background: #cda257;
  border: 1px solid #cda257;
  margin-right: -30px;
  padding: 2px;
  &:hover,
  &:focus {
    background-color: #cda257;
    border: 1px solid #cda257;
    opacity: 0.9;
  }
`;

export const InputPrefixWrapper = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-right: 5px;
  }
`;

export const SmalLabel = styled.span`
  font-size: 12px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexItem = styled.div`
  flex: 0 0 10%;
  padding-right: 1rem;
  padding-left: 1rem;
`;

export const FlexInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SmallLabel = styled.label`
`;

export const TextFieldStyled2 = styled.input`
`;

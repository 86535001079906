import styled, { css } from 'styled-components';
import Card from 'react-bootstrap/Card';

import { DisplayFlex } from '../../Assets/GlobalStyled';
import { mobile } from '../../Assets/layout';
import { BlogScreenImageExample } from '../../Assets/Images';
import { EventBanerImageExample } from '../../Assets/Images';

export const Container = styled.div`
  padding: 30px 0px;
`;

export const SectionTitle = styled.h1`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  color: #13101e;

  ${mobile(css`
    font-size: 18px;
  `)};
`;

export const BannerCard = styled(Card)`
  border-radius: 0;
  border-width: 0;
  width: 100%;
`;

export const BannerImg = styled(Card.Img)`
  border-radius: 0;
`;

export const BannerOverlay = styled(Card.ImgOverlay)`
  top: unset;
  text-align: center;
`;

export const Section = styled(DisplayFlex)`
${mobile(css`
    justify-content: center;
`)};
`;

export const RightSide = styled(DisplayFlex)`
  cursor: pointer;
`;

export const View = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #cda257;

  ${mobile(css`
    font-size: 14px;
  `)};
`;

export const Image = styled.div`
  background-image: url(${BlogScreenImageExample});
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5vh;
`;
export const ImageBanner = styled.div`
  background-image: url(${EventBanerImageExample});
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;

  margin-bottom: 5vh;
  ${mobile(css`
    height: 14vh;
  `)};
`;

export const CardTitleStyled = styled(Card.Title)`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 33.6774px;
  line-height: 45px;
  text-align: center;

  color: #ffffff;
`;

export const StyledFilterButton = styled.button`
  background: white;
  border-radius: 40.6px;
  padding: 3px 10px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  color: #666462;
  border: none;
  &.active {
    background: #cda257;
    color: white;
  }
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  padding: 20px;
`;

import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Container,
  Description,
  Image,
  JoinLabel,
  Title,
  TitleContainer,
  DescriptionContainer
} from './EventCard.styled';
import Icon from '../Icon';
import { limitOutputString, removeTags } from '../../Utils/general.utils';
import { ROUTE } from '../../Constants';

const EventCardComponent = ({ event = {} }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const id = get(event, 'id', '');
  const image = get(event, 'image', '');
  const title = get(event, 'title', '');
  const content = get(event, 'content', '');

  return (
    <Container onClick={() => navigate(`/${ROUTE.event}/${id}`)}>
      <Image image={image} />
      <TitleContainer>
        <Title>{limitOutputString(title, 35, true)}</Title>
      </TitleContainer>
      <DescriptionContainer>
        <Description>
          {limitOutputString(removeTags(content), 70, true)}
        </Description>
      </DescriptionContainer>
      <Button>
        <JoinLabel>{t('joint-now')}</JoinLabel>
        <Icon
          IconShow={IoIosArrowForward}
          style={{ color: '#FFFFFF', size: 18 }}
        />
      </Button>
    </Container>
  );
};

export default EventCardComponent;

import React from 'react';
import { Table } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import { useDispatch } from 'react-redux';
import { servicesRequest } from '../../Utils';
import { PRODUCT_CART_URL } from '../../Utils/serviceUrl.utils';
import {
  ActionCartButton,
  ContentLabel,
  ContentLabelBold,
  ContentLabelLine
} from '../ProductCard/ProductCard.styled';
import './Product.styled.scss';
import { BsCart3 } from 'react-icons/bs';
import Icon from '../Icon';


const iconStyle = {
  color: 'white',
  className: 'global-class-name',
  size: '1.5em'
};

const ProductCardListComponent = ({ data, carts = [] }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));
  React.useEffect(() => { }, [carts, data]);
  const addToCart = async product_code => {
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.post(PRODUCT_CART_URL, {
        product_code: product_code,
        qty: 1
      });
      showNotification(`${t('successfully-added-cart')}`);
    } catch (e) {
      showNotification('Sorry, we unable to add to cart. Please try again');
    }
  };

  const mapping = () => {
    return data?.map(item => {
      return {
        ...item,
        key: item.id
      };
    });
  };

  const columns = [
    {
      title: `${t('code-no')}`,
      dataIndex: 'code',
      key: 'code',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('name')}`,
      dataIndex: 'name_en',
      key: 'name_en',
      render: text => <ContentLabelBold>{text ? text : '-'}</ContentLabelBold>
    },
    {
      title: `${t('region')}`,
      key: 'region',
      dataIndex: 'region',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('appellation')}`,
      dataIndex: 'appellation',
      key: 'appellation',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('qty')}`,
      dataIndex: 'qty',
      key: 'qty',
      render: text => <ContentLabel>{JSON.parse(text).totalQty > 0 ? JSON.parse(text).totalQty : 0}</ContentLabel>
    },
    {
      title: `${t('price')}`,
      render: (_, record) => (
        <>
          <Row >
            <ContentLabelBold>HK$ {record.bprice}</ContentLabelBold>
          </Row>
          <Row >
            <ContentLabelLine>HK$ {record.aprice}</ContentLabelLine>
          </Row>
        </>
      )
    },
    {
      title: '',
      align: 'center',
      width: '10%',
      render: (_, record) => (
        <>
          <Row className='text-center'>
            <Col>
              <ActionCartButton
                className={carts?.includes(record.code) ? 'disabled' : ''}
                onClick={e => {
                  e.target.classList.add('disabled');
                  addToCart(record.code);
                }}>
                <Icon style={iconStyle} IconShow={BsCart3} />
              </ActionCartButton>
            </Col>
          </Row>
        </>
      )
    }
  ];
  return (
    <>
      <Table
        className="product-tabel pt-3"
        columns={columns}
        dataSource={mapping()}
        pagination={false}
        carts={carts}
        size="small"
        rowKey="code"
      />
    </>
  );
};

export default ProductCardListComponent;

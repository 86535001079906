import React from 'react';
import { Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TabContainer,
  TabDiv,
  TabStyledComponent,
  TabTitle,
  TopLabel
} from './Account.styled';
import './Account.style.scss';
import UpdateProfileComponent from './Components/UpdateProfile.component';
import OrderComponent from './Components/Order.component';
import { ROUTE } from '../../Constants';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import OrderDetailComponent from './Components/OrderDetail.component';

const TAB = {
  ORDER_TAB: 'ORDER_TAB',
  PROFILE_TAB: 'PROFILE_TAB'
};

const AccountScreen = () => {
  const [showOrderDetails, setShowOrderDetails] = React.useState(null);

  const { defaultTab = TAB.ORDER_TAB } = useParams();
  const { paymentTraceId = '' } = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const [activeTab, setActiveTab] = React.useState(defaultTab);
  const [profileData, setProfileData] = React.useState([]);

  const navigate = useNavigate();

  const TAB_ORDERS = [
    {
      name: t('profile'),
      navigate: TAB.PROFILE_TAB
    },
    {
      name: t('orders'),
      navigate: TAB.ORDER_TAB
    }
  ];

  React.useEffect(() => {
    if (paymentTraceId !== '') {
      showNotification('Sorry, your payment is failed. Please try again');

      setTimeout(() => {
        navigate(`/${ROUTE.cart}`);
      }, 3000);
    }

    const token = localStorage.getItem('token') || '';
    if (token) {
      const decodedToken = jwt_decode(token);
      setProfileData(decodedToken);
    }
  }, []);

  const componentProps = () => ({
    showOrderDetails,
    setShowOrderDetails
  });

  return (
    <TabStyledComponent className="account-setting">
      <Row>
        <TopLabel>
          {t('member-name')} : {profileData.name}
        </TopLabel>
        <TopLabel>
          {t('membership-id')} : {profileData.id}
        </TopLabel>
      </Row>

      {showOrderDetails === null && (
        <>
          <TabContainer className="mt-3 mb-3">
            {TAB_ORDERS.map((tab, index) => (
              <TabDiv
                active={activeTab === tab.navigate}
                onClick={() => setActiveTab(tab.navigate)}
                key={index}>
                <TabTitle active={activeTab === tab.navigate}>
                  {tab.name}
                </TabTitle>
              </TabDiv>
            ))}
          </TabContainer>

          <div className="mt-2">
            {activeTab === TAB.PROFILE_TAB && <UpdateProfileComponent />}
            {activeTab === TAB.ORDER_TAB && (
              <OrderComponent {...componentProps()} />
            )}
          </div>
        </>
      )}

      {showOrderDetails !== null && (
        <OrderDetailComponent {...componentProps()} />
      )}
    </TabStyledComponent>
  );
};

export default AccountScreen;

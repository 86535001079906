// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './Locales/en.json'; // English translations
import translationCN from './Locales/cn.json'; // Chinese translations

const resources = {
  en: {
    translation: translationEN
  },
  cn: {
    translation: translationCN
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Set the default language here
  fallbackLng: 'en', // Use English if translation for current language is unavailable
  interpolation: {
    escapeValue: false // React already escapes values to prevent XSS
  }
});

export default i18n;

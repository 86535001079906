import styled, { css } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { desktop, laptop, mobile, tablet } from '../../Assets/layout';

export const Container = styled.div`
  border-radius: 5px;
`;

export const ProductNameEn = styled.h3`
  'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #000000;
  text-align: center;

  ${mobile(css`
    text-align: center;
    font-size: 12px !important;
    line-height: 13px;
  `)};
`;

export const ProductNameList = styled.h3`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16.3591px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #000000;
  text-align: left;
`;

export const ProductPrice = styled.p`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #000000;
  text-align: center;
  ${mobile(css`
    font-size: 12px;
    line-height: 0px;
  `)};
`;

export const ProductDicountPrice = styled.p`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 0px;
  letter-spacing: 0.04em;
  color: grey;
  text-align: center;
  ${mobile(css`
    font-size: 12px;
    line-height: 0px;
  `)};
`;

export const ProductLabel = styled.h3`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 0px;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: center;
  padding-top: 0px;
  ${mobile(css`
    line-height: 0px;
    font-size: 10px;
  `)};
`;

export const ProductLabel4 = styled.h3`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 0px;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: center;
  padding-top: 4px;
  ${mobile(css`
    line-height: 0px;
    font-size: 10px;
  `)};
`;

export const ProductLabel2 = styled.h3`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #000000;
  text-align: center;
  padding-top: 2px;
  ${mobile(css`
    line-height: 10px;
    font-size: 10px !important;
  `)};
`;

export const ProductLabel3 = styled.h3`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  letter-spacing: 0.06em;
  color: #000000;
  text-align: center;
  padding: 2px 10px 0px 10px;
  ${mobile(css`
    line-height: 10px;
    font-size: 10px !important;
  `)};
`;
export const ProductLabel2Container = styled.div`
  ${mobile(css`
    max-width: 70px;
  `)};
`;
export const ProductLabel2Container2 = styled.div`
  ${mobile(css`
    max-width: 70px;
  `)};
`;

export const ProductPriceList = styled.p`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #000000;
  text-align: left;
`;

export const ProductCategory = styled.span`
  color: #969696;
`;

export const ProductImage = styled.div`
  background-color: #efeee8;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 17px 17px 0 0;

  height: 300px;
  object-fit: cover;
  margin-bottom: 1vh;

  ${mobile(css`
    height: 205px;
  `)};
`;
export const ProductImageList = styled.div`
  background-color: #efeee8;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 16.7554px;
  height: 250px;
  object-fit: cover;
  margin-bottom: 1vh;
`;

export const WishListButtonContainer = styled.div`
  position: relative;
`;

export const WishlistButton = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  text-align: center;

  ${desktop(css`
    top: 0.5em;
    right: 0.6em;
    width: 2.8em;
    height: 2.8em;
    padding: 0.5em;
  `)};

  ${laptop(css`
    top: 0.5em;
    right: 0.6em;
    width: 2.5em;
    height: 2.5em;
    padding: 0.35em 0.3em;
  `)};

  ${tablet(css`
    top: 0.5em;
    right: 0.6em;
    width: 2.5em;
    height: 2.5em;
    padding: 0.35em 0.3em;
  `)};

  ${mobile(css`
    top: 0.5em;
    right: 0.6em;
    width: 2.5em;
    height: 2.5em;
    padding: 0.4em 0.3em;
  `)};
`;

export const IconWishContainer = styled.div`
  color: #cda257;
  svg {
    font-size: 30px;
  }
`;

export const ActionButton = styled.button`
  background: #cda257;
  border: 1px solid #cda257;
  border-radius: 0 0 17px 17px;
  color: #ffff;
  padding: 3px;
  width: 100%;

  ${mobile(css`
    font-size: 11px;
  `)};
`;

export const ActionButtonList = styled.button`
  background: #cda257;
  border: 1px solid #cda257;
  border-radius: 5px;
  color: #ffff;
  padding: 3px;
  width: 100%;
`;

export const ActionCartButton = styled.button`
  background: #cda257;
  border: 1px solid #cda257;
  border-radius: 5px;
  color: #ffff;
  padding: 3px;
  width: 40%;
`;

export const ActionButtonDetail = styled.button`
  background: transparent;
  border: 1px solid #cda257;
  border-radius: 5px;
  color: #cda257;
  padding: 3px;
  width: 100%;
`;

export const CardListContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const ContentLabel = styled.p`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #000000;
`;

export const ContentLabelLine = styled.p`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #000000;
  text-decoration-line: line-through;
`;

export const ContentLabelBold = styled.p`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #000000;
`;

export const ContentLabelHeader = styled.p`
  font-family: 'Encode Sans Condensed', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0 !important;
  color: #000000;
`;

export const BorderedList = styled(Row)`
  padding-top: 15px;
  margin-top: 15px;
  border-top: 0.5px solid #cda257;
`;

export const LeftColumnButton = styled(Col)`
  padding-right: 0;
`;

export const ActionButtonContainer = styled(Col)`
  position: absolute;
  bottom: -32px;
  left: -4px;
  width: 100%;

  ${mobile(css`
    bottom: -24px;
  `)};
`;

export const ProductImageContainer = styled.div`
  position: relative;
`;

export const ProductNameContainer = styled.div`
  height: 45px;
  ${mobile(css`
    height: 65px;
  `)};
`;

export const StyledLinkContainer = styled.div`
  padding-top: 30px;
`;

import React from 'react';
import { Col, Pagination, Row } from 'antd';
import { get } from 'lodash';

const PaginationComponent = ({ data, onChangePage }) => {
  const totalItem = get(data, 'total_item', 10);
  const pageSize = get(data, 'itemperpage', 6);
  const onChange = page => {
    onChangePage(page);
  };

  return (
    <Row className="mt-4 justify-content-center">
      <Col>
        <Pagination
          showSizeChanger={false}
          total={totalItem}
          onChange={onChange}
          pageSize={pageSize}
        />
      </Col>
    </Row>
  );
};

export default PaginationComponent;


import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Container,
  FormContainer,
  Input,
  Title
} from '../SignIn/SignIn.styled';
import {
  Disclaimer,
  Label,
  LoginLabel,
  ModalBody,
  ModalButton,
  ModalText,
  SpinnerContainer
} from './SignUp.styled';
import { servicesRequest } from '../../Utils';
import {
  SIGN_UP_URL,
  SIGN_IN_URL,
  PRODUCT_CART_URL
} from '../../Utils/serviceUrl.utils';
import { StyledHref } from '../../Assets/GlobalStyled';
import { ROUTE } from '../../Constants';
import { resetCartPayload } from '../../Redux/Actions/carts.action';

const DuplicateAccountMessage =
  'Registration unsuccessful. Our record shows that your email is already registered as a member. Please proceed to login page page to login your account. Please click forget password if you have forgotten your password. ';

const SignUpScreen = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const { pageSource = '' } = useParams();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const SuccessMessage = `${t('registration-successful')}`;

  const resetToCartPayload = () => dispatch(resetCartPayload());
  const reduxState = useSelector(state => state.cartsReducer);

  const formSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Mobile no is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password length should be at least 8 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .min(8, 'Password length should be at least 8 characters')
      .oneOf([Yup.ref('password')], 'Passwords do not match')
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema)
  });

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      const response = await request.post(SIGN_UP_URL, data);
      if (response.status === 200 && pageSource === 'cart') {
        const email = data.email;
        const password = data.password;
        const timer = setTimeout(() => {
          setShowLoading(true);
          signIn(email, password);
        }, 2000);
        return () => clearTimeout(timer);
      }
      setIsError(false);
      handleShow();
    } catch (error) {
      console.error(error);
      setIsError(true);
      handleShow();
    }
  };

  const signIn = async (email, password) => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { token }
        }
      } = await request.post(SIGN_IN_URL, { email, password });

      localStorage.setItem('token', token);
      if (pageSource === 'cart') {
        await migrateCartsStateToDB();
        navigate('/' + ROUTE.cart);
      } else {
        navigate('/' + ROUTE.account);
      }
      window.dispatchEvent(new Event('isLogin'));
    } catch (error) {
      if (error.response.data.message === 'User account is not active') {
        setShowNotActivated(true);
      } else {
        handleShow();
      }
    }
  };

  const migrateCartsStateToDB = async () => {
    try {
      let { cartsContainer: tempLocalCartsContainer } = reduxState;

      tempLocalCartsContainer = JSON.parse(tempLocalCartsContainer);
      const carts = tempLocalCartsContainer?.carts;
      if (carts?.length > 0) {
        carts?.map(async cart => {
          const authorizedRequest = await servicesRequest();
          await authorizedRequest.post(PRODUCT_CART_URL, {
            product_code: cart?.products?.code,
            qty: cart?.qty
          });
        });
      }

      resetToCartPayload();
    } catch (e) {}
  };

  const routeToLoginCondition =
    pageSource === '' ? '/sign-in' : '/sign-in/cart';

  return (
    <Container>
      {showLoading && (
        <SpinnerContainer>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </SpinnerContainer>
      )}
      <Title>{t('register-winebuff-account')}</Title>
      <FormContainer className="gy-2 justify-content-center">
        <Input
          error={errors?.email}
          type="email"
          placeholder={`*${t('email')}`}
          {...register('email')}
        />
        <Input
          error={errors?.name}
          type="text"
          placeholder={`*${t('name')}`}
          {...register('name')}
        />
        <Input
          error={errors?.password}
          type="password"
          placeholder={`*${t('password')}`}
          {...register('password')}
        />
        <Input
          error={errors?.confirmPassword}
          type="password"
          placeholder={`*${t('confirm-password')}`}
          {...register('confirmPassword')}
        />
        <Input
          error={errors?.phone}
          type="text"
          placeholder={`*${t('mobile-no')}`}
          {...register('phone')}
        />

        <Disclaimer>{t('by-registration-you-will')}</Disclaimer>

        <Button onClick={handleSubmit(onSubmit)}>{t('register')}</Button>

        <Label>
          {t('already-have-account')}{' '}
          <LoginLabel href={routeToLoginCondition}>{t('sign-in')}</LoginLabel>
        </Label>
      </FormContainer>

      <Modal show={show} onHide={handleClose} centered>
        <ModalBody>
          <ModalText>
            {isError ? DuplicateAccountMessage : SuccessMessage}
          </ModalText>
          <div className="text-center">
            <ModalButton onClick={handleClose} className="text-center">
              <StyledHref href={routeToLoginCondition}>
                {t('login-now')}
              </StyledHref>
            </ModalButton>
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default SignUpScreen;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { SlClose } from 'react-icons/sl';

import NavbarDesktopComponent from './Navbar.desktop.component';
import NavbarMobileComponent from './Navbar.mobile.component';
import { Footer } from '../Footer';
import { ROUTE } from '../../Constants';
import { WinebuffLogo } from '../../Assets/Images';
import {
  BodyCenter,
  ButtonPopup,
  DynamicBanner,
  ImagePopup,
  LargeImageContainer,
  PopupButtonContainer,
  PopupTextBody,
  PopupTextFooter
} from './Navbar.styled';
import { servicesRequest } from '../../Utils';
import { BANNER_URL } from '../../Utils/serviceUrl.utils';
import Icon from '../Icon';

const iconStyle = {
  color: '#000000',
  className: 'global-class-name',
  size: '1.5em'
};

const NavbarComponent = ({ children }) => {
  const [disclaimerBanner, setDisclaimerBanner] = React.useState(false);
  const [notificationMemberBanner, setNotificationMemberBanner] =
    React.useState(false);
  const [bannerData, setBannerData] = React.useState([]);
  const [displayBannerData, setDisplayBannerData] = React.useState(false);
  const [bannerOrders, setBannerOrders] = React.useState([]);

  const disclaimerBannerStatus = localStorage.getItem('pop_status');
  const notificationBannerStatus = localStorage.getItem(
    'notification_banner_status'
  );

  React.useEffect(() => {
    const checkLocalStorage = setInterval(() => {
      const disclaimerBannerStatus = localStorage.getItem('pop_status');
      const notificationBannerStatus = localStorage.getItem(
        'notification_banner_status'
      );

      if (!disclaimerBannerStatus) {
        setDisclaimerBanner(true);
        localStorage.setItem('pop_status', 1);
      }

      if (disclaimerBannerStatus === '1') {
        setDisclaimerBanner(true);
      } else {
        setDisclaimerBanner(false);
      }

      if (!notificationBannerStatus) {
        setNotificationMemberBanner(true);
        localStorage.setItem('notification_banner_status', 1);
      }

      if (notificationBannerStatus === '1') {
        setNotificationMemberBanner(true);
      } else {
        setNotificationMemberBanner(false);
      }
    }, 2000);

    return () => clearInterval(checkLocalStorage);
  }, []);

  React.useEffect(() => {
    if (!disclaimerBannerStatus) {
      setDisclaimerBanner(true);
      localStorage.setItem('pop_status', 1);
    }

    if (disclaimerBannerStatus === '1') {
      setDisclaimerBanner(true);
    } else {
      setDisclaimerBanner(false);
    }
  }, [disclaimerBannerStatus]);

  React.useEffect(() => {
    if (!notificationBannerStatus) {
      setNotificationMemberBanner(true);
      localStorage.setItem('notification_banner_status', 1);
    }

    if (notificationBannerStatus === '1') {
      setNotificationMemberBanner(true);
    } else {
      setNotificationMemberBanner(false);
    }
  }, [notificationBannerStatus]);

  const redirectToLogin = (routes, requiredLogin = true) => {
    if (requiredLogin && localStorage.getItem('token')) {
      return `/${routes}`;
    }

    if (requiredLogin && !localStorage.getItem('token')) {
      return `/${ROUTE.signIn}`;
    }

    if (!requiredLogin) {
      return `/${routes}`;
    }
  };

  const { total: cartsTotal } = useSelector(state => state.cartsReducer);

  const closeDisclaimerBannerPopup = () => {
    localStorage.setItem('pop_status', 0);
    setDisclaimerBanner(false);
  };

  const cancelDisclaimerBannerPopup = () => {
    window.location.replace('https://www.google.com/');
  };

  const closeNotificationBannerPopup = () => {
    localStorage.setItem('notification_banner_status', 0);
    setNotificationMemberBanner(false);
  };

  const props = () => ({
    redirectToLogin,
    cartsTotal
  });

  const getBanner = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(BANNER_URL + '/eligible');

      setBannerData(data);
      createOrderingModal(data);
    } catch (error) {}
  };

  const NotificationBanner = () => {
    return (
      <Modal
        show={notificationMemberBanner}
        fullscreen={true}
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Body>
          <BodyCenter>
            <div>
              <LargeImageContainer>
                <ImagePopup src={WinebuffLogo} />
              </LargeImageContainer>
              <br />
              <div>
                <PopupTextBody>
                  歡迎來到酒悅有限公司全新的網站！
                  我們誠邀您成為新會員，而現有會員需重新註冊。如果您是我們現有會員，請在註冊過程中輸入您之前登記的電話號碼。謝謝！{' '}
                  <br />
                  Welcome to the brand new website of Wine Buff Ltd. We
                  cordially invite you to register and become our new member. If
                  you are our existing member, please use your previous
                  registered phone number to re-register, thank you!
                </PopupTextBody>
              </div>
              <PopupButtonContainer>
                <ButtonPopup
                  onClick={() => {
                    closeNotificationBannerPopup();
                  }}>
                  Enter Website / 進入網站
                </ButtonPopup>
              </PopupButtonContainer>
            </div>
          </BodyCenter>
        </Modal.Body>
      </Modal>
    );
  };

  const DisclaimerBanner = () => {
    return (
      <Modal
        show={disclaimerBanner}
        fullscreen={true}
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Body>
          <BodyCenter>
            <div>
              <LargeImageContainer>
                <ImagePopup src={WinebuffLogo} />
              </LargeImageContainer>
              <br />
              <div>
                <PopupTextBody>
                  Under the law of Hong Kong, intoxicating liquor must not be
                  sold or supplied to a minor in the course of business. <br />
                  根據香港法律，不得在業務過程中，向未成年人售賣或供應令人醺醉的酒類
                </PopupTextBody>
                <PopupTextFooter>
                  ARE YOU OVER THE AGE OF 18? <br />
                  你是否已年滿18歲?
                </PopupTextFooter>
              </div>
              <PopupButtonContainer>
                <ButtonPopup
                  onClick={() => {
                    closeDisclaimerBannerPopup();
                  }}>
                  Yes / 是
                </ButtonPopup>
                <ButtonPopup
                  onClick={() => {
                    cancelDisclaimerBannerPopup();
                  }}>
                  No / 否
                </ButtonPopup>
              </PopupButtonContainer>
            </div>
          </BodyCenter>
        </Modal.Body>
      </Modal>
    );
  };

  const closeBannerModal = bannerId => {
    localStorage.setItem(
      `banner_dismissed_${bannerId}`,
      moment().format('YYYY-MM-DD HH:mm:ss')
    );
    setBannerData(prevData =>
      prevData.filter(banner => banner.id !== bannerId)
    );
    createOrderingModal(bannerData);
  };

  const handleSeeMore = (bannerId, actionUrl) => {
    closeBannerModal(bannerId);
    window.open(actionUrl, '_blank');
  };

  const checkShowCondition = bannerId => {
    const status = localStorage.getItem(`banner_dismissed_${bannerId}`);

    if (status === null || !status) {
      return true;
    }

    const bannerTime = moment(status, 'YYYY-MM-DD HH:mm:ss');
    const currentTime = moment();

    const duration = moment.duration(currentTime.diff(bannerTime));
    const diffInHours = duration.asHours();

    return diffInHours >= 1;
  };

  const createOrderingModal = data => {
    let modalToShow = [];
    for (const item of data) {
      if (checkShowCondition(item.id)) {
        modalToShow.push({
          ...item,
          showModal: true
        });
        break;
      }
    }

    setBannerOrders(modalToShow);
  };

  React.useEffect(() => {
    getBanner()
      .then(() => {
        setTimeout(() => {
          setDisplayBannerData(true); // Set to true after a 5-second delay
        }, 5000); // 5 seconds delay
      })
      .catch(console.error);
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col md={12} sm={12} xl={12}>
          <NotificationBanner />
          <DisclaimerBanner />
          {displayBannerData &&
            bannerOrders.map(banner => (
              <Modal
                key={banner.id}
                centered
                show={banner.showModal}
                fullscreen={false}
                className="custom-modal"
                aria-labelledby={`banner-modal-${banner.id}`}>
                <Modal.Body>
                  <div>
                    <div>
                      <LargeImageContainer>
                        <DynamicBanner src={banner.image} alt={banner.name} />
                      </LargeImageContainer>
                    </div>
                    {banner?.actionUrl && (
                      <PopupButtonContainer className="mt-2">
                        <ButtonPopup
                          onClick={() => {
                            handleSeeMore(banner.id, banner.actionUrl);
                          }}>
                          See More
                        </ButtonPopup>
                      </PopupButtonContainer>
                    )}
                  </div>
                </Modal.Body>
                <div
                  onClick={() => {
                    closeBannerModal(banner.id);
                  }}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    cursor: 'pointer'
                  }}>
                  <Icon style={iconStyle} IconShow={SlClose} />
                </div>
              </Modal>
            ))}
          <NavbarDesktopComponent {...props()} />
          <NavbarMobileComponent {...props()} />
        </Col>
      </Row>
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default NavbarComponent;

import styled, { css } from 'styled-components';
import { Row } from 'react-bootstrap';

import { laptop, mobile } from '../../Assets/layout';

export const Container = styled.div`
  padding: 2em 10em;

  ${mobile(css`
    padding: 4vw;
  `)};

  ${laptop(css`
    padding: 2vw;
  `)};
`;

export const Banner = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin-bottom: 1em;
`;

export const Title = styled.h3`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 700;
  ${mobile(css`
    padding-top: 20px;
  `)};
`;

export const DetailDate = styled.p`
  margin: 0;
  line-height: 2em;
`;

export const DetailDateValue = styled.p`
  margin: 0;
  color: #625b71;
  line-height: 2em;
`;

export const RegisterButton = styled.button`
  border: none;
  background: #cda257;
  border-radius: 8px;
  color: #ffffff;
  padding: 6px 8px 6px 8px;
  font-size: 16px;
  margin-top: 0.9em;
  text-align: center;
  width: 100%;

  ${mobile(css`
    width: 90%;
  `)};
`;

export const TextInput = styled.input`
  background: #e0e0e0;
  opacity: 0.5;
  border-radius: 3px;
  border: ${props => (props.error ? '1px solid #DA2F24' : 'none')};
  width: 100%;
  padding: 0.5em;

  &:focus {
    outline: none;
  }
`;

export const LabelForm = styled.p`
  font-family: 'ABeeZee', sans-serif;
  font-style: italic;
  font-weight: 400;
  color: #324158;
  opacity: 0.8;
  margin: 0;
  padding: 0.5em;
`;

export const InputContainer = styled(Row)`
  justify-items: center;
  margin: 1em 0;
`;

export const CardEventContainer = styled(Row)`
  ${mobile(css`
    flex-direction: column-reverse;
  `)};
`;

export const DetailCard = styled.div`
  border-radius: 8px;
  border: 1px;
  padding: 16px;
  background-color: #f9fafb;
`;

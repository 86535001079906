import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  Img,
  MoveToWishlist,
  Name,
  Price,
  ProductCartMobileContainer,
  ProductCounter,
  StyledInput,
  PriceStrike,
  Remove,
  QuantityContainer
} from './ProductCart.styled';
import { PlusMinus } from '../../Screens/ProductDetail/ProductDetail.styled';
import { COUNTER_TYPE } from '../../Constants';
import { checkJWT } from '../../Utils/general.utils';

const ProductCartMobileComponent = ({
  quantity,
  counterQuantity,
  setCounterQuantity,
  handleDelete,
  handleMoveToWishlist,
  productName,
  productPrice,
  memberPrice,
  productImage
}) => {
  const { t } = useTranslation();
  return (
    <ProductCartMobileContainer>
      <Row>
        <Col xs={5}>
          <Img src={productImage} className="img-fluid" alt="image" />
        </Col>
        <Col xs={7}>
          <Name>{productName}</Name>
          <Price>${productPrice}</Price>
          <PriceStrike>${memberPrice}</PriceStrike>
          <QuantityContainer>
            <ProductCounter>
              <PlusMinus onClick={() => counterQuantity(COUNTER_TYPE.SUBTRACT)}>
                -
              </PlusMinus>
              <StyledInput
                value={quantity}
                onChange={e =>
                  setCounterQuantity(COUNTER_TYPE.SET, e.target.value)
                }
              />
              <PlusMinus onClick={() => counterQuantity(COUNTER_TYPE.ADD)}>
                +
              </PlusMinus>
            </ProductCounter>
          </QuantityContainer>

          <Row>
            {!checkJWT() && (
              <MoveToWishlist onClick={handleMoveToWishlist}>
                {t('move-to-wishlist')}
              </MoveToWishlist>
            )}
          </Row>
          <Row>
            {!checkJWT() && (
              <Remove onClick={handleDelete}>{t('remove')}</Remove>
            )}
          </Row>
        </Col>
      </Row>
    </ProductCartMobileContainer>
  );
};

export default ProductCartMobileComponent;

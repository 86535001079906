import styled, { css } from 'styled-components';
import { Col, Navbar, Row } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Ratio from 'react-bootstrap/Ratio';
import { HiMenuAlt3 } from 'react-icons/hi';
import { FiSearch } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { mobile, tablet } from '../../Assets/layout';

export const NavbarMobileContainer = styled.div`
  ${mobile(css`
    display: block;
  `)};
  ${tablet(css`
    display: block;
  `)};
`;

export const NavbarDesktopContainer = styled.div`
  ${mobile(css`
    display: none;
  `)};
  ${tablet(css`
    display: none;
  `)};
`;

export const Container = styled(Row)`
  background: white;
  padding: 3vh 4vw;
  align-items: center;
`;
export const RowContainer = styled(Row)`
  align-items: center;
  margin: 10px 30px;
`;
export const RowContainer2 = styled(Row)`
  align-items: center;
`;
export const RowContainer3 = styled(Row)`
  align-items: center;
  justify-content: center;
`;

export const Image = styled.div`
  border-radius: 13.6272px 13.6272px 0 0;
  width: 100%;
  height: 70px;
  object-fit: cover;
  background-size: contain;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

export const FlagImage = styled.div`
  width: 30px;
  height: 30px;
  object-fit: cover;
  background-size: contain;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;
export const ImageLarge = styled.div`
  border-radius: 13.6272px 13.6272px 0 0;
  width: 100%;
  height: 150px;
  object-fit: cover;
  background-size: contain;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

export const LargeImageContainer = styled.div`
  text-align: center;
`;

export const BodyCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  padding: 20px;
  box-shadow: rgb(0 0 0 / 44%) 0 1px 16px;
  box-sizing: border-box;
`;

export const Input = styled.input`
  padding: 2vh 0;
  outline: 0;
  color: #000000;
  font-size: 17px;
  width: 100%;
  background-color: transparent;
  border: none;

  ::-webkit-input-placeholder {
    color: #000000;
    opacity: 0.8;
  }
  :-ms-input-placeholder {
    color: #000000;
    opacity: 0.8;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 0 10px;
  height: 36px;
  gap: 0.5em;
`;
export const InputContainer2 = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  padding: 0 10px;
  height: 36px;
`;
export const NavbarLinkTitle2 = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  padding: ${props => (props.padding ? '' : '0 10px')};
  color: #000000;
  font-family: 'Inter', sans-serif;
  text-decoration: none !important;
  &:hover {
    color: #c99843;
  }
`;

export const NavbarLinkTitleWrapper = styled.span`
  /* styles for the link */
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #cda257;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  text-decoration: none !important;
  &:hover {
    color: #cda257;
  }

  &.active {
    /* styles for the active link */
    color: #cda257;
    background: rgba(205, 162, 87, 0.1);
  }

  ${({ padding }) => (padding ? '' : 'padding: 5px 15px;')}
`;

export const NavbarLinkTitle4 = styled.span`
  /* styles for the link */
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #cda257;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  text-decoration: none !important;
  &:hover {
    color: #cda257;
  }

  &.active {
    /* styles for the active link */
    color: #cda257;
    background: rgba(205, 162, 87, 0.1);
  }
`;

export const NavbarLinkTitle5 = styled.span`
  /* styles for the link */
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #cda257;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  text-decoration: none !important;
  &:hover {
    color: #cda257;
  }

  &.active {
    /* styles for the active link */
    color: #cda257;
    background: rgba(205, 162, 87, 0.1);
  }
  ${mobile(css`
    font-size: 16px;
    color: black;
    font-weight: 400;
    margin-left: -10px;
  `)};
`;

export const TextLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 1.5px 10px;
  color: #000000;
  font-family: 'Inter', sans-serif;
  text-decoration: none !important;
  background: #f0f0f0;
  text-align: center;
`;

export const NavbarLinkTitleButton = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  padding: 0 10px;
  cursor: pointer;
  color: #000000;
  font-family: 'Inter', sans-serif;
  text-decoration: none !important;
  &:hover {
    color: #c99843;
  }
`;

export const NavbarLinkTitleDiv = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
`;
export const NavbarLinkTitleDiv2 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 650px;
  margin-bottom: -650px;
`;
export const NavbarLinkTitleDiv1 = styled.div`
  width: 100%;
  div {
    background: #f0f0f0;
  }
`;
export const NavbarLinkTitleDiv3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  background: #f0f0f0;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const NavbarLinkTitleDiv6 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 30px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-wrap: nowrap;
`;

export const TopIcon = styled.div`
  border-radius: 50%;
  color: white;
  padding: 1vh 1.2vh;
  font-size: 14px;
  cursor: pointer;
`;

export const TopNavbar = styled.div`
  text-align: center;
  width: 100vw;
  padding-bottom: 10px;
  padding-top: 10px;
`;

export const StyledNavbar = styled(Navbar)`
  background: #cda257;
  padding: 0;
`;

export const NavbarHeaderText = styled.span`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
`;

export const StyledRow = styled(Row)`
  width: 100%;
  align-items: center;
  background: #efeee8;
  padding: 0 0 0 20px;
`;

export const MobileTopCornerContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

export const NavbarLinkTitle3 = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 1.5px 10px;
  color: #000000;
  font-family: 'Inter', sans-serif;
  text-decoration: none !important;
  background: #f0f0f0;
  border-radius: 25px;
  cursor: pointer;
  margin: 0 5px;
  &:hover {
    color: #c99843;
  }
  min-width: 130px;
  text-align: center;
  &.active {
    color: #80001f;
    background: rgba(205, 162, 87, 0.39);
  }
`;

export const NavbarLinkTitle6 = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 1.5px 10px;
  color: #000000;
  font-family: 'Inter', sans-serif;
  text-decoration: none !important;
  background: #f0f0f0;
  border-radius: 25px;
  cursor: pointer;
  margin: 0 5px;
  &:hover {
    color: #c99843;
  }
  min-width: 130px;
  text-align: center;
  &.active {
    /* styles for the active link */
    color: #cda257;
    background: rgba(205, 162, 87, 0.1);
  }
`;

export const CustomIconContainer = styled.div`
  border-left: 10px solid transparent;
  border-bottom: 60px solid #cda257;
  position: relative;
  width: 60px;
  margin-right: -12px;
`;

export const CustomIconSubContainer = styled.div`
  position: absolute;
  margin-top: 12px;
  margin-left: 10px;
`;

export const StyledDropdownButton = styled(DropdownButton)`
  &:dropdown-toggle::after {
    display: none !important;
  }
`;

export const ButtonPopupWrapper = styled.button`
  display: flex;
  justify-content: center;
`;

export const ButtonPopup = styled.button`
  border: 1px solid #000;
  padding: 10px 30px;
  border-radius: 6px;
  color: #fff;
  background: #000;
  margin-left: 20px;
  text-decoration: none;
  font-family: Futura-Medium, Arial, Helvetica, sans-serif;
  font-size: 16px;
  transition: all 0.1s;
`;

export const ImagePopup = styled.img`
  max-width: 100%;
`;

export const DynamicBanner = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 3px;
`;

export const PopupTextBody = styled.p`
  font-size: 20px !important;
  margin: 0 0 18px !important;
`;

export const PopupTextFooter = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.2em;
  text-align: center;
`;

export const PopupButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileLogoContainer = styled.div`
  width: 100px;
`;

export const CustomTopIconContainer = styled.div`
  width: 30px;
  padding-top: 5px;
`;

export const CategoryLabel = styled.div`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
  padding-top: 30px;
`;

export const TopIconBag = styled.div`
  color: white;
  padding: 15px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  height: 58px;
  display: flex;
  align-items: center;
`;

export const TopIconBagMobile = styled.div`
  color: white;
  padding: 15px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  height: 58px;
  display: flex;
  align-items: center;
  transform-origin: left bottom;
  transform: skewX(-15deg);
  background-color: #cda257;

  overflow: hidden; /* Ensure that any slanted part of the background doesn't overflow */
`;

export const IconBagContainerMobile = styled.div`
  transform: skewX(15deg);
  padding-right: 8px;
`;

export const BagBadgeMobile = styled.div`
  background: red;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 8px;
  color: white !important;
  padding: 0 6px;
  width: 10px;
  position: absolute;
  top: 10px;
  right: 2px;
`;

export const BagBadge = styled.div`
  background: #cda257;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 8px;
  color: white !important;
  padding: 0 6px;
  width: 10px;
  position: absolute;
  top: 25px;
  right: 8px;
`;

export const StyledButton = styled.button`
  background: #4d1111;
  border: 1.5px solid #4d1111;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  padding: 0.3em 0.5em;
  margin: 0 5px;

  ${mobile(css`
    padding: 0.3em 0.5em;
    margin: 5px 0;
  `)};
`;

export const StyledButtonOutline = styled.button`
  background: white;
  border: 1.5px solid #4d1111;
  border-radius: 5px;
  color: #4d1111;
  font-size: 12px;
  padding: 0.3em 0.5em;
  margin: 0 5px;

  ${mobile(css`
    padding: 0.3em 0.5em;
    margin: 5px 0;
  `)};
`;

export const ButtonGroupStyled = styled(ButtonGroup)`
  width: 100vw;
  padding: 0 30px;
`;

export const StyledCol = styled(Col)`
  padding: 0;
`;

export const IconSearchContainer = styled.div`
  opacity: 0.2px;
  color: black;
`;

export const IconWrapper = styled.div`
  margin-left: -25px;
  padding-right: 5px;
  padding-top: 5px;
  margin-bottom: -20px !important;
  ${mobile(css`
    margin-left: -10px;
  `)};
`;

export const NavButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomEmbed = styled.embed`
  height: 30px;
  width: 25px;
`;

export const CustomRatio = styled(Ratio)`
  height: 30px;
  width: 25px;
`;

export const CustomNavbarToggle = styled(Navbar.Toggle)`
  border: none;
  outline: none;
`;

export const RotatedIcon = styled(HiMenuAlt3)`
  transform: rotate(180deg);
  font-size: 25px;
`;

export const StyledButtonOutline2 = styled.div`
  background: #f9fafb;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  color: #4d1111;
  font-size: 16px;
  padding: 13px 20px;
  margin: 10px;
  width: 95vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextContainer = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MiddleIcon = styled(FiSearch)`
  margin-right: 10px;
  color: grey;
`;

export const RightIcon = styled(MdKeyboardArrowRight)`
  margin-left: auto;
  font-size: 25px;
  color: grey;
`;

export const CustomDropdownToggle = styled(Dropdown.Toggle)`
  background: transparent;
  border: none;
  &::after {
    display: none;
  }
`;

export const CustomDropdownItem = styled(Dropdown.Item)``;

export const CustomDropdownMenu = styled(Dropdown.Menu)``;

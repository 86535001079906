import React from 'react';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import { Container, Title } from '../Cart/Cart.styled';
import { ProductCard } from '../../Components';
import { servicesRequest } from '../../Utils';
import {
  PRODUCT_CART_URL,
  PRODUCT_WISHLIST_WITH_PRODUCT_URL
} from '../../Utils/serviceUrl.utils';
import { AddToCartButton } from './Wishlist.styled';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';

const WishlistScreen = () => {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const addToCart = async id => {
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.post(PRODUCT_CART_URL, {
        product_code: id,
        qty: 1
      });
      showNotification('Successfully added to cart');
    } catch (e) {
      showNotification('Sorry, failed to added to cart. Please try again');
    }
  };

  const _renderProducts = () => {
    if (products.length === 0 && isLoading) {
      return <ShimmerSimpleGallery col={4} imageHeight={300} caption />;
    }

    return products.map((item, index) => {
      return (
        <Col xs={6} sm={3} md={3} lg={3} xl={3} key={index}>
          <ProductCard data={item} isWithWishlistButton={false} />
          <AddToCartButton onClick={() => addToCart(item?.code)}>
            {t('add-to-cart')}
          </AddToCartButton>
        </Col>
      );
    });
  };

  const fetchingWishlist = async () => {
    try {
      setIsLoading(true);
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(PRODUCT_WISHLIST_WITH_PRODUCT_URL);

      setProducts(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchingWishlist();
  }, []);

  return (
    <Container>
      <Title className="mb-5">{t('my-wishlist')}</Title>
      <Row className=" gy-3">{_renderProducts()}</Row>
    </Container>
  );
};

export default WishlistScreen;

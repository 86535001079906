import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import {
  Purchasereward,
  Purchasereward2,
  Purchasereward3,
  Purchasereward4,
  ImageFrame,
  AvatarPhoto,
  Glasses,
  LinePoint,
  NorthPoint,
  Wellington,
  MembershipBaner
} from '../../Assets/Images';
import Image from 'react-bootstrap/Image';
import { Row, Col } from 'react-bootstrap';

import {
  BannerCard,
  BannerImg,
  BannerOverlay,
  CardTitleStyled,
  Container,
  ImageBanner,
  Section,
  SectionTitle,
  ImageStyled,
  MembarCardContainer,
  TextContentContainer,
  TitleContent,
  SubTitleContent,
  MemberLineContainer,
  MemberLineContainer1,
  MemberLineContainer2,
  MemberLineContainer3,
  MemberLineContainer4,
  StrongLabel,
  AvatarPhotoContainer,
  AftarFrameContainer,
  TextInfoContainer,
  TitleInfo,
  SubTitleInfo,
  GlasesContainer,
  MemberLineBottomContainer,
  LinePointContainer,
  LineDotContainer,
  SideVertical,
  LabelUnderImage,
  MemberLineGaleryContainer,
  MainTitle,
  SubMainTitle,
  SectionLineContainer,
  SectionLine,
  SloganText,
  SubSloganText,
  JointButton
} from './Membership.styled';

const MembershipContainer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <BannerCard className="text-white">
        <BannerImg src={MembershipBaner} alt="Card image" />
        <BannerOverlay>
          <CardTitleStyled>Membership</CardTitleStyled>
        </BannerOverlay>
      </BannerCard>

      <Section className="px-3 mt-5 mb-5">
        <MainTitle>Wine Buff <br /> Membership</MainTitle>
      </Section>
      <Section className="px-3 mt-5 mb-5">
        <SubMainTitle>Ultrices phasellus arcu congue rhoncus odio quis in.   <br /> Risus lorem arcu dictum</SubMainTitle>
      </Section>
      <SectionLineContainer>
        <SectionLine>

        </SectionLine>
      </SectionLineContainer>
      <SloganText>
        phasellus arcu , a slogan
      </SloganText>
      <SubSloganText>
        Aliquam maecenas aenean aliquam nunc velit etiam sapien. Dictumst viverra molestie cras auctor.
      </SubSloganText>
      <Section className="px-3 mt-5 mb-0 text-center">
        <JointButton onClick={() => {
          navigate('/sign-in');
        }}>
           {t('joint-now')}
        </JointButton>
      </Section>
      <MembarCardContainer>
        <MemberLineContainer1>
          <Col md={6} lg={6} sm={12} xs={12}>
            <ImageStyled src={Purchasereward} />
          </Col>
          <Col md={6} lg={6} sm={12} xs={12}>
            <TextContentContainer>
              <TitleContent>Purchase Reward</TitleContent>
              <SubTitleContent>
                Hinata Childcare Club Reiwa <br /> 4th year admission reception{' '}
                <br /> (secondary)
              </SubTitleContent>
            </TextContentContainer>
          </Col>
        </MemberLineContainer1>
        <MemberLineContainer2>
          <Col md={6} lg={6} sm={12} xs={12}>
            <TextContentContainer>
              <TitleContent>Purchase Reward</TitleContent>
              <SubTitleContent>
                Hinata Childcare Club Reiwa <br /> 4th year admission reception
                (secondary)
              </SubTitleContent>
            </TextContentContainer>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12}>
            <ImageStyled src={Purchasereward2} />
          </Col>
        </MemberLineContainer2>
        <MemberLineContainer3>
          <Col md={6} lg={6} sm={12} xs={12}>
            <ImageStyled src={Purchasereward3} />
          </Col>
          <Col md={6} lg={6} sm={12} xs={12}>
            <TextContentContainer>
              <TitleContent>Purchase Reward</TitleContent>
              <SubTitleContent>
                Hinata Childcare Club Reiwa <br /> 4th year admission reception
                (secondary)
              </SubTitleContent>
            </TextContentContainer>
          </Col>
        </MemberLineContainer3>
        <MemberLineContainer4>
          <Col md={6} lg={6} sm={12} xs={12}>
            <TextContentContainer>
              <TitleContent>Purchase Reward</TitleContent>
              <SubTitleContent>
                Hinata Childcare Club Reiwa <br /> 4th year admission reception
                (secondary)
              </SubTitleContent>
            </TextContentContainer>
          </Col>
          <Col md={6} lg={6} sm={12} xs={12}>
            <ImageStyled src={Purchasereward4} />
          </Col>
        </MemberLineContainer4>
        <MemberLineContainer>
          <StrongLabel className="text-center">
            What our customer have said
          </StrongLabel>
        </MemberLineContainer>
        <MemberLineBottomContainer>
          <Col></Col>
          <Col md={8} lg={8} sm={12} xs={12}>
            <div>
              <AftarFrameContainer>
                <div>
                  <ImageStyled src={ImageFrame} />
                </div>
                <AvatarPhotoContainer>
                  <ImageStyled src={AvatarPhoto} />
                </AvatarPhotoContainer>
                <TextInfoContainer>
                  <TitleInfo>
                    Johnny <br />
                    Schmitt Manso
                  </TitleInfo>
                  <SubTitleInfo>
                    Id tincidunt est elit, est risus et integer <br />dolor quam.  Consectetur mattis nam<br /> consequat  non feugiat mauris<br />  pharetra, amet morbi.
                  </SubTitleInfo>
                </TextInfoContainer>
                <GlasesContainer>
                  <ImageStyled src={Glasses} />
                </GlasesContainer>
              </AftarFrameContainer>
            </div>
          </Col>
          <Col></Col>
        </MemberLineBottomContainer>
        <MemberLineGaleryContainer>
          <StrongLabel className="text-center">
            Our Private Club
          </StrongLabel>
        </MemberLineGaleryContainer>
        <LineDotContainer>
          <LinePointContainer className="text-center">
            <ImageStyled src={LinePoint} />
          </LinePointContainer>
        </LineDotContainer>
        <MemberLineGaleryContainer>
          <Col>
            <SideVertical>
              Our campus
            </SideVertical>
          </Col>
          <Col md={6} lg={6}>
            <div>
              <div>
                <ImageStyled src={Wellington} />
              </div>
              <LabelUnderImage>
                Wellington Club
              </LabelUnderImage>
            </div>
          </Col>
          <Col></Col>
        </MemberLineGaleryContainer>
        <MemberLineGaleryContainer>
          <Col md={4} lg={4}>
          </Col>
          <Col md={6} lg={6}>
            <div>
              <div>
                <ImageStyled src={NorthPoint} />
              </div>
              <LabelUnderImage>
                North Point Wine Shops
              </LabelUnderImage>
            </div>
          </Col>
          <Col></Col>
        </MemberLineGaleryContainer>
      </MembarCardContainer>
    </Container>
  );
};

export default MembershipContainer;

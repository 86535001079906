import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row } from 'react-bootstrap';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CancelButton,
  CloseIcon,
  Input,
  ModalParagraph,
  SavedAddressLabel
} from '../../SignIn/SignIn.styled';
import { servicesRequest } from '../../../Utils';
import { ADDRESS_URL, PROFILE_URL } from '../../../Utils/serviceUrl.utils';
import AddAddressComponent from './AddAddress.component';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import {
  DeliveryEstimation,
  ProfileAddress,
  ProfileAddressContainer
} from '../../Cart/Cart.styled';
import { DisplayFlex } from '../../../Assets/GlobalStyled';
import UpdatePasswordComponent from './UpdatePassword.component';

const UpdateProfileComponent = () => {
  const [modal, setModal] = React.useState(false);
  const [logoutModal, setLogoutModal] = React.useState(false);
  const [addresses, setAddresses] = React.useState([]);
  const [addressToEdit, setAddressToEdit] = React.useState(null);
  const [changePasswordModal, setChangePasswordModal] = React.useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();


  const openModalChangePassword = () => {
    setChangePasswordModal(true);
  };

  const closeModalChangePassword = () => {
    setChangePasswordModal(false);
  };

  const handleLogout = () => {
    setLogoutModal(false);
    localStorage.removeItem('token');
    localStorage.removeItem('pop_status');
    window.dispatchEvent(new Event('isLogout'));
    navigate('/sign-in');
  };

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Mobile no is required'),
    email: Yup.string()
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema)
  });

  const fetchProfile = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: {
          data: { email, phone, name }
        }
      } = await authorizedRequest.get(PROFILE_URL);

      setValue('email', email);
      setValue('phone', phone);
      setValue('name', name);
    } catch (error) {}
  };

  const fetchAddress = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(ADDRESS_URL);
      setAddresses(data);
    } catch (error) {}
  };
  React.useEffect(() => {
    fetchProfile().catch(console.error);
    fetchAddress().catch(console.error);
  }, []);

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      await request.post(PROFILE_URL, data);
      showNotification('Profile updated');
    } catch (error) {
      showNotification('Sorry, we unable to update Profile. Please try again');
    }
  };

  const addAddressProps = () => ({
    modal,
    setModal,
    addresses,
    setAddresses,
    fetchAddress,
    addressToEdit
  });

  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <h5>{t('update-profile')}</h5>
          <hr />
          <div className="gy-2 p-2">
            <Input
              disabled={true}
              className="mb-1"
              type="email"
              placeholder={t('email')}
              {...register('email')}
            />
            <Input
              className="mb-1"
              error={errors?.name}
              type="text"
              placeholder={t('name')}
              {...register('name')}
            />
            <Input
              className="mb-1"
              error={errors?.phone}
              type="text"
              placeholder={t('mobile-number')}
              {...register('phone')}
            />

            <DisplayFlex>
              <Button className="mt-2" onClick={handleSubmit(onSubmit)}>
                {t('update-profile')}
              </Button>

              <CancelButton className="mt-2" onClick={openModalChangePassword}>
                {t('update-password')}
              </CancelButton>
            </DisplayFlex>
            <hr />

            <CancelButton onClick={() => setLogoutModal(true)}>
              {t('logout')}
            </CancelButton>
          </div>
        </Col>
        <Col>
          <SavedAddressLabel>{t('save-address')}</SavedAddressLabel>
          <hr />
          <Row className="g-1">
            {addresses?.map((item, index) => (
              <Col md={4} key={index}>
                <ProfileAddressContainer
                  isDefault={item?.isDefault}
                  onClick={() => {
                    setAddressToEdit(item);
                    setModal(true);
                  }}
                  className="px-2">
                  <ProfileAddress>
                    <DeliveryEstimation className="m-0">
                      {item?.addressLine}
                    </DeliveryEstimation>
                    <DeliveryEstimation className="m-0">
                      {item?.district}
                    </DeliveryEstimation>
                  </ProfileAddress>
                </ProfileAddressContainer>
              </Col>
            ))}
          </Row>
          <Button className="mt-2" onClick={() => setModal(true)}>
            {t('add-new-address')}
          </Button>
        </Col>
      </Row>
      <Modal
        footer={[]}
        title={addressToEdit === null ? `${t('add-new-address')}` : `${t('update-address')}`}
        open={modal}
        onCancel={() => {
          setModal(false);
          setAddressToEdit(null);
        }}>
        <AddAddressComponent {...addAddressProps()} />
      </Modal>
      <Modal
        footer={[]}
        title={t('update-password')}
        open={changePasswordModal}
        onCancel={closeModalChangePassword}>
        <UpdatePasswordComponent onClose={closeModalChangePassword} />
      </Modal>
      <Modal
        footer={[
          <div className="text-center">
            <CancelButton onClick={() => setLogoutModal(false)}>
              {t('cancel')}
            </CancelButton>
            ,
            <Button key="submit" type="primary" onClick={() => handleLogout()}>
              {t('ok')}
            </Button>
            ,
          </div>
        ]}
        closeIcon={<CloseIcon></CloseIcon>}
        open={logoutModal}
        onCancel={() => {
          setLogoutModal(false);
        }}>
        <ModalParagraph>{t('are-you-sure-logout')}</ModalParagraph>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateProfileComponent;

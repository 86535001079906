import styled, { css } from 'styled-components';

import { mobile } from '../../Assets/layout';

export const ContactContainer = styled.div`
  padding: 9vh 8vh;

  ${mobile(css`
    padding: 2em 1.5em;
  `)};
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  color: #333333;
  margin-bottom: 5vh;
`;

export const Description = styled.h3`
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  color: #333333;
  margin-bottom: 4vh;
`;

export const ContactLabel = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.019em;
  text-transform: uppercase;
  color: #1a191a;
  margin-bottom: 1vh;
`;

export const ContactValue = styled.a`
  display: block;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #1a191a;
  mix-blend-mode: normal;
  margin-bottom: 5vh;

  &:hover {
    color: #1a191a;
  }
`;
export const Content = styled.div``;

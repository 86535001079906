import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const DisplayFlex = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5vw;
`;

export const DisplayFlexRight = styled(DisplayFlex)`
  justify-content: right;
`;

export const DisplayFlexLeft = styled(DisplayFlex)`
  justify-content: left;
`;

export const DisplayFlexCenter = styled(DisplayFlex)`
  justify-content: center;
`;

export const DisplayFlexSpaceBetween = styled(DisplayFlex)`
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const StyledHref = styled.a`
  text-decoration: none;
  color: white;

  &:hover {
    color: white;
    opacity: 1;
  }
`;

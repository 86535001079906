import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Modal } from 'react-bootstrap';

import { servicesRequest } from '../../Utils';
import { AUTH_OTP_URL, RESET_PASSWORD_URL } from '../../Utils/serviceUrl.utils';
import {
  Button,
  Container,
  ForgotPasswordForm,
  FormContainer,
  Input,
  Title
} from '../SignIn/SignIn.styled';
import { passwordAndConfirmSchema } from '../../Utils/general.utils';
import { ModalBody } from '../SignUp/SignUp.styled';
import { ROUTE } from '../../Constants';

const ResetPasswordScreen = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { email, otp } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(passwordAndConfirmSchema)
  });

  const checkOTP = async () => {
    setIsLoading(true);
    try {
      const request = await servicesRequest();
      await request.post(AUTH_OTP_URL, { code: otp, email });
    } catch (error) {
      return navigate(`/${ROUTE.signIn}`);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async data => {
    const { password } = data;

    const payload = {
      password,
      email,
      code: otp
    };
    try {
      const request = await servicesRequest();
      await request.post(RESET_PASSWORD_URL, payload);

      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
    } finally {
      handleShow();
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    checkOTP();
  }, [email, otp]);

  // if (isLoading) {
  //   return <p>Still Loading</p>;
  // }

  const SuccessMessage = () => {
    return (
      <React.Fragment>
        <h4 className="text-center">Your password successfully updated</h4>

        <ForgotPasswordForm className="justify-content-center mt-4">
          <Button onClick={() => navigate('/sign-in')} className="mt-3">
            Sign In
          </Button>
        </ForgotPasswordForm>
      </React.Fragment>
    );
  };

  const ErrorMessage = () => {
    return (
      <React.Fragment>
        <h4 className="text-center">Sorry, your password failed to update</h4>

        <ForgotPasswordForm className="justify-content-center mt-4">
          <Button onClick={handleClose} className="mt-3">
            Close
          </Button>
        </ForgotPasswordForm>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Container>
        <Title>Reset Password</Title>
        <FormContainer className="gy-2 justify-content-center">
          <Input
            error={errors?.password}
            type="password"
            placeholder="Password"
            {...register('password')}
          />
          <Input
            error={errors?.confirmPassword}
            type="password"
            placeholder="Confirm Password"
            {...register('confirmPassword')}
          />

          <Button
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
            className="mt-4">
            Reset
          </Button>
        </FormContainer>
      </Container>

      <Modal show={show} onHide={handleClose} centered>
        <ModalBody>
          {isSuccess ? <SuccessMessage /> : <ErrorMessage />}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ResetPasswordScreen;

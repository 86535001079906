import styled from 'styled-components';

import { DisplayFlex } from '../../Assets/GlobalStyled';

export const Container = styled(DisplayFlex)`
  justify-content: space-between;
  align-items: center;
  align-content: center;
  cursor: pointer;
  padding: ${props => (props.type === 1 ? '1vh 0' : '3vh 0')};
  border-bottom: ${props =>
    props.show === true ? '' : '1.1px solid #969696;'};
`;

export const Title = styled.p`
  font-family: ${props =>
    props.type === 1 ? "'Inter', sans-serif" : "'Playfair Display', serif"};
  font-style: normal;
  font-weight: ${props => (props.type === 1 ? '500' : '600')};
  font-size: ${props => (props.type === 1 ? '14px' : '14px')};
  line-height: 20px;
  color: #13101e;
  margin-bottom: 0;
`;

import {
  FILTER_APPELLATION_PAYLOAD
} from '../Actions/action.types';

const filterAppellationReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_APPELLATION_PAYLOAD:
      return { filterAppellation: action.payload };
    default:
      return state
  }
};
export default filterAppellationReducer;

import sha256 from 'crypto-js/hmac-sha256';
import base64 from 'crypto-js/enc-base64';

const secretKey = process.env.REACT_APP_GLOBAL_PAY_SECRET_KEY;
const globalPayURL = process.env.REACT_APP_GLOBAL_PAY_URL;
const accessKey = process.env.REACT_APP_GLOBAL_PAY_ACCESS_KEY;
const profileId = process.env.REACT_APP_GLOBAL_PAY_PROFILE_ID;

export const GLOBAL_PAY_CREDENTIALS = {
  secretKey,
  globalPayURL,
  accessKey,
  profileId
};

export const sign = params => {
  return signData(buildDataToSign(params), secretKey);
};

const signData = (data, secretKey) => {
  let result = sha256(data, secretKey);
  return base64.stringify(result);
  // return sha256('sha256', secretKey).update(data).digest().toString('base64');
};

const buildDataToSign = params => {
  let string = '';

  Object.entries(params).map(([k, v]) => {
    string = `${string}${k}=${v},`;
  });

  string = string.slice(0, -1);

  return string;
};

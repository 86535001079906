import { IconContext } from 'react-icons';
import propTypes from 'prop-types';

const Icon = ({ style, IconShow }) => {
  return (
    <IconContext.Provider value={{ ...style }}>
      <IconShow />
    </IconContext.Provider>
  );
};

Icon.propTypes = {
  style: propTypes.any,
  IconShow: propTypes.any
};

export default Icon;

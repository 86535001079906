import { createBrowserRouter } from 'react-router-dom';

import {
  Blog,
  Images,
  News,
  Cart,
  ContactUs,
  FAQ,
  Home,
  Product,
  Wine,
  ProductDetail,
  Event,
  SignIn,
  SignUp,
  ResetPassword,
  BlogDetail,
  NewsDetail,
  ImagesDetail,
  NotFound,
  Portal,
  Wishlist,
  Account,
  EventDetail,
  ActivateAccount,
  Content,
  Burgundy,
  BurgundyDetail,
  Category,
  Membership
} from '../Screens';
import { menu, ROUTE } from '../Constants';
import { NavBar } from '../Components';
import GlobalPay from '../Screens/Redirect/GlobalPay';

const TYPE = {
  CONTENT: 'CONTENT'
};

const _renderWrapperComponent = (Component, type = '', data = {}) => {
  if (type === TYPE.CONTENT) {
    return (
      <NavBar>
        <Component type={data.value} />
      </NavBar>
    );
  }

  return (
    <NavBar>
      <Component />
    </NavBar>
  );
};

export const router = createBrowserRouter([
  {
    path: ROUTE.root,
    element: _renderWrapperComponent(Product)
  },
  {
    path: ROUTE.home,
    element: _renderWrapperComponent(Home)
  },
  {
    path: ROUTE.en,
    element: _renderWrapperComponent(Product)
  },
  {
    path: ROUTE.enShop,
    element: _renderWrapperComponent(Product)
  },
  {
    path: ROUTE.enContact,
    element: _renderWrapperComponent(ContactUs)
  },
  {
    path: ROUTE.enAbout,
    element: _renderWrapperComponent(Content, TYPE.CONTENT, menu[0])
  },
  {
    path: ROUTE.signIn,
    element: _renderWrapperComponent(SignIn)
  },
  {
    path: ROUTE.signIn + '/:pageSource',
    element: _renderWrapperComponent(SignIn)
  },
  {
    path: ROUTE.signUp,
    element: _renderWrapperComponent(SignUp)
  },

  {
    path: ROUTE.signUp + '/:pageSource',
    element: _renderWrapperComponent(SignUp)
  },
  {
    path: ROUTE.resetPassword,
    element: _renderWrapperComponent(ResetPassword)
  },
  {
    path: ROUTE.product,
    element: _renderWrapperComponent(Product)
  },
  {
    path: ROUTE.wine,
    element: _renderWrapperComponent(Wine)
  },
  {
    path: ROUTE.productDetail,
    element: _renderWrapperComponent(ProductDetail)
  },
  {
    path: ROUTE.blog,
    element: _renderWrapperComponent(Blog)
  },
  {
    path: ROUTE.images,
    element: _renderWrapperComponent(Images)
  },
  {
    path: ROUTE.imagesDetail,
    element: _renderWrapperComponent(ImagesDetail)
  },
  {
    path: ROUTE.news,
    element: _renderWrapperComponent(News)
  },
  {
    path: ROUTE.blogDetail,
    element: _renderWrapperComponent(BlogDetail)
  },
  {
    path: ROUTE.newsDetail,
    element: _renderWrapperComponent(NewsDetail)
  },
  {
    path: ROUTE.burgundy,
    element: _renderWrapperComponent(Burgundy)
  },
  {
    path: ROUTE.burgundyDetail,
    element: _renderWrapperComponent(BurgundyDetail)
  },
  {
    path: ROUTE.contactUs,
    element: _renderWrapperComponent(ContactUs)
  },
  {
    path: ROUTE.faq,
    element: _renderWrapperComponent(FAQ)
  },
  {
    path: ROUTE.cart,
    element: _renderWrapperComponent(Cart)
  },
  {
    path: ROUTE.wishlist,
    element: _renderWrapperComponent(Wishlist)
  },
  {
    path: ROUTE.event,
    element: _renderWrapperComponent(Event)
  },
  {
    path: ROUTE.eventDetail,
    element: _renderWrapperComponent(EventDetail)
  },
  {
    path: ROUTE.portal,
    element: _renderWrapperComponent(Portal)
  },
  {
    path: ROUTE.activateAccount,
    element: _renderWrapperComponent(ActivateAccount)
  },
  {
    path: ROUTE.category,
    element: _renderWrapperComponent(Category)
  },
  {
    path: ROUTE.aboutUs,
    element: _renderWrapperComponent(Content, TYPE.CONTENT, menu[0])
  },
  {
    path: ROUTE.returnPolicy,
    element: _renderWrapperComponent(Content, TYPE.CONTENT, menu[1])
  },
  {
    path: ROUTE.termOfUse,
    element: _renderWrapperComponent(Content, TYPE.CONTENT, menu[2])
  },
  {
    path: ROUTE.privacyPolicy,
    element: _renderWrapperComponent(Content, TYPE.CONTENT, menu[3])
  },
  {
    path: ROUTE.eprCompliance,
    element: _renderWrapperComponent(Content, TYPE.CONTENT, menu[4])
  },
  {
    path: ROUTE.wineCellar,
    element: _renderWrapperComponent(Content, TYPE.CONTENT, menu[5])
  },
  {
    path: '*',
    element: _renderWrapperComponent(NotFound)
  },
  {
    path: ROUTE.account,
    element: _renderWrapperComponent(Account)
  },
  {
    path: ROUTE.account + '/:paymentTraceId/failed',
    element: _renderWrapperComponent(Account)
  },
  {
    path: ROUTE.membership,
    element: _renderWrapperComponent(Membership)
  },
  {
    path: 'redirect/:purchaseId/:total',
    element: <GlobalPay />
  }
]);

import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  ContentLabel,
  ContentLabelBold
} from '../ProductCard/ProductCard.styled';
import './WineList.styled.scss';

const WineListComponent = ({ data }) => {
  const { t } = useTranslation();

  const mapping = () => {
    return data?.map(item => {
      return {
        ...item,
        key: item.id
      };
    });
  };

  const columns = [
    {
      title: `${t('code-no')}`,
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code - b.code,
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('price')}`,
      dataIndex: 'aprice',
      key: 'aprice',
      sorter: (a, b) => a.aprice - b.aprice,
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('member-price')}`,
      dataIndex: 'bprice',
      key: 'bprice',
      sorter: (a, b) => a.bprice - b.bprice,
      render: text => <ContentLabelBold>{text}</ContentLabelBold>
    },
    {
      title: `${t('vintage')}`,
      dataIndex: 'vintage',
      key: 'vintage',
      sorter: (a, b) => {
        const isAscending = true; // Set to false for descending sort
        const aValue = a.vintage;
        const bValue = b.vintage;
        const aIsNumber = typeof aValue === 'number';
        const bIsNumber = typeof bValue === 'number';
        const aIsEmptyString = aValue === '';
        const bIsEmptyString = bValue === '';

        if (aIsEmptyString && bIsEmptyString) {
          return 0;
        } else if (aIsEmptyString) {
          return isAscending ? 1 : -1;
        } else if (bIsEmptyString) {
          return isAscending ? -1 : 1;
        } else if (aIsNumber && bIsNumber) {
          return isAscending ? aValue - bValue : bValue - aValue;
        } else if (aIsNumber) {
          return isAscending ? -1 : 1;
        } else if (bIsNumber) {
          return isAscending ? 1 : -1;
        } else {
          const aStr = aValue.toString();
          const bStr = bValue.toString();
          return isAscending
            ? aStr.localeCompare(bStr)
            : bStr.localeCompare(aStr);
        }
      },
      render: text => <ContentLabelBold>{text}</ContentLabelBold>
    },
    {
      title: `${t('description')}`,
      dataIndex: 'name_en',
      key: 'name_en',
      sorter: (a, b) => {
        const descriptionA = a.name_en || '';
        const descriptionB = b.name_en || '';
        return descriptionA.localeCompare(descriptionB);
      },
      render: text => <ContentLabelBold>{text ? text : '-'}</ContentLabelBold>
    },
    {
      title: `${t('qty')}`,
      key: 'qty',
      dataIndex: 'qty',
      sorter: (a, b) => {
        const qtyA = JSON.parse(a.qty).totalQty || 0;
        const qtyB = JSON.parse(b.qty).totalQty || 0;
        return qtyA - qtyB;
      },
      render: text => (
        <ContentLabel>
          {JSON.parse(text).totalQty > 0 ? JSON.parse(text).totalQty : 0}
        </ContentLabel>
      )
    },
    {
      title: `${t('size-ml')}`,
      key: 'size',
      dataIndex: 'size',
      sorter: (a, b) => a.size - b.size,
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('country')}`,
      key: 'country',
      dataIndex: 'country',
      sorter: (a, b) => {
        const countryA = a.country || '';
        const countryB = b.country || '';
        return countryA.localeCompare(countryB);
      },
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('region')}`,
      key: 'region',
      dataIndex: 'region',
      sorter: (a, b) => {
        const regionA = a.appellation || '';
        const regionB = b.appellation || '';
        return regionA.localeCompare(regionB);
      },
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('appellation')}`,
      dataIndex: 'appellation',
      key: 'appellation',
      sorter: (a, b) => {
        const appellationA = a.appellation || '';
        const appellationB = b.appellation || '';
        return appellationA.localeCompare(appellationB);
      },
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('rating')}`,
      key: 'rating',
      dataIndex: 'rating',
      sorter: (a, b) => {
        const ratingA = a.country || '';
        const ratingB = b.country || '';
        return ratingA.localeCompare(ratingB);
      },
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('category')}`,
      key: 'category_label',
      dataIndex: 'category_label',
      render: text => <ContentLabel>{text}</ContentLabel>
    }
  ];

  return (
    <>
      <Table
        className="product-tabel2 pt-3"
        columns={columns}
        dataSource={mapping()}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 50,
          pageSizeOptions: ['10', '20', '50', '100', '200']
        }}
        size="small"
        rowKey="code"
      />
    </>
  );
};

export default WineListComponent;

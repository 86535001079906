import React from 'react';
import { useForm } from 'react-hook-form';

import { Button, ForgotPasswordForm, Input } from '../SignIn.styled';
import { servicesRequest } from '../../../Utils';
import { FORGOT_PASSWORD_URL } from '../../../Utils/serviceUrl.utils';

const ForgotPasswordComponent = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSent, setIsSent] = React.useState({
    status: false,
    success: false
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async data => {
    try {
      setIsLoading(true);
      const request = await servicesRequest();
      await request.post(FORGOT_PASSWORD_URL, data);
      setIsSent({ status: true, success: true });
    } catch (error) {
      setIsSent({ status: true, success: false });
    } finally {
      setIsLoading(false);
    }
  };

  const MessageComponent = () => {
    if (!isSent.success) {
      return (
        <div>
          <h5 className="text-center">Sorry, your account is not found</h5>
        </div>
      );
    }
    return (
      <div>
        <h5 className="text-center">
          Confirmation link has been sent to your email. Please check and click
          to reset your password
        </h5>
      </div>
    );
  };

  return (
    <div>
      {isSent.status ? (
        <MessageComponent />
      ) : (
        <React.Fragment>
          <h4 className="text-center">Forgot Password</h4>
          <ForgotPasswordForm className="justify-content-center mt-4">
            <Input
              error={errors?.email}
              type="email"
              placeholder="Email"
              {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
            />
            <Button
              disabled={isLoading}
              onClick={handleSubmit(onSubmit)}
              className="mt-3">
              Forgot Password
            </Button>
          </ForgotPasswordForm>
        </React.Fragment>
      )}
    </div>
  );
};

export default ForgotPasswordComponent;

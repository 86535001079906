import {
  FILTER_NEW_DATA_PAYLOAD
} from '../Actions/action.types';

const filterNewDataReducer = (state = true, action) => {
  switch (action.type) {
    case FILTER_NEW_DATA_PAYLOAD:
      return {filterNewData: action.payload}
    default:
      return state
  }
};
export default filterNewDataReducer;

import styled, { css } from 'styled-components';
import { Row } from 'react-bootstrap';

import { DisplayFlex } from '../../Assets/GlobalStyled';
import { desktop, laptop, mobile } from '../../Assets/layout';
import { Table } from 'antd';

export const Container = styled.div`
  margin: 5em 0;
  position: relative;

  ${mobile(css`
    margin: 3em 0;
  `)};
`;

export const FormContainer = styled(Row)`
  padding: 0 10em;

  ${desktop(css`
    padding: 0 30em;
  `)};

  ${laptop(css`
    padding: 0 10em;
  `)};

  ${mobile(css`
    padding: 0 2em;
  `)};
`;

export const Title = styled.h1`
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #3e2f24;
  margin-bottom: 1em;
`;

export const Input = styled.input`
  background: #ffffff;
  opacity: 0.8;
  border: ${props =>
    props.error ? '1px solid #DA2F24' : '1px solid #3e2f24;'};
  border-radius: 5px;
  width: 100%;
  padding: 0.3em 0.5em;
  display: block;

  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 500;
    color: #3e2f24;
  }

  :-ms-input-placeholder {
    font-weight: 500;
    color: #3e2f24;
  }
`;

export const SoftInput = styled(Input)`
  border: 1px solid #d9d9d9;
  padding: 0.5em 0.5em;
`;

export const TextArea = styled.textarea`
  background: #ffffff;
  opacity: 0.8;
  border: ${props =>
    props.error ? '1px solid #DA2F24' : '1px solid #3e2f24;'};
  border-radius: 5px;
  width: 100%;
  padding: 0.3em 0.5em;
  display: block;

  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 500;
    color: #3e2f24;
  }

  :-ms-input-placeholder {
    font-weight: 500;
    color: #3e2f24;
  }
`;

export const SoftTextArea = styled(TextArea)`
  border: 1px solid #d9d9d9;
  padding: 0.5em 0.5em;
`;

export const SecondContainer = styled(DisplayFlex)`
  justify-content: space-between;
`;

export const Label = styled.p`
  padding: 0;
`;

export const ForgotPassword = styled(Label)`
  cursor: pointer;
`;

export const Button = styled.button`
  background: #4d1111;
  border: 1.5px solid #4d1111;
  border-radius: 5px;
  color: #fff;
  padding: 0.3em 0.5em;
  width: 40%;

  ${mobile(css`
    padding: 0.3em 0.5em;
    width: 50%;
  `)};
`;

export const CancelButton = styled.button`
  background: transparent;
  border: 1.5px solid #4d1111;
  border-radius: 5px;
  color: #4d1111;
  padding: 0.3em 0.5em;
  width: 40%;

  ${mobile(css`
    padding: 0.3em 0.5em;
    width: 50%;
  `)};
`;

export const ForgotPasswordForm = styled(FormContainer)`
  padding: 0 3em;
`;

export const ModalParagraph = styled.p`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding-top: 20px;
`;

export const CloseIcon = styled.p`
  display: none;
`;

export const SavedAddressLabel = styled.h5`
  ${mobile(css`
    padding-top: 30px;
  `)};
`;

export const ButtonSmall = styled.button`
  background: #4d1111;
  border: 1.5px solid #4d1111;
  border-radius: 5px;
  color: #fff;
  padding: 0.3em 0.5em;
  width: 25%;

  ${mobile(css`
    padding: 0.3em 0.5em;
    width: 50%;
  `)};
`;

export const CancelButtonSmall = styled.button`
  background: transparent;
  border: 1.5px solid #4d1111;
  border-radius: 5px;
  color: #4d1111;
  padding: 0.3em 0.5em;
  width: 25%;

  ${mobile(css`
    padding: 0.3em 0.5em;
  `)};
`;

export const StyledTable = styled(Table)`
  overflow: scroll;
  & th {
    white-space: nowrap;
  }
`;

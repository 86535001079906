import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Button } from '../SignIn/SignIn.styled';
import { StyledHref } from '../../Assets/GlobalStyled';

export const Disclaimer = styled.p`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #3e2f24;
  margin: 1.5em 0;
`;

export const ModalBody = styled(Modal.Body)`
  background: #ebdddd;
  border: 1px solid #3e2f24;
  border-radius: 5px;

  padding: 4em 2em;
`;

export const ModalText = styled.p`
  text-align: center;
`;

export const ModalButton = styled(Button)`
  margin-top: 2em;
  width: 60%;
`;

export const Label = styled(Disclaimer)`
  font-size: 13px;
`;

export const LoginLabel = styled(StyledHref)`
  color: #3e2f24;
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;

  &:hover {
    color: #3e2f24;
    opacity: 1;
  }
`;

export const StyledLabel = styled.span`
  font-size: 14px;
`;

export const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* adjust opacity as needed */
  z-index: 9999; /* ensure it is on top of other elements */
`;

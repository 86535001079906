import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { servicesRequest } from '../../Utils';
import {
  PRODUCT_CART_URL,
  PRODUCT_URL,
  PRODUCT_WISHLIST_URL
} from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import ProductDetailComponent from './ProductDetail.component';
import { COUNTER_TYPE, ROUTE } from '../../Constants';
import { checkJWT } from '../../Utils/general.utils';
import { addCartPayload } from '../../Redux/Actions/carts.action';

const ProductDetailScreen = () => {
  const [product, setProduct] = React.useState({});
  const [quantity, setQuantity] = React.useState(1);
  const [isWishlist, setIsWishlist] = React.useState(false);

  const qtyAmount = product.qtyAmount;

  const { t } = useTranslation();

  const { product: product_code } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));
  const addToCartPayload = payload => dispatch(addCartPayload(payload));

  const updateCartsRedux = tempQty => {
    addToCartPayload({
      discount: 0,
      discountId: '',
      products: product,
      qty: Number(tempQty),
      subTotal: Number(Number(tempQty) * Number(product?.bprice)),
      subTotalAfterDiscount: Number(Number(tempQty) * Number(product?.bprice))
    });
  };

  const fetch = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: {
          data: { products: response }
        }
      } = await authorizedRequest.post(PRODUCT_URL, {
        itemCode: product_code,
        categoryCode: '',
        onlineStore: '1'
      });

      response.qty = JSON.parse(response.qty);

      setProduct(response);
    } catch (error) {
      showNotification(
        'Sorry, we are unable to fetch product data. Please try again'
      );
    }
  };

  const fetchingWishlist = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(PRODUCT_WISHLIST_URL);

      setIsWishlist(data?.includes(product_code));
    } catch (error) {
      showNotification('Something went wrong. Try again later');
    }
  };

  const addToCart = async () => {
    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        await authorizedRequest.post(PRODUCT_CART_URL, {
          product_code: product_code,
          qty: quantity
        });
      } else {
        addToCartPayload({
          discount: 0,
          discountId: '',
          products: product,
          qty: 1,
          subTotal: Number(product?.bprice),
          subTotalAfterDiscount: Number(product?.bprice)
        });
      }

      showNotification(
        <>
          <div>{t('successfully-added-cart')}</div>
          <a href="/cart">{t('go-to-checkout')}</a>
        </>
      );

      setTimeout(() => {
        navigate('/' + ROUTE.cart);
      }, 2500);
    } catch (e) {
      showNotification(
        'Sorry, unable to add product to cart. Please try again'
      );
    }
  };

  React.useEffect(() => {
    fetch().catch(console.error);
    localStorage.getItem('token') && fetchingWishlist().catch(console.error);
  }, [product_code]);

  const wishlistAction = async id => {
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.post(PRODUCT_WISHLIST_URL, {
        product: id
      });
    } catch (error) {
      showNotification('Something went wrong. Try again later');
    }
  };

  const handleWishlist = async () => {
    setIsWishlist(!isWishlist);
    await wishlistAction(product_code);
  };

  const _counterQuantity = type => {
    if (type === COUNTER_TYPE.SUBTRACT) {
      if (quantity === 0) return;

      setQuantity(quantity - 1);
      updateCartsRedux(quantity - 1);
      dispatch({ type: 'REMOVE_ITEM' });
      return;
    }

    if (quantity + 1 <= qtyAmount) {
      dispatch({ type: 'ADD_ITEM' });

      setQuantity(quantity + 1);
      updateCartsRedux(quantity + 1);
    }
  };

  const _setCounterQuantity = async (type, e) => {
    if (e <= qtyAmount) {
      dispatch({ type: 'SET_ITEM' });
      setQuantity(e);
      updateCartsRedux(e);
    } else {
      setQuantity(qtyAmount);
      updateCartsRedux(qtyAmount);
    }
  };

  const props = () => ({
    product,
    isWishlist,
    quantity,
    handleWishlist,
    _counterQuantity,
    _setCounterQuantity,
    addToCart
  });

  return <ProductDetailComponent {...props()} />;
};

export default ProductDetailScreen;

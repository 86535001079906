import {
  FILTER_APPELLATION_PAYLOAD
} from './action.types';

export const filterAppellationPayload = payload => dispatch => {
  dispatch({
    type: FILTER_APPELLATION_PAYLOAD,
    payload: payload
  });
};


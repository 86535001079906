import {
  SUBTRACT_CARTS,
  ADD_CARTS,
  DEFAULT_CARTS,
  ADD_CARTS_PAYLOAD,
  SUBTRACT_CARTS_PAYLOAD,
  RESET_CARTS_PAYLOAD
} from './action.types';

export const setDefaultCart =
  (number = 1) =>
  dispatch => {
    dispatch({
      type: DEFAULT_CARTS,
      payload: number
    });
  };

export const addCart =
  (number = 1) =>
  dispatch => {
    dispatch({
      type: ADD_CARTS,
      payload: number
    });
  };

export const addCartPayload = payload => dispatch => {
  dispatch({
    type: ADD_CARTS_PAYLOAD,
    payload: payload
  });
};

export const subtractCartPayload = payload => dispatch => {
  dispatch({
    type: SUBTRACT_CARTS_PAYLOAD,
    payload: payload
  });
};

export const resetCartPayload = () => dispatch => {
  dispatch({
    type: RESET_CARTS_PAYLOAD
  });
};

export const subtractCart = number => dispatch => {
  dispatch({
    type: SUBTRACT_CARTS,
    payload: number
  });
};

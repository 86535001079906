import React from 'react';
import { Product } from '../Product';
import { useParams } from 'react-router-dom';

const CategoryContainer = () => {
  const { mainCategory = '', subCategory = '' } = useParams();

  let categoryRegion = subCategory === 'filter' ? '' : subCategory;

  return (
    <Product categoryCode={mainCategory} categoryRegion={categoryRegion} />
  );
};

export default CategoryContainer;

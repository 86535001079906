import React from 'react';

import { update_qrcode } from '../../../Utils/payme.utils';
import { WinebuffLogo } from '../../../Assets/Images';

const PaymeBarcodeComponent = ({ url }) => {
  return (
    <form name="qrForm" onSubmit={update_qrcode}>
      <span hidden>TypeNumber:</span>
      <select name="t" onChange={update_qrcode} hidden>
        <option value="0" selected="selected">
          Auto Detect
        </option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="40">40</option>
      </select>
      <span hidden>ErrorCorrectionLevel:</span>
      <select name="e" onChange={update_qrcode} hidden>
        <option value="L">L(7%)</option>
        <option value="M">M(15%)</option>
        <option value="Q" selected="selected">
          Q(25%)
        </option>
        <option value="H">H(30%)</option>
      </select>
      <span hidden>Size:</span>
      <input name="size" type="text" defaultValue="244" hidden />
      <span hidden>
        <img
          id="logo"
          width="30"
          height="30"
          src={WinebuffLogo}
          onLoad={update_qrcode}
          alt=""
        />
      </span>
      <input
        hidden
        type="checkbox"
        name="consumer"
        value="consumer"
        onChange={update_qrcode}
      />{' '}
      <p />
      <textarea name="msg" rows="2" cols="86" hidden>
        {url}
      </textarea>
      <p />
      <input
        hidden
        className="update-btn"
        type="button"
        value="Update"
        onClick={update_qrcode}
      />
      <div id="qr"></div>
      <canvas id="payCodeCanvas" width="344" height="344"></canvas>
    </form>
  );
};

export default PaymeBarcodeComponent;

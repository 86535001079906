import {
  SHOW_HIDE
} from './action.types';

export const showHidePayload = payload => dispatch => {
  dispatch({
    type: SHOW_HIDE,
    payload: payload
  });
};


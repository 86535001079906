import styled, { css } from 'styled-components';

import { mobile } from '../../Assets/layout';

export const Container = styled.div`
  padding: 2em 3em;

  ${mobile(css`
    padding: 2em 1em;
  `)};
`;

export const Image = styled.img`
  border-radius: 8px;

  height: 32em;
  ${mobile(css`
    height: auto;
  `)};
`;

export const ProductName = styled.h1`
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #18181b;
  font-family: 'Plus Jakarta Sans', sans-serif;

  ${mobile(css`
    text-align: center;
    font-size: 24px;
    margin-top: 1.5vh;
    letter-spacing: 0.1em;
  `)};
`;

export const ProductPriceContainer = styled.div`
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 5vh;

  ${mobile(css`
    margin-top: 2.5vh;
  `)};
`;

export const ProductPriceDiscount = styled.h1`
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #18181b;
  margin-right: 1vw;

  ${mobile(css`
    font-size: 25px;
  `)};
`;

export const ProductPriceNormal = styled.h1`
  font-weight: 700;
  font-size: 21px;
  line-height: 34px;
  text-decoration-line: line-through;
  color: #71717a;

  ${mobile(css`
    font-size: 19px;
  `)};
`;

export const DiscountContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 1vw;
`;

export const DiscountIcon = styled.img``;

export const DiscountDescription = styled.span`
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #71717a;
`;

export const QuantityContainer = styled.div`
  width: 20%;

  ${mobile(css`
    width: 30%;
  `)};
`;

export const Counter = styled.div`
  border: 1px solid #d4d4d8;
  border-radius: 4.57143px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0.5vh 1vw;
  margin-top: 6vh;

  ${mobile(css`
    margin-top: 1.5vh;
  `)};
`;

export const QuantityLabel = styled.span`
  font-weight: 500;
  font-size: 13.7143px;
  line-height: 21px;
  color: #969696;
`;

export const PlusMinus = styled.p`
  cursor: pointer;
  font-weight: bolder;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #969696;

  ${mobile(css`
    font-size: 20px;
  `)};
`;

export const QuantityNumber = styled.p`
  font-weight: 600;
  font-size: 15.6735px;
  margin: 0;
  padding: 0;
`;

export const StyledInput = styled.input`
  font-weight: 600;
  font-size: 15.6735px;
  color: black;
  width: 40px;
  border: none; /* remove border */
  outline: none; /* remove outline */
  box-shadow: none; /* remove box shadow */
  background-color: transparent; /* set background to transparent */
  text-align: center;
`;

export const PickupContainer = styled.div`
  width: 50%;
  margin-top: 2vh;

  ${mobile(css`
    width: 70%;
  `)};
`;

export const PickupItem = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

export const PickupStore = styled.span`
  text-transform: uppercase;
`;
export const PickupStock = styled.span`
  color: ${props => (props.storeStock > 0 ? '#56BB8E' : '#DA2F24')};
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

export const AddToBagButton = styled.button`
  font-family: 'Plus Jakarta Sans', sans-serif;
  background: #000000;
  border-radius: 5px;
  border: 2px solid #000000;
  color: #ffffff;
  width: 100%;
  padding: 1vh 0;
  font-weight: 700;
`;

export const AddToWishlistButton = styled.div`
  background: #fafafb;
  border: 1.5px solid #d4d4d8;
  border-radius: 5px;
  padding: 1vh;
  width: 3vw;
  text-align: center;
  cursor: pointer;

  ${mobile(css`
    width: 50%;
  `)};
`;

export const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 1vh;
`;

export const ProductFeatures = styled.span`
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #52525b;
  margin-left: 0.5vw;
`;

export const Description = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 20.3396px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: #000000;
`;

export const Divider = styled.hr`
  border: 1px solid #000000;
  margin: 2vh 0;

  ${mobile(css`
    margin: 2vh 0;
  `)};
`;

export const DescriptionDetail = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

export const ProductDescription = styled.p`
  margin-top: 4vh;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #626262;

  ${mobile(css`
    margin-top: 1vh;
  `)};
`;

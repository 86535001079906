import styled, { css } from 'styled-components';
import { mobile } from '../../Assets/layout';
import { Col, Row } from 'react-bootstrap';

export const Container = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em 3em;
`;

export const Card = styled(Col)`
  width: 100%;
  ${mobile(css`
    padding: 0px;
    width: unset;
  `)};
`;

export const CardBody = styled(Row)`
  padding: 0px;
  margin: 0;
  border: 1px solid #EDEDED;
  border-radius: 0 0 3px 3px;
  min-height: 35em;
  --bs-gutter-x: 0px !important;
`;

export const Title = styled.div`
  font-family: 'Encode Sans Condensed', serif;
  color: black;
  font-weight: 600;
  font-size: 40px;
`;

export const Content = styled.div`
  font-family: 'Encode Sans Condensed', serif;
  line-height: 1.8em !important;
  cursor: pointer;
  font-size: 20px;
`;
export const ContentHeader = styled.div`
  font-family: 'Encode Sans Condensed', serif;
  line-height: 1.8em !important;
  color: #667085;
  cursor: pointer;
  font-size: 20px;
`;

export const Map = styled.img`
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 3px;
  width: 100%;
`;

export const Image = styled.img`
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;
  margin: 2em 0;
  @media only screen and (max-width: 1536px) {
    width: 100%;
  }
`;

export const ProductsDisplayContainer = styled.div`
  padding: 100px 0;
`;

export const TitleProductDetail = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20.3396px;
  padding-right: 10px;
  padding-left: 10px;
  color: #000000;
`;

export const ButtonContainer = styled.div`
  justify-content: center;
`;

export const StyledDetailButton = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

export const MapTabsContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
`;

export const ColContainer = styled(Col)`
    display: flex;
    place-items: center;
    flex-direction: column;
`
export const DescriptionContainer = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
`

export const Description = styled(Col)`
    max-width: 500px;
    text-align: center;
`

import { FiArrowUpRight } from 'react-icons/fi';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Description,
  Image,
  Title,
  TitleContainer,
  Writer
} from './BlogCard.styled';
import Icon from '../Icon';
import { limitOutputString, removeTags, getTitle, getContent } from '../../Utils/general.utils';
import { ROUTE } from '../../Constants';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const BlogCardComponent = ({ data }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();


  return (
    <Container>
      <Image src={data?.thumbnail} className="img-fluid" />
      <Writer>{`${data?.author} • ${moment(data?.createdAt).format(
        'MMM Do YYYY'
      )}`}</Writer>
      <TitleContainer
        onClick={() => {
          navigate(`/${ROUTE.blog}/${data?.id}`);
        }}>
        <Title>{getTitle(data?.title, data?.title_ch, i18n.language)}</Title>
        <Icon IconShow={FiArrowUpRight} style={{ size: '24px' }} />
      </TitleContainer>
      <Description>
        {limitOutputString(removeTags(getContent(data, i18n.language)), 90, true)}
      </Description>
    </Container>
  );
};

export default BlogCardComponent;

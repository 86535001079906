import { Table as TableBS } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { mobile } from '../../Assets/layout';
import { DisplayFlex } from '../../Assets/GlobalStyled';

export const TopLabel = styled.span``;

export const TabStyledComponent = styled.div`
  padding: 40px;
`;

export const Title = styled.h3`
  font-family: 'ABeeZee', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  color: #324158;
`;

export const TabContainer = styled(DisplayFlex)`
  justify-content: space-evenly;
`;

export const TabDiv = styled.div`
  cursor: pointer;
  width: 150px !important;
  display: flex !important;
  justify-content: center;
  border-bottom: ${props =>
    props.active ? '3px solid #CD5D78 !important' : ''};
`;

export const TabTitle = styled.h4`
  font-family: 'ABeeZee', sans-serif;
  font-weight: 700;
  color: ${props => (props.active ? '#CD5D78' : '#3E2F24')};
`;

export const TableButton = styled.button`
  background: #4d1111;
  border: 1.5px solid #4d1111;
  border-radius: 5px;
  color: #fff;
  padding: 0.3em 0.5em;
  width: 150px;

  ${mobile(css`
    padding: 0.3em 0.5em;
  `)};
`;

export const ColTitle = styled.h5`
  background-color: #f9fafb;
  border-radius: 8px;
  color: #4e5157;
`;

export const DetailContainer = styled.div`
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 0.5vh;
`;

export const Table = styled(TableBS)`
  border-radius: 8px !important;
`;

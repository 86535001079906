import {
  SELECTED_FILTER_VALUE_PAYLOAD
} from '../Actions/action.types';

const selectedFilterValueReducer = (state = '', action) => {
  switch (action.type) {
    case SELECTED_FILTER_VALUE_PAYLOAD:
      return { selectedFilterValue: action.payload };
    default:
      return state
  }
};
export default selectedFilterValueReducer;

import {
  ADD_CARTS,
  ADD_CARTS_PAYLOAD,
  DEFAULT_CARTS,
  RESET_CARTS_PAYLOAD,
  SUBTRACT_CARTS,
  SUBTRACT_CARTS_PAYLOAD
} from '../Actions/action.types';
import { COUNTER_TYPE } from '../../Constants';

const cartState = {
  total: 0,
  cartsContainer: JSON.stringify({
    carts: [],
    subTotal: 0,
    subTotalDiscount: 0,
    totalQuantity: 0
  })
};

const cartsReducer = (state = cartState, action) => {
  switch (action.type) {
    case DEFAULT_CARTS:
      return { ...state, total: action.payload };
    case ADD_CARTS:
      return { ...state, total: state.total + action.payload };
    case SUBTRACT_CARTS:
      return { ...state, total: state.total - action.payload };
    case ADD_CARTS_PAYLOAD:
      let tempCartsContainer = JSON.parse(state.cartsContainer);
      let { carts: tempCarts } = tempCartsContainer;

      const { type = COUNTER_TYPE.ADD } = action.payload;

      // check if already in temp carts
      const findCartIndex = tempCarts.findIndex(
        x => x.products.code === action.payload.products.code
      );

      if (type === COUNTER_TYPE.ADD) {
        if (findCartIndex !== -1) {
          // type add and add other qty

          tempCarts[findCartIndex].qty += 1;
          tempCarts[findCartIndex].subTotal += Number(
            action.payload.products.bprice
          );
          tempCarts[findCartIndex].subTotalAfterDiscount += Number(
            action.payload.products.bprice
          );
        } else {
          // type add and push new product to cart
          tempCarts.push(action.payload);
        }
      }

      if (type === COUNTER_TYPE.SUBTRACT) {
        const existingCartQty = tempCarts?.[findCartIndex]?.qty || 1;

        if (existingCartQty - 1 === 0) {
          tempCarts.splice(findCartIndex, 1);
        } else {
          tempCarts[findCartIndex].qty -= 1;
          tempCarts[findCartIndex].subTotal -= Number(
            action.payload.products.bprice
          );
          tempCarts[findCartIndex].subTotalAfterDiscount -= Number(
            action.payload.products.bprice
          );
        }
      }

      let subTotal = 0;
      let totalQuantity = 0;

      tempCarts.map(item => {
        subTotal += Number(item.subTotal);
        totalQuantity += Number(item.qty);
      });

      // next, set the new value of the state
      let finalCalculation = {
        carts: tempCarts,
        subTotal,
        subTotalDiscount: 0,
        totalQuantity
      };

      finalCalculation = JSON.stringify(finalCalculation);

      return { ...state, cartsContainer: finalCalculation };
    case SUBTRACT_CARTS_PAYLOAD:
      let temp =
        state.cartsContainer === '' ? [] : JSON.parse(state.cartsContainer);
      const itemToDelete = action.payload;

      const findIndexItem = temp.findIndex(x => x.code === itemToDelete.code);
      temp.splice(findIndexItem, 1);

      return {
        ...state,
        total: temp?.length,
        cartsContainer: JSON.stringify(temp)
      };
    case RESET_CARTS_PAYLOAD:
      return {
        ...state,
        cartsContainer: JSON.stringify({
          carts: [],
          subTotal: 0,
          subTotalDiscount: 0,
          totalQuantity: 0
        })
      };
    default:
      return state;
  }
};

export default cartsReducer;

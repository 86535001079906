import {
  FILTER_SEARCH_PAYLOAD
} from '../Actions/action.types';

const filterSearchReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_SEARCH_PAYLOAD:
      return { filterSearch: action.payload };
    default:
      return state
  }
};
export default filterSearchReducer;

import React from 'react';
import Slider from 'react-slick';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import i18n from '../../../i18n';
import { servicesRequest } from '../../../Utils';
import { PRODUCT_CART_URL, PRODUCT_URL } from '../../../Utils/serviceUrl.utils';
import { ProductCard } from '../../../Components';
import { addCart, addCartPayload } from '../../../Redux/Actions/carts.action';
import { checkJWT } from '../../../Utils/general.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const ProductComponent = filterAppellation => {
  const [products, setProducts] = React.useState([]);
  const { t, i18n } = useTranslation();

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_URL, {
        onlineStore: '1',
        appellation: filterAppellation.filterAppellation
      });

      setProducts(data.products);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, [filterAppellation]);

  const dispatch = useDispatch();

  const addToCart = (amount = 1) => dispatch(addCart(amount));
  const addToCartPayload = payload => dispatch(addCartPayload(payload));

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const cartAction = async product => {
    const { code } = product;

    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        await authorizedRequest.post(PRODUCT_CART_URL, {
          product_code: code,
          qty: 1
        });
      } else {
        addToCartPayload({
          discount: 0,
          discountId: '',
          products: product,
          qty: 1,
          subTotal: Number(product?.cprice),
          subTotalAfterDiscount: Number(product?.cprice)
        });
      }

      addToCart();
      showNotification(`${t('successfully-added-cart')}`);
    } catch (e) {
      showNotification(
        'Sorry, unable to add product to cart. Please try again'
      );
    }
  };

  return (
    <div>
      {products?.length === 0 ? (
        <ShimmerSimpleGallery row={1} col={4} imageHeight={200} caption />
      ) : (
        <Slider {...settings}>
          {products.map((item, index) => {
            return (
              <div key={index} className="px-1">
                <ProductCard
                  cartAction={cartAction}
                  data={item}
                  isWithWishlistButton={true}
                />
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default ProductComponent;

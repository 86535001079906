import React from 'react';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Container,
  ForgotPassword,
  FormContainer,
  Input,
  SecondContainer,
  Title
} from './SignIn.styled';
import { DisplayFlex } from '../../Assets/GlobalStyled';
import { servicesRequest } from '../../Utils';
import {
  Label as LabelBold,
  LoginLabel,
  ModalBody,
  ModalButton,
  ModalText,
  StyledLabel
} from '../SignUp/SignUp.styled';
import { PRODUCT_CART_URL, SIGN_IN_URL } from '../../Utils/serviceUrl.utils';
import ForgotPasswordComponent from './Components/ForgotPassword.component';
import { ROUTE } from '../../Constants';
import { resetCartPayload } from '../../Redux/Actions/carts.action';

const CustomCheckbox = styled(Checkbox)`
  ${props =>
    props.backgroundColor &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props.backgroundColor};
        border-color: ${props.backgroundColor};
      }
    `}
`;

const SignInScreen = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [showNotActivated, setShowNotActivated] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const { t } = useTranslation();

  const { pageSource = '' } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const closeNotActivated = () => setShowNotActivated(false);

  const reduxState = useSelector(state => state.cartsReducer);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const resetToCartPayload = () => dispatch(resetCartPayload());

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      const loginData = data;
      const {
        data: {
          data: { token }
        }
      } = await request.post(SIGN_IN_URL, data);

      localStorage.setItem('token', token);

      if (pageSource === 'carts') {
        await migrateCartsStateToDB();
        navigate('/' + ROUTE.cart);
      } else {
        navigate('/' + ROUTE.account);
      }
      window.dispatchEvent(new Event('isLogin'));

      if (rememberMe) {
        saveLogin(loginData.email, loginData.password);
      } else {
        clearLogin();
      }
    } catch (error) {
      if (error.response.data.message === 'User account is not active') {
        setShowNotActivated(true);
      } else {
        handleShow();
      }
    }
  };

  const saveLogin = (email, password) => {
    localStorage.setItem('loginEmail', email);
    localStorage.setItem('loginPassword', password);
  };

  const clearLogin = () => {
    localStorage.removeItem('loginEmail');
    localStorage.removeItem('loginPassword');
  };

  const loadLogin = () => {
    const loginEmail = localStorage.getItem('loginEmail');
    const loginPassword = localStorage.getItem('loginPassword');
    if (loginEmail && loginPassword) {
      setEmail(loginEmail);
      setPassword(loginPassword);
      setRememberMe(true);
    }
  };

  const migrateCartsStateToDB = async () => {
    try {
      let { cartsContainer: tempLocalCartsContainer } = reduxState;

      tempLocalCartsContainer = JSON.parse(tempLocalCartsContainer);
      const carts = tempLocalCartsContainer?.carts;
      if (carts?.length > 0) {
        carts?.map(async cart => {
          const authorizedRequest = await servicesRequest();
          await authorizedRequest.post(PRODUCT_CART_URL, {
            product_code: cart?.products?.code,
            qty: cart?.qty
          });
        });
      }

      resetToCartPayload();
    } catch (e) {}
  };
  const onRemember = e => {
    setRememberMe(e.target.checked);
  };

  React.useEffect(() => {
    loadLogin();
  }, []);

  const ErrorMessage = () => {
    return (
      <React.Fragment>
        <ModalText>{t('sign-in-error')}</ModalText>
        <div className="text-center">
          <ModalButton onClick={handleClose} className="text-center">
            Ok
          </ModalButton>
        </div>
      </React.Fragment>
    );
  };

  const ErrorNotActivated = () => {
    return (
      <React.Fragment>
        <ModalText>
          You Haven't Yet Activated Your Account To Access. Please check your
          email and click on the link to activate your account accordingly.
        </ModalText>
        <div className="text-center">
          <ModalButton onClick={closeNotActivated} className="text-center">
            Ok
          </ModalButton>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Container>
      <Title>{t('sign-in')}</Title>
      <FormContainer className="gy-2 justify-content-center">
        <Input
          error={errors?.email}
          type="email"
          placeholder={t('email')}
          defaultValue={email}
          onChange={e => setEmail(e.target.value)}
          {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
        />
        <Input
          error={errors?.password}
          type="password"
          placeholder={t('password')}
          defaultValue={password}
          onChange={e => setPassword(e.target.value)}
          {...register('password', { required: true, minLength: 8 })}
        />
        <SecondContainer>
          <DisplayFlex>
            <CustomCheckbox
              backgroundColor="black"
              checked={rememberMe}
              onChange={onRemember}>
              {t('remember-me')}
            </CustomCheckbox>
          </DisplayFlex>
          <ForgotPassword
            className="pt-2"
            onClick={() => {
              setForgotPassword(true);
              setShow(true);
            }}>
            <StyledLabel>{t('forgot-password')}</StyledLabel>
          </ForgotPassword>
        </SecondContainer>
        <Button onClick={handleSubmit(onSubmit)}>{t('login')}</Button>

        <LabelBold>
          {t('do-not-have-account')}{' '}
          <LoginLabel href={pageSource === '' ? '/sign-up' : '/sign-up/cart'}>
            {t('register')}
          </LoginLabel>
        </LabelBold>
      </FormContainer>

      <Modal show={show} onHide={handleClose} centered>
        <ModalBody>
          {forgotPassword ? <ForgotPasswordComponent /> : <ErrorMessage />}
        </ModalBody>
      </Modal>
      <Modal show={showNotActivated} onHide={closeNotActivated} centered>
        <ModalBody>
          <ErrorNotActivated />
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default SignInScreen;

import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  ButtonStyled,
  InputPrefixWrapper,
  SliderContainer,
  SmalLabel,
  StyledSlider,
  TextFieldStyled
} from './PriceRange.styled';
import { CustomHeader } from '../../Screens/Product/Product.styled';
import { arrangeProductFilterParams } from '../../Screens/Product/Product.container';
import { SIZE_FILTER } from '../../Screens/Product/Product.component';

const PriceRangeComponent = ({
  minPrice,
  maxPrice,
  // rangeSelector,
  fixedMaxPrice,
  fixedMinPrice,
  filterPriceAndFormat,
  sizeFilterParams
}) => {
  const [value, setValue] = React.useState([minPrice, maxPrice]);
  const [minPrice2, setMinPrice2] = React.useState(minPrice);
  const [maxPrice2, setMaxPrice2] = React.useState(maxPrice);
  const [sizeFilter, setSizeFilter] = React.useState(sizeFilterParams);

  const setRangeSelector = (event, newValue) => {
    setValue(newValue);
  };
  const setRangeSelectorCommitted = (event, newValue) => {
    // rangeSelector(newValue);
    setMinPrice2(newValue[0]);
    setMaxPrice2(newValue[1]);
  };
  const handleInputChangeMin = event => {
    setMinPrice2(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleInputChangeMax = event => {
    setMaxPrice2(event.target.value === '' ? '' : Number(event.target.value));
  };

  const { t } = useTranslation();

  const submitRange = () => {
    const sum = [minPrice2, maxPrice2];
    setValue(sum);
    // rangeSelector(sum);

    filterPriceAndFormat(sum, sizeFilter);
  };

  const maximumPrice = fixedMaxPrice;
  const minimumPrice = fixedMinPrice;

  return (
    <div className="mt-2">
      {/*<SliderContainer>*/}
      {/*  <StyledSlider*/}
      {/*    value={value}*/}
      {/*    min={minimumPrice}*/}
      {/*    max={maximumPrice}*/}
      {/*    onChange={setRangeSelector}*/}
      {/*    onChangeCommitted={setRangeSelectorCommitted}*/}
      {/*  />*/}
      {/*</SliderContainer>*/}
      <Row className="pt-2">
        <Col md={6}>
          <InputPrefixWrapper>
            <SmalLabel>HK$</SmalLabel>
            <div>
              <TextFieldStyled
                value={minPrice2}
                disableunderline="true"
                size="small"
                onChange={handleInputChangeMin}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: maximumPrice,
                  type: 'number',
                  'aria-labelledby': 'input-slider'
                }}
              />
            </div>
          </InputPrefixWrapper>
        </Col>
        <Col md={6}>
          <InputPrefixWrapper>
            <SmalLabel>HK$</SmalLabel>
            <div>
              <TextFieldStyled
                value={maxPrice2}
                size="small"
                onChange={handleInputChangeMax}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: maximumPrice,
                  type: 'number',
                  'aria-labelledby': 'input-slider'
                }}
              />
            </div>
          </InputPrefixWrapper>
        </Col>
      </Row>
      <div className="mt-4">
        <CustomHeader>{t('format')}</CustomHeader>
        <div className="mt-2 mx-3">
          {SIZE_FILTER.map((size, index) => (
            <Form.Check
              checked={size.value === sizeFilter}
              key={index}
              name="sizeFilter"
              type="radio"
              onClick={() => {
                setSizeFilter(size.value);
              }}
              label={size?.label}
            />
          ))}
        </div>
      </div>
      <Row className="px-3 pt-3">
        <ButtonStyled onClick={() => submitRange()}>
          {t('search-filter')}
        </ButtonStyled>
      </Row>
    </div>
  );
};

export default PriceRangeComponent;

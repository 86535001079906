import React from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import { CollapseMenu, ContactUsForm } from '../../Components';
import { Answer, Title } from './FAQ.styled';
import { servicesRequest } from '../../Utils';
import { FAQ_URL } from '../../Utils/serviceUrl.utils';

const FAQAnswer = props => {
  return (
    <Answer
      className="pb-4 pt-3"
      dangerouslySetInnerHTML={{ __html: props?.answer }}
    />
  );
};

const FAQScreen = () => {
  const [faqs, setFaqs] = React.useState([]);
  const { t, i18n } = useTranslation();

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(FAQ_URL);
      setFaqs(data);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetching();
  }, []);

  const _renderFaq = () => {
    return faqs?.map((faq, index) => {
      return (
        <CollapseMenu
          key={index}
          title={faq?.question}
          Component={FAQAnswer}
          type={2}
          dataToHOC={faq}
        />
      );
    });
  };

  return (
    <React.Fragment>
      <div className="p-5">
        <Title>{t('frequently-asked')}</Title>
        {_renderFaq()}
      </div>
      <div className="mt-5">
        <ContactUsForm />
      </div>
    </React.Fragment>
  );
};

export default FAQScreen;

import React from 'react';

import { servicesRequest } from '../../../Utils';
import { PURCHASE_URL } from '../../../Utils/serviceUrl.utils';
import OrderTableComponent from './OrderTable.component';

const OrderComponent = props => {
  const [orders, setOrders] = React.useState([]);

  const fetchOrder = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(PURCHASE_URL + '/all');

      setOrders(data);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchOrder().catch(console.error);
  }, []);

  return <OrderTableComponent data={orders} {...props} />;
};

export default OrderComponent;

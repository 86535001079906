import React from 'react';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { FaThList } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import './Components/FilterMenu.styled.scss';

import {
  CloseIcon,
  CollapseMenuContainer,
  Container,
  CustomHeader,
  DropdownBtn,
  FilterFlex,
  FilterLabel,
  FilterTopContainer,
  FilterTopLeftContainer,
  Input,
  InputContainer2,
  InputContainer3,
  ListContainer,
  ListTableContainer,
  MiddleIcon,
  ProductsGrid,
  RadioFilterContainer,
  SideBarContainer,
  SidebarHeader,
  TopFilter,
  TopIconContainer,
  TopIconFilter,
  TopIconFilterLeft,
  TotalItemContainer
} from './Product.styled';
import FilterMenu from './Components/FilterMenu';
import CollapseMenuComponent from './Components/CollapseMenu.component';

import {
  Pagination,
  PriceRange,
  ProductCard,
  ProductCardList,
  ProductCardListMobile
} from '../../Components';
import '../../Components/ProductCardList/Product.styled.scss';
import { showHidePayload } from '../../Redux/Actions/showHide.action';
import { filterActivePayload } from '../../Redux/Actions/filterActive.action';
import { arrangeProductFilterParams } from './Product.container';

export const SIZE_FILTER = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 375,
    value: '375'
  },
  {
    label: 750,
    value: '750'
  },
  {
    label: 1500,
    value: '1500'
  }
];

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};

const ProductScreen = props => {
  const {
    products,
    wishlists,
    setPage,
    item,
    setItem,
    pagination,
    wishlistAction,
    cartAction,
    setView,
    carts,
    view,
    minPrice,
    maxPrice,
    fixedMaxPrice,
    // rangeSelector,
    isLoading,
    sizeFilterParams,
    websiteListingParams,
    appellationParams,
    regionParams,
    categoryFilterParams,
    submenuParams,
    filterPriceAndFormat
  } = props;

  const { t } = useTranslation();

  const [search, setSearch] = React.useState('');

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  const isTablet = windowWidth > 768 && windowWidth <= 1024;

  const { showHide: showHide } = useSelector(state => state.showHideReducer);

  const dispatch = useDispatch();

  const filterActive = data => dispatch(filterActivePayload(data));
  const showHideSidebar = data => dispatch(showHidePayload(data));
  const navigate = useNavigate();

  const handleSearch = text => {
    if (text !== '') {
      navigate(`/product?q=${text}`);
    }
  };

  const handleSearchKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      performSearch(event.target);
    }
  };

  const handleSearchBlur = event => {
    performSearch(event.target);
  };

  const performSearch = target => {
    const searchText = target.value.trim();
    if (searchText === '') {
      handleSearch(searchText);
    } else {
      handleSearch(search);
    }
    target.value = '';
    filterActive('');
    showHideSidebar(false);
  };

  const toggleSidebar = () => {
    showHideSidebar(false);
  };

  const handleChangeSubFilterAppellation = event => {
    navigate(
      arrangeProductFilterParams({
        appellation: event.target.value,
        country: 'France',
        subMenu: submenuParams,
        websiteListing: websiteListingParams
      })
    );
  };

  // const renderSizeFilterComponent = () => {
  //   return (
  //     <div className="mt-2 mx-3">
  //       {SIZE_FILTER.map((size, index) => (
  //         <Form.Check
  //           checked={size.value === sizeFilterParams}
  //           key={index}
  //           name="sizeFilter"
  //           type="radio"
  //           onClick={() => {
  //             navigate(
  //               arrangeProductFilterParams({
  //                 category: categoryFilterParams,
  //                 websiteListing: websiteListingParams,
  //                 country: categoryFilterParams,
  //                 appellation: appellationParams,
  //                 region: regionParams,
  //                 filterSize: size.value
  //               })
  //             );
  //           }}
  //           label={size?.label}
  //         />
  //       ))}
  //     </div>
  //   );
  // };

  const renderPriceFilter = () => {
    return (
      <div className="mt-2 mb-2 mx-2">
        {maxPrice && (
          <PriceRange
            fixedMaxPrice={fixedMaxPrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
            // rangeSelector={rangeSelector}
            filterPriceAndFormat={filterPriceAndFormat}
            sizeFilterParams={sizeFilterParams}
          />
        )}
      </div>
    );
  };

  const renderAppellationFilter = () => {
    return <FilterMenu {...props} />;
  };

  const renderTotalItems = () => {
    return (
      <FilterLabel>
        <TopIconContainer className="product-tabel">
          <TopIconFilterLeft>
            <BsFillGrid3X3GapFill
              className={view === 'grid' ? 'active filter-icon' : 'filter-icon'}
              onClick={() => setView('grid')}
            />
          </TopIconFilterLeft>
          <TopIconFilter>
            <FaThList
              className={view === 'list' ? 'active filter-icon' : 'filter-icon'}
              onClick={() => setView('list')}
            />
          </TopIconFilter>
          <TotalItemContainer>
            {t('showing')}{' '}
            {Math.min(
              pagination.page * pagination.itemperpage + 1,
              pagination.total_item
            )}{' '}
            -{' '}
            {Math.min(
              pagination.page * pagination.itemperpage + pagination.itemperpage,
              pagination.total_item
            )}{' '}
            of {pagination.total_item} {t('products')}
          </TotalItemContainer>
        </TopIconContainer>
      </FilterLabel>
    );
  };

  const _renderProductsTable = () => {
    return <ProductCardList data={products} carts={carts} />;
  };

  const _ProductCardListMobile = () => {
    if (products.length === 0 && isLoading) {
      return <ShimmerSimpleGallery col={4} imageHeight={300} caption />;
    }

    return products.map((item, index) => {
      return (
        <Col md={3} key={index}>
          <ProductCardListMobile data={item} carts={carts} />
        </Col>
      );
    });
  };

  const _renderProducts = () => {
    if (products.length === 0 && isLoading) {
      return <ShimmerSimpleGallery col={4} imageHeight={300} caption />;
    }

    return products.map((item, index) => {
      return (
        <Col key={index}>
          <ProductCard
            data={item}
            wishlistAction={wishlistAction}
            wishlists={wishlists}
            carts={carts}
            cartAction={cartAction}
          />
        </Col>
      );
    });
  };

  const panels = [
    {
      header: <CustomHeader>{t('wine-type')}</CustomHeader>,
      key: '1',
      content: renderAppellationFilter()
    },
    {
      header: <CustomHeader>{t('price-range')}</CustomHeader>,
      key: '2',
      content: renderPriceFilter()
    }
    // {
    //   header: <CustomHeader>{t('format')}</CustomHeader>,
    //   key: '3',
    //   content: renderSizeFilterComponent()
    // }
  ];

  const handleChangePage = number => {
    setPage(number);
    window.scrollTo(0, 0);
  };

  const renderSubFilter = () => {
    if (
      submenuParams === 'null' ||
      submenuParams === null ||
      submenuParams === ''
    ) {
      return <></>;
    }

    const submenuItems = submenuParams.split(',');
    const submenuObjects = submenuItems.map(item => {
      const decodedItem = decodeURIComponent(item);
      return {
        id: decodedItem,
        name: decodedItem,
        value: decodedItem,
        category: 'appellation'
      };
    });

    return (
      <FormControl component="fieldset">
        <RadioGroup
          value={appellationParams}
          onChange={handleChangeSubFilterAppellation}>
          <Grid container spacing={1}>
            {submenuObjects.map(item => (
              <Grid item xs={6} sm={3} key={item.id}>
                <FormControlLabel
                  className="small-radio-label"
                  value={item.id}
                  control={<Radio />}
                  label={item.name}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <Container>
      <Row>
        <SideBarContainer lg={2} xl={2}>
          <CollapseMenuContainer>
            <CollapseMenuComponent panels={panels} />
          </CollapseMenuContainer>
        </SideBarContainer>
        <Col md={12} lg={10} xl={10}>
          <TopFilter className="mb-2">
            <FilterTopContainer>
              <RadioFilterContainer>{renderSubFilter()}</RadioFilterContainer>
              <Col className="pt-2">{renderTotalItems()}</Col>
              <FilterTopLeftContainer>
                <FilterFlex>
                  <FilterLabel>{t('show')}:</FilterLabel>
                  <DropdownBtn title={item} variant="Secondary">
                    <Dropdown.Item onClick={() => setItem(12)}>
                      12
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItem(24)}>
                      24
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItem(36)}>
                      36
                    </Dropdown.Item>
                  </DropdownBtn>
                </FilterFlex>
              </FilterTopLeftContainer>
            </FilterTopContainer>
          </TopFilter>
          <Row className="pt-3">
            {view === 'grid' ? (
              <>
                <ProductsGrid>{_renderProducts()}</ProductsGrid>
              </>
            ) : (
              <>
                <ListTableContainer>
                  {_renderProductsTable()}
                </ListTableContainer>
                <ListContainer>{_ProductCardListMobile()}</ListContainer>
              </>
            )}
          </Row>
          <Pagination
            data={pagination}
            onChangePage={number => handleChangePage(number)}
          />
        </Col>
        {showHide && (
          <Navbar.Offcanvas
            id="offcanvasRightNavbar-expand-lg"
            onHide={() => toggleSidebar()}
            placement="start"
            style={{
              width: isMobile ? '85vw' : isTablet ? '40vw' : 'auto'
            }}
            show={showHide}>
            <Offcanvas.Header>
              <Offcanvas.Title>
                <SidebarHeader>{t('our-wines')}</SidebarHeader>
              </Offcanvas.Title>
              <CloseIcon onClick={() => toggleSidebar()} />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Row>
                <InputContainer3>
                  <InputContainer2>
                    <div>
                      <MiddleIcon />
                    </div>
                    <Input
                      onBlur={handleSearchBlur}
                      onKeyDown={handleSearchKeyDown}
                      onChange={event => {
                        setSearch(event.target.value);
                      }}
                      type="text"
                      placeholder={t('search')}
                    />
                  </InputContainer2>
                </InputContainer3>
              </Row>
              <CollapseMenuComponent panels={panels} />
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        )}
      </Row>
    </Container>
  );
};

export default ProductScreen;

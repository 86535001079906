import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { servicesRequest } from '../../Utils';
import { CONTENT_URL } from '../../Utils/serviceUrl.utils';
import {
  Container,
  Content,
  Image,
  Title
} from '../BlogDetail/BlogDetail.styled';
import { getContent, getTitle } from '../../Utils/general.utils';

const ContentContainer = ({ type }) => {
  const [content, setContent] = React.useState(null);
  const { i18n } = useTranslation();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(CONTENT_URL + '/detail', {
        params: {
          type
        }
      });

      setContent(data);
    } catch (e) {}
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  return (
    <Container>
      {content?.title === 'About Us' && (
        <Helmet>
          <title>About Us</title>
          <meta
            name="description"
            content="Since 1997 Wine Buff has grown to become one of the most trusted fine wine merchants in Hong Kong"
          />
        </Helmet>
      )}
      <Title className="mb-4">
        {getTitle(content?.title, content?.title_ch, i18n.language)}
      </Title>
      {content?.thumbnail && (
        <Image
          width={content?.width}
          height={content?.height}
          src={content?.thumbnail}
          className="img-fluid"
        />
      )}
      <Content
        className="mt-4"
        dangerouslySetInnerHTML={{ __html: getContent(content, i18n.language) }}
      />
    </Container>
  );
};

export default ContentContainer;

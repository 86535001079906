import React, { useState } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showHidePayload } from '../../../Redux/Actions/showHide.action';
import { ROUTE } from '../../../Constants';

import './FilterMenu.styled.scss';
import menuData from './menuData.json';
import { arrangeProductFilterParams } from '../Product.container';

export const shorterUrl = submenu => {
  return submenu.map(option => option.id).join(',');
};

const FilterMenu = props => {
  const { websiteListingParams } = props;

  const [openKeys, setOpenKeys] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);

  const { t } = useTranslation();

  const rootSubmenuKeys = menuData.country_list
    .filter(item => item.id && item.des !== null)
    .map(item => item.id);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => !openKeys.includes(key));
    if (!rootSubmenuKeys.includes(latestOpenKey)) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showHideSidebar = data => dispatch(showHidePayload(data));

  const handleFilterCountry = id => {
    setTimeout(() => {
      navigate(
        arrangeProductFilterParams({
          websiteListing: id
        })
      );
    }, 500);
  };

  const handleFilterGeneral = (id, submenu) => {
    navigate(
      arrangeProductFilterParams({
        websiteListing: id,
        subMenu:
          submenu === '' || submenu === 'null' || submenu === null
            ? ''
            : shorterUrl(submenu)
      })
    );
  };

  const handleFilterRegion = id => {
    navigate(
      arrangeProductFilterParams({
        category: id
      })
    );
  };

  const handleFilterAppellation = id => {
    navigate(
      arrangeProductFilterParams({
        appellation: id
      })
    );
  };

  const handleClick = (id, category, submenu, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    showHideSidebar(false);

    if (id === 'france') {
      setActiveMenu(null);
    } else if (id === 'all') {
      removeAllFilter();
    } else {
      if (category === 'general') {
        handleFilterGeneral(id, submenu);
      } else if (category === 'country') {
        handleFilterCountry(id);
      } else if (category === 'appellation') {
        handleFilterAppellation(id);
      } else if (category === 'region') {
        handleFilterRegion(id);
      }

      setActiveMenu(id);
    }
  };

  const removeAllFilter = () => {
    navigate(`/product`);
    setOpenKeys([]);
  };

  const inlineIndentStyle = {
    paddingLeft: 24
  };

  const handleWineCellarClick = () => {
    window.location.href = `/${ROUTE.wineCellar}`;
  };

  return (
    <Menu
      mode="inline"
      theme="light"
      inlineIndent={12}
      className="menu-no-border"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={websiteListingParams === '' ? [] : [websiteListingParams]}>
      {menuData.country_list.map(item => {
        return (
          <Menu.Item
            key={item.id}
            style={item.child === 'france' ? inlineIndentStyle : null}
            onClick={e => handleClick(item.id, item.category, item.submenu, e)}>
            {i18n.language === 'en' ? item.name : item.name_ch}
          </Menu.Item>
        );
      })}
      <Menu.Item onClick={handleWineCellarClick}>{t('wine-cellar')}</Menu.Item>
    </Menu>
  );
};

export default FilterMenu;

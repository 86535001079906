import React from 'react';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardBody,
  Container,
  Content,
  Map,
  Title,
  MapTabsContainer,
  ContentHeader,
  DescriptionContainer,
  Description
} from './Burgundy.styled';
import './Burgundy.scss';
import { FirstTitle, SecondTitle } from '../Blog/Blog.styled';
import { servicesRequest } from '../../Utils';
import { BURGUNDY_URL } from '../../Utils/serviceUrl.utils';
import { ROUTE } from '../../Constants';

const BurgundyContainer = () => {
  const [burgundies, setBurgundies] = React.useState([]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { contents }
        }
      } = await request.get(BURGUNDY_URL);

      setBurgundies(contents);
    } catch (e) {}
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  return (
    <Container className="burgundy-map">
      <Row>
        <Col md={12}>
          <FirstTitle>{t('map')}</FirstTitle>
          <SecondTitle>{t('burgundy-map')}</SecondTitle>
          <DescriptionContainer>
            <Description>{t('cote-d-or')}</Description>
          </DescriptionContainer>
        </Col>
      </Row>
      <MapTabsContainer>
        {burgundies.length > 0 && (
          <Tabs
            defaultActiveKey={burgundies[0]?.id}
            id="my-tabs"
            style={{ backgroundColor: 'white' }}>
            {burgundies.map((burgundy, index) => (
              <Tab key={index} eventKey={burgundy.id} title={burgundy.title}>
                <Card md={12} key={index}>
                  <CardBody>
                    <Col md={6} className="text-start">
                      <Map src={burgundy?.thumbnail} />
                    </Col>
                    <Col md={6} className="p-5">
                      <Title>{burgundy?.title}</Title>
                      <ContentHeader
                        dangerouslySetInnerHTML={{
                          __html: burgundy?.content
                        }}
                      />
                      {burgundy?.burgundy_subs?.length > 0 &&
                        burgundy?.burgundy_subs?.map((item, index) => (
                          <Content
                            key={index}
                            className="mt-1"
                            onClick={() => {
                              navigate('../' + ROUTE.burgundy + '/' + item.id);
                            }}>
                            {index + 1}. {item?.title}
                          </Content>
                        ))}
                    </Col>
                  </CardBody>
                </Card>
              </Tab>
            ))}
          </Tabs>
        )}
      </MapTabsContainer>
    </Container>
  );
};

export default BurgundyContainer;

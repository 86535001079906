import React from 'react';
import { useParams } from 'react-router-dom';

import { servicesRequest } from '../../Utils';
import { GALLERY_DETAIL_URL } from '../../Utils/serviceUrl.utils';
import {
  Container,
  Content,
  Readtime,
  Title,
  Image,
  Writer,
} from './ImagesDetail.styled';

const ImagesDetailScreen = () => {
  const [image, setBlog] = React.useState(null);
  const { id } = useParams();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(GALLERY_DETAIL_URL + id);

      setBlog(data);
    } catch (e) { }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  return (
    <Container>
      <Title className="mb-4">{image?.title}</Title>
      <Image src={image?.thumbnail} className="img-fluid" />
      <p className="mt-2">
        <Writer>{image?.author}</Writer>{' '}
      </p>
      <Content
        className="mt-4"
        dangerouslySetInnerHTML={{ __html: image?.content }}
      />
    </Container>
  );
};

export default ImagesDetailScreen;

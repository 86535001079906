import styled, { css } from 'styled-components';

import { mobile } from '../../Assets/layout';

export const ButtonContainer = styled.div`
  ${mobile(css`
    display: flex !important;
    justify-content: center !important;
  `)};
`;

export const ButtonStyled = styled.button`
  background: #4d1111;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  color: #ffffff;
  padding: 10px 37px;

  ${mobile(css`
    font-size: 15px;
    line-height: 23px;
  `)};
`;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { servicesRequest } from '../../Utils';
import { AUTH_OTP_URL } from '../../Utils/serviceUrl.utils';
import { ROUTE } from '../../Constants';

const ActivateAccountContainer = () => {
  const [isError, setIsError] = React.useState(false);

  const { email, otp } = useParams();
  const navigate = useNavigate();

  const checkOTP = async () => {
    try {
      const request = await servicesRequest();
      await request.post(AUTH_OTP_URL, { code: otp, email });
    } catch (error) {
      setIsError(true);
    } finally {
      setTimeout(() => {
        navigate(`/${ROUTE.root}`);
      }, 3000);
    }
  };

  React.useEffect(() => {
    checkOTP().catch(console.error);
  }, []);

  return (
    <div className="m-5 p-5">
      <h4 className="m-5 p-5">
        {isError
          ? 'Not authenticated on this page'
          : 'Your account has activated. Will redirecting you to main page now.'}
      </h4>
    </div>
  );
};

export default ActivateAccountContainer;

import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

import { Breadcrumb, Container, PlaceOrderButton, Title } from './Cart.styled';
import { CollapseMenu, ProductCartList } from '../../Components';
import RightSideComponent from './Components/RightSide.component';
import PaymentsComponent from './Components/Payments.component';
import { servicesRequest } from '../../Utils';
import './Cart.styled.scss';
import {
  ADDRESS_URL,
  PAYMENT_METHOD_URL,
  PRODUCT_CART_URL,
  PRODUCT_WISHLIST_URL,
  PURCHASE_URL
} from '../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import {
  OUT_DISTRICT,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  ROUTE,
  TRANSACTION_TYPE
} from '../../Constants';
import DeliveryComponent from './Components/Delivery.component';
import { checkJWT } from '../../Utils/general.utils';
import AddAddressComponent from '../../Screens/Account/Components/AddAddress.component';
import PaymeModalComponent from './Components/PaymeModal.component';
import BillingDetailsComponent from './Components/BillingDetails.component';
import ShippingDetailsComponent from './Components/ShippingDetails.component';
import CustomerRemarksComponent from '../Account/Components/CustomerRemarks.component';

const { Text } = Typography;

const discount = null;

const reformatSelectedPayment = value => {
  if (value === PAYMENT_TYPE.CREDIT_CARD2) {
    return PAYMENT_TYPE.CREDIT_CARD;
  }

  return value;
};

const CartScreen = () => {
  const [carts, setCarts] = React.useState([]);
  const [isPlaceOrder, setIsPlaceOrder] = React.useState(false);
  const [subTotal, setSubTotal] = React.useState(0);
  const [totalQuantity, setTotalQuantity] = React.useState(0);
  const [autoDiscount, setAutoDiscount] = React.useState(0);
  const [address, setAddress] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [disabled, setDisabled] = React.useState(true);
  const [deliveryFee, setDeliveryFee] = React.useState(0);
  const [selectedPayments, setSelectedPayments] = React.useState('');
  const [selectedShipment, setSelectedShipment] = React.useState(null);
  const [addressToEdit, setAddressToEdit] = React.useState(null);
  const [modal, setModal] = React.useState(false);

  const [paymeDeeplink, setPaymeDeeplink] = React.useState('');
  const [totalFinal, setTotalFinal] = React.useState(0);
  const [paymentTraceId, setPaymentTraceId] = React.useState('');

  const [paymentMethods, setPaymentMethods] = React.useState([]);

  const [billingDetails, setBillingDetails] = React.useState(null);
  const [shippingDetails, setShippingDetails] = React.useState(null);
  const [customerRemarks, setCustomerRemarks] = React.useState('');
  const [editMode, setEditMode] = React.useState(false);
  const [showCondition, setShowCondition] = React.useState(false);

  const [isLoadingToMakeOrder, setIsLoadingToMakeOrder] = React.useState(false);
  const [isSuccessToMakeOrder, setIsSuccessToMakeOrder] = React.useState(false);
  const [isLoadingFetchCart, setIsLoadingToFetchCart] = React.useState(false);

  const [isRedirectTo, setIsRedirectTo] = React.useState(false);

  const [isTnC, setIsTnC] = React.useState(true);

  const [step, setStep] = React.useState({
    product_step: true,
    payment_step: false,
    deliver_step: false,
    remarks_step: false
  });
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // temporary disabled
  // React.useEffect(() => {
  //   if (shippingDetails !== null && selectedShipment !== null) {
  //     let deliveryFeeTemp;
  //
  //     if (selectedShipment?.type === 'NO_FEE') {
  //       deliveryFeeTemp = 0;
  //     } else {
  //       const districtTemp = shippingDetails?.district;
  //       const checkIfOutDistrict = OUT_DISTRICT.includes(districtTemp);
  //
  //       if (checkIfOutDistrict) {
  //         // set delivery HKD 400 if the district is outside
  //         deliveryFeeTemp = 400;
  //       } else {
  //         // set delivery HKD 200 if the district is outside
  //         deliveryFeeTemp = 200;
  //
  //         if (carts?.length > 0) {
  //           let standardBottles = 0;
  //           let amountTemp = 0;
  //
  //           for (const cart of carts) {
  //             amountTemp += Number(cart?.subTotal);
  //
  //             if (cart?.products?.size === '750') {
  //               standardBottles += Number(cart?.qty);
  //             }
  //           }
  //
  //           if (standardBottles >= 6 || amountTemp >= 1500) {
  //             deliveryFeeTemp = 0;
  //           }
  //         }
  //       }
  //     }
  //
  //     setDeliveryFee(deliveryFeeTemp);
  //   }
  // }, [shippingDetails, selectedShipment, carts]);

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  let reduxState = useSelector(state => state.cartsReducer);
  let { cartsContainer: tempLocalCartsContainer } = reduxState;

  tempLocalCartsContainer = JSON.parse(tempLocalCartsContainer);

  const fetching = async () => {
    setIsLoadingToFetchCart(true);
    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        const {
          data: { data }
        } = await authorizedRequest.get(PRODUCT_CART_URL + '/products');

        setCarts(data?.carts);
        setSubTotal(data?.subTotal);
        setAutoDiscount(data?.subTotalDiscount);
        setTotalQuantity(data?.totalQuantity);
      } else {
        setCarts(tempLocalCartsContainer?.carts);
        setSubTotal(tempLocalCartsContainer?.subTotal);
        setAutoDiscount(tempLocalCartsContainer?.subTotalDiscount);
        setTotalQuantity(tempLocalCartsContainer?.totalQuantity);
      }
    } catch (error) {
      showNotification('Sorry, unable to get carts. Please try again.');
    } finally {
      setIsLoadingToFetchCart(false);
    }
  };

  const fetchAddress = async () => {
    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        const {
          data: { data }
        } = await authorizedRequest.get(ADDRESS_URL);
        setAddress(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchingPaymentMethod = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { contents }
        }
      } = await request.get(PAYMENT_METHOD_URL, {
        params: {
          page: 0,
          size: 10,
          search: '',
          status: '1'
        }
      });

      setPaymentMethods(contents);
    } catch (error) {
      showNotification(
        'Sorry, we unable to fetch payment method. Please try again'
      );
    }
  };

  React.useEffect(() => {
    if (isPlaceOrder) {
      fetchAddress().catch(console.error);
      setStep({
        ...step,
        product_step: false,
        payment_step: true
      });
    }
  }, [isPlaceOrder]);

  React.useEffect(() => {
    fetching().catch(console.error);
    fetchingPaymentMethod().catch(console.error);
  }, []);

  const addToCart = async (product_code, qty) => {
    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        await authorizedRequest.post(PRODUCT_CART_URL, {
          product_code,
          qty
        });
      }

      await fetching();
    } catch (e) {
      showNotification('Sorry, we unable to add to cart. Please try again');
    }
  };

  const deleteCart = async product_code => {
    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        await authorizedRequest.post(PRODUCT_CART_URL, {
          product_code: product_code,
          qty: 0
        });

        window.location.reload();
      }
    } catch (e) {
      showNotification('Sorry, we unable to delete cart. Please try again');
    }
  };

  const moveToWishlist = async product_code => {
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.post(PRODUCT_WISHLIST_URL, {
        product: product_code,
        type: TRANSACTION_TYPE.EXTERNAL
      });
      await deleteCart(product_code);
      showNotification('Product has been move to wishlist');
    } catch (error) {
      showNotification(
        'Sorry, we unable to move to wishlist. Please try again'
      );
    }
  };

  const paymentsProps = () => ({
    title: `${t('payments')}`,
    Component: PaymentsComponent,
    collapse: true,
    dataToHOC: {
      setSelectedPayments,
      selectedPayments,
      paymentMethods,
      setStep,
      setIsPlaceOrder
    }
  });

  const shippingProps = () => ({
    title: `${t('delivery')}`,
    Component: DeliveryComponent,
    collapse: true,
    dataToHOC: {
      address,
      selectedAddress,
      setSelectedAddress,
      selectedShipment,
      setSelectedShipment,
      modal,
      setModal,
      setStep
    }
  });

  const billingProps = () => ({
    title: `${t('billing-details')}`,
    Component: BillingDetailsComponent,
    collapse: true,
    dataToHOC: {
      address,
      billingDetails,
      setBillingDetails,
      setEditMode,
      selectedShipment,
      setStep,
      selectedShipmentMethod
    }
  });

  const shippingDetailsProps = () => ({
    title: `${t('shipping-details')}`,
    Component: ShippingDetailsComponent,
    collapse: true,
    dataToHOC: {
      address,
      billingDetails,
      shippingDetails,
      setShippingDetails,
      setEditMode,
      setStep
    }
  });

  const customerRemarksProps = () => ({
    title: `${t('remarks')}`,
    Component: CustomerRemarksComponent,
    collapse: true,
    dataToHOC: {
      customerRemarks,
      setCustomerRemarks,
      isTnC,
      setIsTnC,
      setStep
    }
  });

  const rightSideProps = () => ({
    isPlaceOrder,
    setIsPlaceOrder,
    subTotal,
    discount,
    autoDiscount,
    deliveryFee,
    carts,
    setStep
  });

  const productListProps = () => ({
    carts,
    addToCart,
    deleteCart,
    moveToWishlist,
    isLoadingFetchCart
  });

  const handleCheckoutFlow = async () => {
    if (selectedPayments === PAYMENT_TYPE.PAYME) {
      return await proceedToCreatePaymeRequest();
    } else {
      return await proceedToCheckout(PAYMENT_STATUS.PENDING);
    }
  };

  const proceedToCreatePaymeRequest = async () => {
    try {
      setIsLoadingToMakeOrder(true);

      const reformatCarts = () =>
        carts.map(cart => {
          let cartFormatted = {
            ...cart,
            productName: cart?.products?.name_en,
            productCode: cart?.products?.code,
            productPrice: cart?.products?.bprice,
            records: JSON.stringify(cart?.products)
          };

          delete cartFormatted.products;
          return cartFormatted;
        });

      const totalDiscount = 0;
      const subTotalAfterDiscount = subTotal - totalDiscount;

      const payload = {
        carts: reformatCarts(),
        total: subTotal,
        totalAfterDiscount: subTotalAfterDiscount,
        totalQuantity,
        discount,
        autoDiscount,
        payments: reformatSelectedPayment(selectedPayments),
        deliveryFee,
        paymentTraceId,
        paymentStatus: 'PENDING',
        remarks: customerRemarks,
        billingDetails,
        shippingDetails,
        shipmentMethod: selectedShipment
      };

      const authorizedRequest = await servicesRequest();
      const response = await authorizedRequest.post(
        PURCHASE_URL + '/payme/create',
        payload
      );

      const deeplink = response?.data?.data?.deepLink;
      const totalFinalResponse = response?.data?.data?.totalFinal;
      const paymentTraceIdResponse = response?.data?.data?.paymentTraceId;

      setPaymeDeeplink(deeplink);
      setTotalFinal(totalFinalResponse);
      setPaymentTraceId(paymentTraceIdResponse);

      if (window.innerWidth <= 800) {
        window.open(deeplink, '_blank', 'noreferrer');
      }
    } catch (error) {
      const errorCode = error?.response?.data?.data?.errorCode;

      if (errorCode === 'EB099') {
        return showNotification(
          'We apologize that PayMe is currently unavailable and we are fixing it. Please try again later or use another payment option. Thank you. '
        );
      }
    }
  };

  const proceedToCheckout = async (paymentStatus = 'PENDING') => {
    setIsLoadingToMakeOrder(true);

    const reformatCarts = () =>
      carts.map(cart => {
        let cartFormatted = {
          ...cart,
          productName: cart?.products?.name_en,
          productCode: cart?.products?.code,
          productPrice: cart?.products?.bprice,
          records: JSON.stringify(cart?.products)
        };

        delete cartFormatted.products;
        return cartFormatted;
      });

    const totalDiscount = 0;
    const subTotalAfterDiscount = subTotal - totalDiscount;

    const payload = {
      carts: reformatCarts(),
      total: subTotal,
      totalAfterDiscount: subTotalAfterDiscount,
      totalQuantity,
      discount,
      autoDiscount,
      payments: reformatSelectedPayment(selectedPayments),
      deliveryFee,
      paymentTraceId,
      paymentStatus,
      remarks: customerRemarks,
      billingDetails,
      shippingDetails,
      shipmentMethod: selectedShipment
    };

    try {
      if (selectedPayments === PAYMENT_TYPE.PAYME) {
        showNotification(
          'Congratulations, your payment is successful and order is confirmed. Kindly check your member page and email for record. Thank you.'
        );
        setTimeout(() => {
          navigate(`/${ROUTE.account}`);
        }, 2500);
      } else {
        if (
          selectedPayments === PAYMENT_TYPE.CREDIT_CARD ||
          selectedPayments === PAYMENT_TYPE.CREDIT_CARD2
        ) {
          setIsRedirectTo(true);
        }

        const authorizedRequest = await servicesRequest();
        const response = await authorizedRequest.post(PURCHASE_URL, payload);

        setIsSuccessToMakeOrder(true);
        switch (selectedPayments) {
          case PAYMENT_TYPE.CREDIT_CARD:
            const purchaseId = response.data.data.purchaseId;
            const priceToBill = response.data.data.totalFinal;

            navigate(`/redirect/${purchaseId}/${priceToBill}`);
            break;
          case PAYMENT_TYPE.CREDIT_CARD2:
            navigate(
              `/redirect/${response.data.data.purchaseId}/${response.data.data.totalFinal}`
            );
            break;
          default:
            showNotification(`${t('thank-you-for-your-order')}`);

            setTimeout(() => {
              navigate(`/${ROUTE.account}`);
            }, 3500);
        }
      }
    } catch (error) {
      if (error?.response?.data?.data?.errorCode === 890) {
        showNotification(
          "We're unable to process your order as one of the selected products is currently unavailable. Please try again later."
        );
        fetching().catch(console.error);
      } else {
        showNotification('Sorry, your order is failed. Please try again');
      }
      setIsSuccessToMakeOrder(false);
    } finally {
      setIsLoadingToMakeOrder(false);
      setIsRedirectTo(false);
    }
  };

  const selectedShipmentMethod =
    shippingProps().dataToHOC.selectedShipment?.type === 'NO_FEE';

  React.useEffect(() => {
    let isDisabled = true;
    let condition = false;

    if (
      billingDetails !== null &&
      selectedPayments !== '' &&
      shippingDetails !== null
    ) {
      isDisabled = false;
    }

    if (
      billingDetails !== null &&
      selectedPayments !== '' &&
      selectedShipmentMethod &&
      shippingDetails === null
    ) {
      isDisabled = false;
    }

    if (
      billingDetails !== null &&
      selectedPayments !== '' &&
      selectedShipmentMethod &&
      shippingDetails === null
    ) {
      condition = true;
    }

    if (
      billingDetails !== null &&
      selectedPayments !== '' &&
      !selectedShipmentMethod &&
      shippingDetails !== null
    ) {
      condition = true;
    }

    if (
      billingDetails !== null &&
      selectedPayments !== '' &&
      selectedShipmentMethod &&
      shippingDetails !== null
    ) {
      condition = true;
    }

    setDisabled(isDisabled);
    setShowCondition(condition);
  }, [
    billingDetails,
    shippingDetails,
    selectedShipment,
    selectedPayments,
    selectedShipmentMethod
  ]);

  const addAddressProps = () => ({
    modal,
    setModal,
    addressToEdit,
    fetchAddress
  });

  const isEligibleProceedToCheckout =
    isTnC && !isLoadingToMakeOrder && !isSuccessToMakeOrder;

  return (
    <>
      <Container>
        <Title className="mb-5">{t('my-cart')}</Title>

        {!step.product_step && (
          <div className="my-2">
            <center>
              <Breadcrumb>
                <span className={step.payment_step ? 'step-selected' : ''}>
                  {' '}
                  1. {t('payment-method')}{' '}
                </span>{' '}
                >{' '}
                <span className={step.deliver_step ? 'step-selected' : ''}>
                  2. {t('delivery-method')}
                </span>{' '}
                >{' '}
                <span className={step.remarks_step ? 'step-selected' : ''}>
                  3. {t('customer-remark')}
                </span>
              </Breadcrumb>
            </center>
          </div>
        )}

        <Row className="gy-4">
          <Col lg={8} md={12} sm={12}>
            {step.product_step && <ProductCartList {...productListProps()} />}

            {isPlaceOrder && step.payment_step && (
              <CollapseMenu {...paymentsProps()} />
            )}
            {isPlaceOrder && step.deliver_step && (
              <CollapseMenu {...shippingProps()} />
            )}
            {isPlaceOrder && step.deliver_step && (
              <CollapseMenu {...billingProps()} />
            )}
            {isPlaceOrder &&
              billingDetails !== null &&
              !selectedShipmentMethod &&
              step.deliver_step && <CollapseMenu {...shippingDetailsProps()} />}

            {isPlaceOrder &&
              billingDetails !== null &&
              showCondition === true &&
              step.remarks_step && <CollapseMenu {...customerRemarksProps()} />}

            {step.remarks_step && (
              <PlaceOrderButton
                disabled={!isEligibleProceedToCheckout}
                className={
                  !isEligibleProceedToCheckout ? 'disabled mt-2' : 'mt-2'
                }
                onClick={() => handleCheckoutFlow()}>
                {!isEligibleProceedToCheckout && (
                  <>
                    <Spinner size="sm" animation="border" variant="light" />{' '}
                  </>
                )}
                {t('proceed-to-checkout')}
              </PlaceOrderButton>
            )}
          </Col>
          <Col lg={4} md={12} sm={12}>
            <RightSideComponent {...rightSideProps()} />
          </Col>
        </Row>
      </Container>

      <Modal
        footer={[]}
        title={addressToEdit === null ? 'Add New Address' : 'Update Address'}
        open={modal}
        onCancel={() => {
          setModal(false);
          setAddressToEdit(false);
        }}>
        <AddAddressComponent {...addAddressProps()} />
      </Modal>

      {/* component for payme modal, showing payme barcode */}
      <Modal
        closable={false}
        width={1000}
        footer={[]}
        centered={true}
        keyboard={false}
        title={[]}
        onCancel={() => {
          setPaymeDeeplink('');
          setPaymentTraceId('');
        }}
        open={paymeDeeplink !== ''}>
        <PaymeModalComponent
          url={paymeDeeplink}
          price={totalFinal}
          paymentTraceId={paymentTraceId}
          onComplete={async status => {
            await proceedToCheckout(status);
          }}
          onFailed={() => {
            setPaymeDeeplink('');
            setPaymentTraceId('');
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }}
        />
      </Modal>

      <Modal centered={true} open={isRedirectTo} closable={false} footer={null}>
        <Typography>
          <Space>
            <LoadingOutlined style={{ fontSize: '24px', color: '#cda257' }} />
            <Text>Please wait while we redirect you to the payment page.</Text>
          </Space>
        </Typography>
      </Modal>
    </>
  );
};

export default CartScreen;

import React from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import { ImageList, Pagination } from '../../Components';
import {
  Container,
  ContentContainer,
  Description,
  FirstTitle,
  Image,
  SearchButton,
  SearchContainer,
  SearchInput,
  SecondTitle
} from './Images.styled';
import {
  DropdownBtn,
  FilterFlex,
  FilterLabel,
  TopFilter,
  TopIconContainer,
  FilterTopContainer,
  FilterTopLeftContainer,
  TotalItemContainer,
} from '../Product/Product.styled';
import { servicesRequest } from '../../Utils';
import { GALLERY_URL } from '../../Utils/serviceUrl.utils';

const ImagesScreen = () => {
  const [images, setImages] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [pageSize, setPaeSize] = React.useState(6);
  const [pagination, setPagination] = React.useState({
    page: '1',
    total_page: '1',
    total_item: '1',
    itemperpage: '1'
  });
  const { t, i18n } = useTranslation();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data
        }
      } = await request.get(GALLERY_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      const paginationData = {
        itemperpage: pageSize,
        page: data.currentPage,
        total_item: data.totalItems,
        total_page: data.totalPages
      }

      setPagination(paginationData);
      setTotalItems(data.totalItems);
      setImages(data.contents);
    } catch (e) { }
  };
  React.useEffect(() => {
    fetching().catch(console.error);
  }, [pages, totalItems, pageSize]);

  const paginationProps = () => ({
    data: { total_page: totalItems, pageSize },
    onChangePage: page => {
      setPages(page - 1);
    }
  });

  return (
    <Container>
      <Image />
      <Row>
        <Col md={12}>
          <FirstTitle>{t('photo-gallery')}</FirstTitle>
          <SecondTitle>{t('photo-gallery')}</SecondTitle>
          <Description>
            {t('get-updated-photo')}
          </Description>
        </Col>
        <Col>
          <SearchContainer className='p-4'>
            <SearchInput
              onChange={e => setSearch(e.target.value)}
              placeholder={t('enter-your-keywords')}
            />
            <SearchButton
              onClick={() => {
                fetching().catch(console.error);
              }}>
              {t('search')}
            </SearchButton>
          </SearchContainer>
        </Col>
      </Row>
      <Row>
        <TopFilter className="mb-2">
          <FilterTopContainer className='px-4'>
            <Col className="pt-2"><FilterLabel>
              <TopIconContainer className="product-tabel">
                <TotalItemContainer>
                  {t('showing')} {pagination.page * pagination.itemperpage + 1} -{' '}
                  {pagination.page * pagination.itemperpage + pagination.itemperpage}{' '}
                  {t('of')} {pagination.total_item}  {t('items')}
                </TotalItemContainer>
              </TopIconContainer>
            </FilterLabel>
            </Col>
            <FilterTopLeftContainer>
              <FilterFlex>
                <FilterLabel>{t('show')}:</FilterLabel>
                <DropdownBtn title={pageSize} variant="Secondary">
                  <Dropdown.Item onClick={() => setPaeSize(6)}>
                    6
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setPaeSize(24)}>
                    24
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setPaeSize(36)}>
                    36
                  </Dropdown.Item>
                </DropdownBtn>
              </FilterFlex>
              <FilterFlex className="px-3">
                <FilterLabel> {t('position')}</FilterLabel>
                <DropdownBtn title="Position" variant="Secondary">
                  <Dropdown.Item href="#">1</Dropdown.Item>
                  <Dropdown.Item href="#">2</Dropdown.Item>
                  <Dropdown.Item href="#">3</Dropdown.Item>
                </DropdownBtn>
              </FilterFlex>
            </FilterTopLeftContainer>
          </FilterTopContainer>
        </TopFilter>
      </Row>
      <ContentContainer>
        <ImageList data={images} />
        <Pagination {...paginationProps()} />
      </ContentContainer>
    </Container>
  );
};

export default ImagesScreen;

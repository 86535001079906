import React from 'react';

import { FooterDesktop } from './Footer.Desktop.component';
import { FooterMobile } from './Footer.Mobile.component';
import './Footer.styled.scss';

const FooterComponent = () => {
  return (
    <>
      <FooterDesktop />
      <FooterMobile />
    </>
  );
};

export default FooterComponent;

import { Col, Row } from 'react-bootstrap';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  EnquiriesTitle,
  FormContainer,
  Input,
  Button,
  FormCol,
  TextArea
} from './ContactUsForm.styled';
import { servicesRequest } from '../../Utils';
import { CONTACT_ENQUIRIES_URL } from '../../Utils/serviceUrl.utils';

const ContactUsFormComponent = () => {
  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      country: '',
      company_name: '',
      interested_in: '',
      message: ''
    }
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      message: 'Your message has been sent',
      description: 'Thank you contact us. We will reach you soon',
      duration: 3
    });
  };

  const onSubmit = async data => {
    try {
      const request = await servicesRequest();
      await request.post(CONTACT_ENQUIRIES_URL, data);
      reset();
      openNotification();
    } catch (error) {}
  };

  return (
    <>
      {contextHolder}
      <FormContainer>
        <Row className="m-0">
          <Col className="d-flex align-items-center justify-content-center">
            <EnquiriesTitle>{t('have-a-enquiries')}</EnquiriesTitle>
          </Col>
          <FormCol xl={6} md={6}>
            <Row className="m-0">
              <Col md={6} xl={6} className="pl-0">
                <Input
                  error={errors?.name}
                  type="text"
                  placeholder={t('your-name')}
                  {...register('name', { required: true })}
                />
              </Col>
              <Col md={6} xl={6} className="pl-0">
                <Input
                  error={errors?.email}
                  type="email"
                  placeholder={t('your-email')}
                  {...register('email', {
                    required: true,
                    pattern: /^\S+@\S+$/i
                  })}
                />
              </Col>
              <Col md={6} xl={6} className="pl-0">
                <Input
                  error={errors?.phone}
                  type="text"
                  placeholder={t('phone-number')}
                  {...register('phone', { required: true })}
                />
              </Col>
              <Col md={6} xl={6} className="pl-0">
                <Input
                  error={errors?.country}
                  type="text"
                  placeholder={t('country')}
                  {...register('country', { required: true })}
                />
              </Col>
              <Col md={6} xl={6} className="pl-0">
                <Input
                  error={errors?.company_name}
                  type="text"
                  placeholder={t('company-name')}
                  {...register('company_name', { required: true })}
                />
              </Col>
              <Col md={6} xl={6} className="pl-0">
                <Input
                  error={errors?.interested_in}
                  type="text"
                  placeholder={t('interested-in')}
                  {...register('interested_in', { required: true })}
                />
              </Col>
              <Col md={12} xl={12} className="pl-0">
                <TextArea
                  error={errors?.message}
                  type="text-area"
                  placeholder={t('message')}
                  {...register('message', { required: true })}
                />
              </Col>
            </Row>
            <Button onClick={handleSubmit(onSubmit)}>{t('submit')}</Button>
          </FormCol>
        </Row>
      </FormContainer>
    </>
  );
};

export default ContactUsFormComponent;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { servicesRequest } from '../../Utils';
import {
  PRODUCT_CART_URL,
  PRODUCT_URL,
  PRODUCT_WISHLIST_URL
} from '../../Utils/serviceUrl.utils';
import ProductComponent from './Product.component';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import {
  addCart,
  addCartPayload,
  setDefaultCart
} from '../../Redux/Actions/carts.action';
import { checkJWT } from '../../Utils/general.utils';

export const arrangeProductFilterParams = params => {
  const {
    category = '',
    websiteListing = '',
    country = '',
    appellation = '',
    region = '',
    filterSize = '',
    sortByLatest = false,
    subMenu = ''
  } = params;

  return `/product?category=${category}&website-listing=${websiteListing}&country=${country}&appellation=${appellation}&region=${region}&filter-size=${filterSize}&sort=${sortByLatest}&submenu=${subMenu}`;
};

const ProductContainer = ({ categoryCode = '', categoryRegion = '' }) => {
  const [products, setProducts] = React.useState([]);
  const [wishlists, setWishlists] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [item, setItem] = React.useState(36);
  const [view, setView] = React.useState('grid');
  const [carts, setCarts] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    page: '1',
    total_page: '1',
    total_item: '1',
    itemperpage: '20'
  });
  const [isLoading, setIsLoading] = React.useState(true);

  const [yearFilter, setYearFilter] = React.useState('');
  const [sizeFilter, setSizeFilter] = React.useState('');

  // const [minPrice, setMinPrice] = React.useState(null);
  // const [maxPrice, setMaxPrice] = React.useState(null);
  const [fixedMaxPrice, setFixedMaxPrice] = React.useState(null);
  const [fixedMinPrice, setFixedMinPrice] = React.useState(null);

  const [filterSize, setFilterSize] = React.useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const addToCart = (amount = 1) => dispatch(addCart(amount));
  const addToCartPayload = payload => dispatch(addCartPayload(payload));
  const setDefaultAmountCart = (amount = 1) => dispatch(setDefaultCart(amount));

  const { filterRegion: filterRegion } = useSelector(
    state => state.filterRegionReducer
  );

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const searchParams = queryParams.get('q') || '';
  const sizeFilterParams = queryParams.get('filter-size') || '';
  const categoryFilterParams = queryParams.get('category') || '';
  const websiteListingParams = queryParams.get('website-listing') || '';
  const countryParams = queryParams.get('country') || '';
  const appellationParams = queryParams.get('appellation') || '';
  const regionParams = queryParams.get('region') || '';
  const submenuParams = queryParams.get('submenu') || null;
  const sortByLatestParams = queryParams.get('sort') || false;
  const maxPriceParams = queryParams.get('max-price') || fixedMaxPrice;
  const minPriceParams = queryParams.get('min-price') || fixedMinPrice;

  const fetching = async () => {
    try {
      setIsLoading(true);
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_URL, {
        itemCode: '',
        categoryCode: categoryFilterParams,
        websiteListing: websiteListingParams,
        itemName: searchParams,
        itemPerPage: item,
        pageCount: page,
        minPrice: Number(minPriceParams),
        maxPrice: Number(maxPriceParams),
        categoryYear: yearFilter,
        categorySize: sizeFilterParams,
        categoryRegion: categoryRegion,
        region: regionParams,
        country: countryParams,
        onlineStore: '1',
        appellation: appellationParams,
        sortByDate: sortByLatestParams
      });

      setProducts(data.products);
      setPagination(data.pagination);
      setFixedMaxPrice(Number(data?.highestPrice));
      setFixedMinPrice(Number(data?.lowestPrice));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchingWishlist = async () => {
    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        const {
          data: { data }
        } = await authorizedRequest.get(PRODUCT_WISHLIST_URL);

        setWishlists(data);
      }
    } catch (error) {
      showNotification(
        'Sorry, we unable to fetch your wishlist. Please try again'
      );
    }
  };
  const fetchingCarts = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(PRODUCT_CART_URL + '/products');

      setDefaultAmountCart(data.carts.length);
      const dataCode = data?.carts.map(item => item.products.code);
      setCarts(dataCode);
    } catch (error) {
      showNotification('Sorry, we unable to fetch your Cart. Please try again');
    }
  };

  const wishlistAction = async id => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_WISHLIST_URL, {
        product: id
      });
      setWishlists(data);
    } catch (error) {
      showNotification('Something went wrong. Try again later');
    }
  };

  const cartAction = async product => {
    const { code } = product;

    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        await authorizedRequest.post(PRODUCT_CART_URL, {
          product_code: code,
          qty: 1
        });
      } else {
        addToCartPayload({
          discount: 0,
          discountId: '',
          products: product,
          qty: 1,
          subTotal: Number(product?.bprice),
          subTotalAfterDiscount: Number(product?.bprice)
        });
      }

      addToCart();
      showNotification(
        <>
          <div>{t('successfully-added-cart')}</div>
          <a href="/cart">{t('go-to-checkout')}</a>
        </>
      );
    } catch (e) {
      showNotification(
        'Sorry, unable to add product to cart. Please try again'
      );
    }
  };

  // const handlePriceFilter = price => {
  //   setMaxPrice(price.max);
  //   setMinPrice(price.min);
  // };

  const handleYearFilter = year => {
    setYearFilter(year);
  };

  // const rangeSelector = data => {
  //   setMaxPrice(data[1]);
  //   setMinPrice(data[0]);
  // };

  const filterPriceAndFormat = (sum, sizeFilter) => {
    const arrangeParams =
      arrangeProductFilterParams({
        category: categoryFilterParams,
        websiteListing: websiteListingParams,
        country: categoryFilterParams,
        appellation: appellationParams,
        region: regionParams,
        filterSize: sizeFilter
      }) + `&max-price=${sum[1]}&min-price=${sum[0]}`;

    navigate(arrangeParams);
  };

  const props = {
    products,
    setProducts,
    wishlists,
    setWishlists,
    page,
    setPage,
    item,
    setItem,
    setView,
    view,
    pagination,
    setPagination,
    wishlistAction,
    cartAction,
    carts,
    // handlePriceFilter,
    minPrice: minPriceParams,
    maxPrice: maxPriceParams,
    fixedMaxPrice,
    fixedMinPrice,
    handleYearFilter,
    yearFilter,
    setSizeFilter,
    sizeFilter,
    // rangeSelector,
    isLoading,
    searchParams,
    sizeFilterParams,
    websiteListingParams,
    countryParams,
    appellationParams,
    regionParams,
    sortByLatestParams,
    categoryFilterParams,
    submenuParams,
    filterSize,
    setFilterSize,
    filterPriceAndFormat
  };

  // React.useEffect(() => {
  //   if (fixedMaxPrice !== null) {
  //     setMaxPrice(fixedMaxPrice);
  //   }
  //   if (fixedMinPrice !== null) {
  //     setMinPrice(fixedMinPrice);
  //   }
  // }, [fixedMaxPrice, fixedMinPrice]);

  React.useEffect(() => {
    fetching().catch(console.error);
    localStorage.getItem('token') &&
      fetchingWishlist().catch(console.error) &&
      fetchingCarts().catch(console.error);
  }, [
    page,
    item,
    categoryCode,
    yearFilter,
    sizeFilter,
    maxPriceParams,
    minPriceParams,
    filterRegion,
    categoryRegion,
    searchParams,
    sizeFilterParams,
    categoryFilterParams,
    websiteListingParams,
    countryParams,
    appellationParams,
    regionParams,
    sortByLatestParams
  ]);

  return <ProductComponent {...props} />;
};

export default ProductContainer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';

import { SoftTextArea } from '../../SignIn/SignIn.styled';

const CustomerRemarksComponent = props => {
  const { customerRemarks, setCustomerRemarks, isTnC, setIsTnC } = props;
  const { t } = useTranslation();

  return (
    <div>
      <SoftTextArea
        placeholder={t('write-your-remarks')}
        value={customerRemarks}
        onChange={event => {
          setCustomerRemarks(event.target.value);
        }}
      />

      <Checkbox
        onClick={() => setIsTnC(!isTnC)}
        className="mb-2"
        checked={isTnC}>
        {t('accept-tos')}{' '}
        <a href="./term-of-use" target="_blank" className="text-dark">
          {t('tos')}{' '}
        </a>
      </Checkbox>
    </div>
  );
};

export default CustomerRemarksComponent;

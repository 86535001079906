import {
  FILTER_COUNTRY_PAYLOAD
} from '../Actions/action.types';

const filterCountryReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_COUNTRY_PAYLOAD:
      return { filterCountry: action.payload };
    default:
      return state
  }
};
export default filterCountryReducer;

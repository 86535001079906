import React from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment/moment';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import i18n from '../../../i18n';
import { reformatString } from '../../../Utils/general.utils';
import { ColTitle, DetailContainer, Table } from '../Account.styled';
import { DisplayFlexSpaceBetween } from '../../../Assets/GlobalStyled';

const OrderDetailComponent = props => {
  const { setShowOrderDetails, showOrderDetails: data } = props;

  const { t, i18n } = useTranslation();
  let {
    address: {
      billingDetails,
      shippingDetails,
      shipmentMethod,
      remarks,
      deliveryStatus
    },
    id,
    payments: { paymentMethod, paymentStatus },
    createdAt,
    purchase_products: products,
    deliveryFee,
    totalFinal
  } = data;

  createdAt = moment(createdAt).format('YYYY-MM-DD');
  paymentStatus = reformatString(paymentStatus);
  deliveryStatus = reformatString(deliveryStatus);
  billingDetails = JSON.parse(billingDetails);
  shippingDetails = JSON.parse(shippingDetails);

  const _renderComponentRow = (label, value) => {
    return (
      <Col md={12}>
        <Row>
          <Col md={4}>
            <b>{label}</b>
          </Col>
          <Col md={8}>{value}</Col>
        </Row>
      </Col>
    );
  };

  return (
    <div className="mt-5">
      <DisplayFlexSpaceBetween>
        <h4>{t('order-detail')}</h4>
        <Button
          onClick={() => {
            setShowOrderDetails(null);
          }}>
          Back
        </Button>
      </DisplayFlexSpaceBetween>
      <hr />
      <Row className="gy-3">
        {_renderComponentRow(`${t('order-detail')}`, id)}
        {_renderComponentRow(`${t('order-date')}`, createdAt)}
        {_renderComponentRow(`${t('payment-method')}`, paymentMethod)}
        {_renderComponentRow(`${t('payment-status')}`, paymentStatus)}
        {_renderComponentRow(`${t('delivery-method')}`, shipmentMethod)}
        {/* {_renderComponentRow('Delivery Status', deliveryStatus)} */}
        {_renderComponentRow(`${t('customer-remark')}`, remarks)}
      </Row>
      <DetailContainer className="mt-4">
        <Row>
          <Col md={6}>
            <ColTitle className="p-3">{t('bill-to-information')}</ColTitle>
            <div className="m-3">
              <p className="m-0 lh-4">{billingDetails?.name}</p>
              <p className="m-0 lh-4">{billingDetails?.email}</p>
              <p className="m-0 lh-4">{billingDetails?.phoneNumber}</p>
              <p className="m-0 lh-4">
                {billingDetails?.addressLine}, {billingDetails?.district}
              </p>
            </div>
          </Col>
          <Col md={6}>
            <ColTitle className="p-3">{t('shipment-address')}</ColTitle>
            <div className="m-3">
              <p className="m-0 lh-4">{shippingDetails?.name}</p>
              <p className="m-0 lh-4">{shippingDetails?.email}</p>
              <p className="m-0 lh-4">{shippingDetails?.phoneNumber}</p>
              <p className="m-0 lh-4">
                {shippingDetails?.addressLine}, {shippingDetails?.district}
              </p>
            </div>
          </Col>
        </Row>
        <div className="mt-3 px-3">
          <Table responsive bordered>
            <thead>
              <tr>
                <th>SKU</th>
                <th>{t('product-name')}</th>
                <th className="text-end">{t('price')}</th>
                <th className="text-center">{t('quantity')}</th>
                <th className="text-end">{t('sub-toal')}</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={index}>
                  <td>{product?.productCode}</td>
                  <td>{product?.productName}</td>
                  <td className="text-end">HK$ {product?.productPrice}</td>
                  <td className="text-center">{product?.qty}</td>
                  <td className="text-end">HK$ {product?.subTotal}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={4}>{t('delivery-fee')}</td>
                <td className="text-end">HK$ {deliveryFee}</td>
              </tr>
              <tr>
                <td colSpan={4}>{t('price-total')}</td>
                <td className="text-end fw-bold">HK$ {totalFinal}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </DetailContainer>
    </div>
  );
};

export default OrderDetailComponent;

import styled, { css } from 'styled-components';

import { DisplayFlex } from '../../Assets/GlobalStyled';
import { mobile } from '../../Assets/layout';
import { Row } from 'react-bootstrap';

export const Description = styled.p`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #000000;

  ${mobile(css`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding: 0 2em;
  `)};
`;

export const Image = styled.div`
  background-image: url(${props => props.src});
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  margin-bottom: 5vh;
`;

export const SectionTitle = styled.h1`
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  color: #13101e;
  text-align: center;

  ${mobile(css`
    font-size: 18px;
  `)};
`;

export const Section = styled(DisplayFlex)`
  justify-content: space-between;
`;
export const SubSection = styled(DisplayFlex)`
  justify-content: right;
  align-items: center;
`;

export const Spacer = styled(Row)`
  padding: 20px 0;
`;

export const RightSide = styled(DisplayFlex)`
  cursor: pointer;
`;

export const View = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #cda257;

  ${mobile(css`
    font-size: 14px;
  `)};
`;

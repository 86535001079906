import styled, { css } from 'styled-components';
import { Col } from 'react-bootstrap';

import { mobile } from '../../Assets/layout';

export const FormContainer = styled.div`
  background: #cda257;

  ${mobile(css`
    padding-top: 2em;
    padding-bottom: 1em;
  `)};
`;

export const FormCol = styled(Col)`
  padding: 1.5em 2em;
  ${mobile(css`
    padding: 1em 2em;
  `)};
`;

export const EnquiriesTitle = styled.h1`
  font-weight: 700;
  font-size: 42.4098px;
  line-height: 49px;
  color: #ffffff;
  max-width: 500px;
`;

export const Input = styled.input`
  padding: 2vh 0;
  background-color: #cda257;
  outline: 0;
  border-width: 0 0 1px;
  border-color: ${props => (props.error ? 'red' : '#f2f2f2')};
  color: #f2f2f2;
  font-size: 17px;
  width: 100%;

  ::-webkit-input-placeholder {
    color: #f2f2f2;
  }
  :-ms-input-placeholder {
    color: #f2f2f2;
  }
`;

export const TextArea = styled.textarea`
   padding: 2vh 0;
  background-color: #cda257;
  outline: 0;
  border-width: 0 0 1px;
  border-color: ${props => (props.error ? 'red' : '#f2f2f2')};
  color: #f2f2f2;
  font-size: 17px;
  width: 100%;

  ::-webkit-input-placeholder {
    color: #f2f2f2;
  }
  :-ms-input-placeholder {
    color: #f2f2f2;
  }
`;

export const Button = styled.button`
  background: #fecf08;
  border: none;
  padding: 1vh 4vh;
  margin-top: 5vh;
  font-weight: 400;
  font-size: 13.2365px;
`;

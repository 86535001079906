import styled, { css } from 'styled-components';
import Card from 'react-bootstrap/Card';

import { mobile } from '../../Assets/layout';

export const Container = styled.div`
  border-radius: 13px 13px 0 0;
  min-width: 100%;
`;

export const SubHeaderCardTextPromo = styled.span`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 33.5775px;
  line-height: 45px;
  color: #ffffff;
  ${mobile(css`
    font-size: 18px;
    line-height: 15px;
  `)};
`;

export const FeatureButton = styled.button`
  background: #ffffff;
  border: none;
  border-radius: 30.8837px;
  color: #867a5c;
  font-weight: 700;
  font-size: 16.7887px;
  font-family: 'Playfair Display', serif;
  font-style: normal;
  padding: 2px 15px;
  width: 110.53px;
`;

export const RatingButton = styled.button`
  background: rgba(0, 0, 0, 0.15);
  border: none;
  backdrop-filter: blur(13.9906px);
  border-radius: 30.8837px;
  color: #ffffff;
  font-family: 'SF Pro', sans-serif;
  font-style: normal;
  font-weight: 510;
  font-size: 16.7887px;
  padding: 2px 15px;
`;

export const TopCardContainer = styled.div`
  display: flex;
  justify-items: center;
  justify-content: space-between;
`;

export const PromoTime = styled.div`
  background: #cda257;
  border-radius: 8px;
  text-align: center;
  font-family: 'SF Pro', sans-serif;
  font-style: italic;
  font-weight: 508;
  font-size: 16.7887px;
  padding: 10px;
  ${mobile(css`
    font-size: 12px;
    line-height: 15px;
    padding: 5px;
    margin-top: -10px;
  `)};
`;

export const CardContainer = styled(Card)`
  border-radius: 30px;
  border-width: 0;
  width: 100%;
  background-color: transparent;
`;

export const CardImg = styled(Card.Img)`
  border-radius: 8px;
`;

export const CardImgOverlay = styled(Card.ImgOverlay)`
  top: 0;
`;

export const CardImgOverlayUnset = styled(Card.ImgOverlay)`
  top: unset;
`;

export const FeatureFlex = styled.div`
  display: flex;
  justify-items: center;
`;

export const IconContainer = styled.div`
  padding-right: 4px;
  margin-top: -2px;
`;

import styled from 'styled-components';

export const AddToCartButton = styled.button`
  border: none;
  background: #cda257;
  border-radius: 4.7px;
  color: #ffffff;
  font-size: 12px;
  width: 100%;
  padding: 0.5em 1.5em;
  font-weight: 600;
`;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SlHandbag } from 'react-icons/sl';
import { BsHeart, BsPerson } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectedFilterValuePayload } from '../../Redux/Actions/selectedFilterValue.action';
import { filterActivePayload } from '../../Redux/Actions/filterActive.action';

import {
  BagBadge,
  CustomDropdownItem,
  CustomDropdownMenu,
  CustomDropdownToggle,
  FlagImage,
  Image,
  Input,
  InputContainer,
  NavbarDesktopContainer,
  NavbarLinkTitle3,
  NavbarLinkTitleDiv,
  NavbarLinkTitleDiv3,
  NavbarLinkTitleWrapper,
  RowContainer,
  RowContainer3,
  StyledButton,
  StyledButtonOutline,
  TextLabel,
  TopIcon,
  TopIconBag
} from './Navbar.styled';
import './Navbar.styled.scss';
import { DisplayFlexRight } from '../../Assets/GlobalStyled';
import Icon from '../Icon';
import { EnFlag, HkFlag, WinebuffLogo } from '../../Assets/Images';
import { ROUTE } from '../../Constants';
import filterData from './filterData.json';
import { arrangeProductFilterParams } from '../../Screens/Product/Product.container';
import { shorterUrl } from '../../Screens/Product/Components/FilterMenu';

const iconStyle = {
  color: '#CDA257',
  className: 'global-class-name',
  size: '1.5em'
};

const iconSearchStyle = {
  color: 'rgba(0, 0, 0, 0.8)',
  className: 'global-class-name',
  size: '1.5em'
};

export const selectedPopularFilter = (
  websiteListingParams,
  sortParams,
  category
) => {
  if (category.value === 'New Items' && sortParams === 'true') {
    return 'active';
  }

  return websiteListingParams === category?.value ? 'active' : '';
};

const NavbarComponent = props => {
  const pathName = location.pathname.replace('/', '');
  const [search, setSearch] = React.useState('');
  const [activeLink, setAnActiveLink] = React.useState(pathName);
  const [language, setLanguage] = React.useState(
    localStorage.getItem('language') || 'en'
  );

  const dispatch = useDispatch();
  const locationRoute = useLocation();
  const queryParams = new URLSearchParams(locationRoute.search);
  const websiteListingParams = queryParams.get('website-listing') || '';
  const sortParams = queryParams.get('sort') || false;

  const selectedFilterValue = data =>
    dispatch(selectedFilterValuePayload(data));
  const filterActive = data => dispatch(filterActivePayload(data));

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleSearch = text => {
    if (text !== '') {
      navigate(`/product?q=${text}`);
    }
  };

  const handleEnterOnSearch = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const searchText = event.target.value.trim();
      handleSearch(searchText);
    }
  };

  React.useEffect(() => {
    const timeOutId = setTimeout(() => handleSearch(search), 2500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const setActiveLink = (e, data) => {
    e.preventDefault();
    navigate(`/${data}`);
    setAnActiveLink(data);
  };

  const filterNavbarCategory = category => {
    filterActive('TOP_FILTER');
    selectedFilterValue('');
    if (category.value === 'New Items') {
      navigate(
        arrangeProductFilterParams({
          sortByLatest: true
        })
      );
    } else {
      navigate(
        arrangeProductFilterParams({
          websiteListing: category.label,
          sortByLatest: false,
          subMenu:
            category.submenu === '' ||
            category.submenu === 'null' ||
            category.submenu === null
              ? ''
              : shorterUrl(category.submenu)
        })
      );
    }
  };

  const resetFilter = () => {
    navigate(
      `/product?category=&website-listing=&country=&appellation=&region=&filter-size=&sortByLatest=false`
    );
  };

  const handleLanguageChange = lng => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
  };

  React.useEffect(() => {
    const searchQuery = new URLSearchParams(location.search).get('q') || '';
    setSearch(searchQuery);
  }, [location.search]);

  React.useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, [language, i18n]);

  const NavbarLinkTitle = ({ isActive, onClick, children, padding }) => {
    const activeClass = isActive ? 'active' : '';

    return (
      <NavbarLinkTitleWrapper
        className={activeClass}
        onClick={onClick}
        padding={padding}>
        {children}
      </NavbarLinkTitleWrapper>
    );
  };

  return (
    <NavbarDesktopContainer className="navdekstop-container desktop-dropdown-menu">
      <RowContainer>
        <Col xl={8} md={8}>
          <Row>
            <Col xl={2} md={2}>
              <Image
                onClick={() => {
                  navigate('/home');
                  setAnActiveLink('');
                }}
                image={WinebuffLogo}
              />
            </Col>
            <Col>
              <NavbarLinkTitleDiv>
                <NavbarLinkTitle
                  key={activeLink}
                  isActive={activeLink === 'about-us'}
                  onClick={e => setActiveLink(e, 'about-us')}>
                  {t('about-us')}
                </NavbarLinkTitle>
                <NavbarLinkTitle
                  isActive={activeLink === 'product'}
                  onClick={e => {
                    resetFilter();
                    setActiveLink(e, 'product');
                  }}>
                  {t('product')}
                </NavbarLinkTitle>
                <NavbarLinkTitle
                  isActive={activeLink === 'event'}
                  onClick={e => setActiveLink(e, 'event')}>
                  {t('event')}
                </NavbarLinkTitle>
                <NavbarLinkTitle
                  isActive={activeLink === 'blog'}
                  onClick={e => setActiveLink(e, 'blog')}>
                  {t('blog')}
                </NavbarLinkTitle>
                <NavbarLinkTitle
                  isActive={activeLink === 'burgundy'}
                  onClick={e => setActiveLink(e, 'burgundy')}>
                  {t('burgundy-map')}
                </NavbarLinkTitle>
                <StyledButton
                  onClick={() => {
                    navigate('/wine');
                    setAnActiveLink('');
                  }}>
                  {t('wine-list')}
                </StyledButton>
                <StyledButtonOutline
                  onClick={() => {
                    window.open('https://newsletter.winebuff.hk/', '_blank');
                  }}>
                  {t('signup-for-eNews')}
                </StyledButtonOutline>
              </NavbarLinkTitleDiv>
            </Col>
          </Row>
        </Col>
        <Col xl={4} md={4}>
          <DisplayFlexRight>
            <Dropdown>
              <CustomDropdownToggle
                variant="outline-success"
                id="lang-dropdown">
                {language === 'en' ? (
                  <FlagImage image={EnFlag} />
                ) : (
                  <FlagImage image={HkFlag} />
                )}
              </CustomDropdownToggle>
              <CustomDropdownMenu>
                <CustomDropdownItem onClick={() => handleLanguageChange('en')}>
                  <FlagImage image={EnFlag} />
                </CustomDropdownItem>
                <CustomDropdownItem onClick={() => handleLanguageChange('cn')}>
                  <FlagImage image={HkFlag} />
                </CustomDropdownItem>
              </CustomDropdownMenu>
            </Dropdown>
            <InputContainer>
              <Icon style={iconSearchStyle} IconShow={FiSearch} />
              <Input
                onKeyDown={handleEnterOnSearch}
                onChange={event => {
                  setSearch(event.target.value);
                }}
                type="text"
                placeholder={t('search')}
              />
            </InputContainer>
            <TopIcon>
              <a href={props.redirectToLogin(ROUTE.wishlist)}>
                <Icon style={iconStyle} IconShow={BsHeart} />
              </a>
            </TopIcon>
            <TopIcon href="sign-in">
              <a href={props.redirectToLogin(ROUTE.account)}>
                <Icon style={iconStyle} IconShow={BsPerson} />
              </a>
            </TopIcon>
            <TopIconBag>
              <a href={props.redirectToLogin(ROUTE.cart, false)}>
                <Icon style={iconStyle} IconShow={SlHandbag} />
              </a>
              <BagBadge>{props?.cartsTotal}</BagBadge>
            </TopIconBag>
          </DisplayFlexRight>
        </Col>
      </RowContainer>
      {location.pathname === '/product' ||
      location.pathname === '/' ||
      location.pathname === '/en' ||
      location.pathname === '/en/shop' ||
      location.pathname.includes('/search/') ||
      location.pathname.includes('/category') ? (
        <>
          <RowContainer3>
            <NavbarLinkTitleDiv3>
              <TextLabel>{t('popular')}: </TextLabel>
              {filterData.CATEGORIES?.map((category, index) => (
                <NavbarLinkTitle3
                  key={index}
                  onClick={() => {
                    filterNavbarCategory(category);
                  }}
                  className={selectedPopularFilter(
                    websiteListingParams,
                    sortParams,
                    category
                  )}>
                  {i18n.language === 'en'
                    ? category?.label
                    : category?.label_ch}
                </NavbarLinkTitle3>
              ))}
            </NavbarLinkTitleDiv3>
          </RowContainer3>
        </>
      ) : null}
    </NavbarDesktopContainer>
  );
};

export default NavbarComponent;

import React from 'react';
import { useParams } from 'react-router-dom';

import { servicesRequest } from '../../Utils';
import { NEWS_DETAIL_URL } from '../../Utils/serviceUrl.utils';
import { getContent, getTitle } from '../../Utils/general.utils';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

import {
  Container,
  Content,
  Readtime,
  Title,
  Image,
  Writer
} from './NewsDetail.styled';

const NewsDetailScreen = () => {
  const [news, setBlog] = React.useState(null);
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(NEWS_DETAIL_URL + id);

      setBlog(data);
    } catch (e) { }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  return (
    <Container>
      <Title className="mb-4">{getTitle(news?.title, news?.title_ch, i18n.language)}</Title>
      <Image src={news?.thumbnail} className="img-fluid" />
      <p className="mt-2">
        <Writer>{news?.author}</Writer>{' '}
        <Readtime>- {news?.readtime_estimation} {t('mins-read')}</Readtime>
      </p>
      <Content
        className="mt-4"
        dangerouslySetInnerHTML={{ __html: getContent(news, i18n.language) }}
      />
    </Container>
  );
};

export default NewsDetailScreen;

import {
  APPLLATION_DATA_PAYLOAD
} from '../Actions/action.types';

const appellationDataReducer = (state = [], action) => {
  switch (action.type) {
    case APPLLATION_DATA_PAYLOAD:
      return { appellationData: action.payload };
    default:
      return state
  }
};
export default appellationDataReducer;
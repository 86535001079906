import styled from 'styled-components';

export const Answer = styled.div`
  padding-left: 0px;
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: ${props => (props.type === 1 ? '500' : '500')};
  font-size: ${props => (props.type === 1 ? '16px' : '18px')};
  letter-spacing: 0.065em;
  color: #000000;
  border-bottom: ${props =>
    props.type === 1
      ? '1px solid rgba(0, 0, 0, 0.12)'
      : '1.1px solid #969696;'};
`;

export const Title = styled.h1`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 28px;
  color: #333333;
  text-align: center;
  padding-bottom: 5vh;
  border-bottom: 0.622596px solid #969696;
`;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { exportToCSV } from '../../Utils/general.utils';
import {
  ButtonSized,
  Container,
  FilterTopContainer,
  ListTableContainer,
  TopFilter
} from './Wine.styled';
import { WineList, WineListMobile } from '../../Components';
import '../../Components/ProductCardList/Product.styled.scss';
import { ListContainer } from '../Product/Product.styled';

const WineComponent = props => {
  const { t } = useTranslation();

  const {  allProducts, isLoading } = props;

  const fileName = `Wine List ${moment().format('YYYY-MM-DD')}`;
  const reformatWineList = allProducts?.map(item => ({
    col1: item?.code,
    col2: item?.aprice,
    col3: item?.bprice,
    col4: item?.vintage,
    col5: item?.name_en,
    col6:
      JSON.parse(item?.qty).totalQty > 0 ? JSON.parse(item.qty).totalQty : 0,
    col7: item?.size,
    col8: item?.country,
    col9: item?.region,
    col10: item?.appellation,
    col11: item?.rating,
    col12: item?.category_label
  }));

  const _renderWinesTable = () => {
    if (allProducts?.length === 0) {
      return <ShimmerSimpleGallery col={4} imageHeight={300} caption />;
    }
    return <WineList data={allProducts} />;
  };

  const _WineListMobile = () => {
    if (allProducts?.length === 0) {
      return <ShimmerSimpleGallery col={4} imageHeight={300} caption />;
    }

    return allProducts.map((item, index) => {
      return (
        <Col md={3} key={index}>
          <WineListMobile data={item} />
        </Col>
      );
    });
  };

  const downloadWineList = () => {
    if (!isLoading) {
      exportToCSV(reformatWineList, fileName);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <TopFilter className="mb-2 px-2">
            <FilterTopContainer>
              <ButtonSized
                isLoading={isLoading}
                onClick={() => downloadWineList()}>
                {t('download-winelist')}
              </ButtonSized>
            </FilterTopContainer>
          </TopFilter>
          <Row>
            <>
              <ListTableContainer>{_renderWinesTable()}</ListTableContainer>
              <ListContainer>{_WineListMobile()}</ListContainer>
            </>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default WineComponent;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import LanguageProvider from './LanguageProvider';
import { router } from './Routes';
import { persistor, store } from './Redux/store';
import './index.css';

import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <LanguageProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <RouterProvider router={router}>
            {/* Your app components/pages */}
          </RouterProvider>
        </React.StrictMode>
      </PersistGate>
    </Provider>
  </LanguageProvider>,
);

reportWebVitals();

import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h2`
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #101828;
`;

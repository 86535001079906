export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const DEFAULT_CARTS = 'DEFAULT_CARTS';
export const ADD_CARTS = 'ADD_TO_CARTS';
export const SUBTRACT_CARTS = 'SUBTRACT_CARTS';
export const ADD_ITEM = 'ADD_ITEM';
export const SET_ITEM = 'SET_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const ADD_CARTS_PAYLOAD = 'ADD_CARTS_PAYLOAD';
export const SUBTRACT_CARTS_PAYLOAD = 'SUBTRACT_CARTS_PAYLOAD';
export const RESET_CARTS_PAYLOAD = 'RESET_CARTS_PAYLOAD';
export const FILTER_APPELLATION_PAYLOAD = 'FILTER_APPELLATION_PAYLOAD';
export const FILTER_COUNTRY_PAYLOAD = 'FILTER_COUNTRY_PAYLOAD';
export const FILTER_CATEGORY_PAYLOAD = 'FILTER_CATEGORY_PAYLOAD';
export const FILTER_REGION_PAYLOAD = 'FILTER_REGION_PAYLOAD';
export const FILTER_NEW_DATA_PAYLOAD = 'FILTER_NEW_DATA_PAYLOAD';
export const SHOW_HIDE = 'SHOW_HIDE';
export const APPLLATION_DATA_PAYLOAD = 'APPLLATION_DATA_PAYLOAD';
export const WEBSITE_LISTING_PAYLOAD = 'WEBSITE_LISTING_PAYLOAD';
export const SELECTED_FILTER_VALUE_PAYLOAD = 'SELECTED_FILTER_VALUE_PAYLOAD';
export const FILTER_SEARCH_PAYLOAD = 'FILTER_SEARCH_PAYLOAD';
export const FILTER_ACTIVE = 'FILTER_ACTIVE';
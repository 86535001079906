import React from 'react';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import Slider from 'react-slick';

import { servicesRequest } from '../../../Utils';
import { EVENT_URL } from '../../../Utils/serviceUrl.utils';
import { EventCard } from '../../../Components';
import   './Event.component.scss';


export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};

const EventComponent = () => {
  const [events, setEvents] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  const pageSize = 6;

  const fetching = async () => {
    try {
      setLoading(true);
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(EVENT_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setEvents(contents);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  return (
    <div>
      {events?.length === 0 && loading ? (
        <ShimmerSimpleGallery row={1} col={4} imageHeight={200} caption />
      ) : (
        <Slider {...settings}>
          {events.map((item, index) => {
            return (
              <div key={index} className="px-1">
                <EventCard event={item} />
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default EventComponent;

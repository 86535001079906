import styled, { css } from 'styled-components';

import {
  Counter,
  QuantityContainer as Qty,
  QuantityNumber
} from '../../Screens/ProductDetail/ProductDetail.styled';
import { desktop, laptop, mobile, tablet } from '../../Assets/layout';
import { Row } from 'react-bootstrap';

export const Name = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #171520;

  ${mobile(css`
    font-size: 14px;
  `)};
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #626262;

  ${mobile(css`
    font-size: 14px;
    line-height: 12px;
  `)};
`;

export const Price = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  color: #171520;
  text-align: center;
  padding-top: 10px;

  ${mobile(css`
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  `)};
`;

export const PriceStrike = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  color: #171520;
  text-align: center;
  text-decoration: line-through;
  ${mobile(css`
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  `)};
`;

export const ProductCounter = styled(Counter)`
  background: #efeee8;
  border: 1px solid #d4d4d8;
  border-radius: 4.57143px;
  gap: 0.5vw;
  margin: 0;
  padding: 0.5vh 0.5vw;

  ${mobile(css`
    padding: 0.3vh 1.5vw;
  `)};
`;

export const Quantity = styled(QuantityNumber)`
  font-weight: 600;
  font-size: 15.6735px;
  color: #cda257;
`;

export const StyledInput = styled.input`
  font-weight: 600;
  font-size: 15.6735px;
  color: #cda257;
  width: 40px;
  border: none; /* remove border */
  outline: none; /* remove outline */
  box-shadow: none; /* remove box shadow */
  background-color: transparent; /* set background to transparent */
  text-align: center;
`;

export const MoveToWishlist = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #cda257;
  margin-top: 0vh;
  cursor: pointer;
  text-align: center;
  ${mobile(css`
    font-size: 14px;
    line-height: 25px;
    margin-top: 10px;
    text-align: left;
  `)};
   ${tablet(css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  `)};
`;

export const Remove = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 6px;
  text-decoration-line: underline;
  color: #b00020;
  margin-top: 0vh;
  cursor: pointer;
  text-align: center;
  ${mobile(css`
    font-size: 14px;
    line-height: 15px;
    margin-top: -10px;
    text-align: left;
  `)};
`;

export const QuantityContainer = styled(Qty)`
  width: 60%;
`;

export const ProductCartMobileContainer = styled.div`
  ${laptop(css`
    display: none;
  `)};

  ${tablet(css`
    display: none;
  `)};

  ${desktop(css`
    display: none;
  `)};

  ${mobile(css`
    display: block;
  `)};
`;

export const ProductCartDesktopContainer = styled(Row)`
  ${mobile(css`
    display: none;
  `)};
`;

export const Img = styled.img`
  border-radius: 8px;
`;

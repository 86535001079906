import { FiArrowUpRight } from 'react-icons/fi';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Description,
  Image,
  Title,
  TitleContainer,
  Writer
} from './ImageCard.styled';
import Icon from '../Icon';
import { limitOutputString, removeTags } from '../../Utils/general.utils';
import { ROUTE } from '../../Constants';

const ImageCardComponent = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Image src={data?.thumbnail} className="img-fluid" />
      <Writer>{`${data?.author} • ${moment(data?.createdAt).format(
        'MMM Do YYYY'
      )}`}</Writer>
      <TitleContainer
        onClick={() => {
          navigate(`/${ROUTE.images}/${data?.id}`);
        }}>
        <Title>{data?.title}</Title>
        <Icon IconShow={FiArrowUpRight} style={{ size: '24px' }} />
      </TitleContainer>
      <Description>
        {limitOutputString(removeTags(data?.content), 90, true)}
      </Description>
    </Container>
  );
};

export default ImageCardComponent;

import propTypes from 'prop-types';

import { ButtonContainer, ButtonStyled } from './Button.styled';

const ButtonComponent = ({ label, onClick }) => {
  return (
    <ButtonContainer>
      <ButtonStyled onClick={onClick} variant="danger">
        {label}
      </ButtonStyled>
    </ButtonContainer>
  );
};

ButtonComponent.propTypes = {
  label: propTypes.string,
  onClick: propTypes.func
};

ButtonComponent.defaultProps = {
  label: 'Button',
  onClick: () => {}
};

export default ButtonComponent;

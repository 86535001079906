import styled, { css } from 'styled-components';

import { DisplayFlex } from '../../Assets/GlobalStyled';
import { mobile } from '../../Assets/layout';

export const Container = styled.div`
  padding: 1vw;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 15em;
  border-radius: 8px;
`;

export const TitleContainer = styled(DisplayFlex)`
  justify-content: space-between;
  cursor: pointer;

  ${mobile(css`
    margin-bottom: 1vh;
  `)};
`;

export const Title = styled.h4`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;

  ${mobile(css`
    font-size: 20px;
  `)};
`;

export const Description = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  flex: none;
  order: 2;
  align-self: stretch;
  margin-top: 0.8em;
`;

export const Writer = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6941c6;
  margin-top: 2vh;
`;

export const TagContainer = styled(DisplayFlex)`
  margin-top: 4vh;
  gap: 0.5vw;

  ${mobile(css`
    margin-top: 2vh;
  `)};
`;

export const Tag = styled.div`
  background: #f9f5ff;
  border-radius: 16px;
  padding: 0.2vh 2vw;
`;

export const TagLabel = styled.span`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #6941c6;
`;

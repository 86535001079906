import { Col, Row } from 'react-bootstrap';

import { BlogCard } from '../BlogCard';
import { Container, Title } from './BlogList.styled';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const BlogListComponent = ({ data }) => {
  const { t, i18n } = useTranslation();
  const _renderBlog = data =>
    data.map((item, index) => (
      <Col key={index} xl={4} md={4} sm={4}>
        <BlogCard data={item} />
      </Col>
    ));

  return (
    <Container>
      <Title>{t('all-blog-posts')}</Title>
      <Row>{_renderBlog(data)}</Row>
    </Container>
  );
};

export default BlogListComponent;

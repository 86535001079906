import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import { ImageCard } from '../ImageCard';
import { Container, Title } from './ImageList.styled';

const ImageListComponent = ({ data }) => {
  const { t, i18n } = useTranslation();

  const _renderImage = data =>
    data.map((item, index) => (
      <Col key={index} xl={4} md={4} sm={4}>
        <ImageCard data={item} />
      </Col>
    ));

  return (
    <Container>
      <Title>{t('all-photo-gallery')}</Title>
      <Row>{_renderImage(data)}</Row>
    </Container>
  );
};

export default ImageListComponent;

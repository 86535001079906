import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Img,
  MoveToWishlist,
  Name,
  Price,
  PriceStrike,
  ProductCartDesktopContainer,
  ProductCounter,
  QuantityContainer,
  Remove,
  StyledInput
} from './ProductCart.styled';
import { PlusMinus } from '../../Screens/ProductDetail/ProductDetail.styled';
import { COUNTER_TYPE } from '../../Constants';
import { checkJWT } from '../../Utils/general.utils';

const ProductCartDesktopComponent = props => {
  const {
    quantity,
    counterQuantity,
    setCounterQuantity,
    handleDelete,
    handleMoveToWishlist,
    productName,
    productPrice,
    memberPrice,
    productImage,
    subTotalAfterDiscount
  } = props;

  const { t } = useTranslation();

  return (
    <ProductCartDesktopContainer className="mt-1">
      <Col md={5} xs={5}>
        <Row>
          <Col md={4} xs={5}>
            <Img src={productImage} className="img-fluid" alt={productName} />
          </Col>
          <Col md={8} xs={7}>
            <Name>{productName}</Name>
          </Col>
        </Row>
      </Col>
      <Col md={7} xs={7}>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={3} className="">
                <Price>${productPrice}</Price>
                <PriceStrike>${memberPrice}</PriceStrike>
              </Col>
              <Col md={4} className="d-flex justify-content-center">
                <QuantityContainer>
                  <ProductCounter>
                    <PlusMinus
                      onClick={() => counterQuantity(COUNTER_TYPE.SUBTRACT)}>
                      -
                    </PlusMinus>
                    <StyledInput
                      value={quantity}
                      onChange={e =>
                        setCounterQuantity(COUNTER_TYPE.SET, e.target.value)
                      }
                    />
                    <PlusMinus
                      onClick={() => counterQuantity(COUNTER_TYPE.ADD)}>
                      +
                    </PlusMinus>
                  </ProductCounter>
                </QuantityContainer>
              </Col>
              <Col md={2}>
                <Price>${subTotalAfterDiscount}</Price>
              </Col>
              <Col md={3}>
                {!checkJWT() && (
                  <Remove onClick={handleDelete}>{t('remove')}</Remove>
                )}
                {!checkJWT() && (
                  <MoveToWishlist onClick={handleMoveToWishlist}>
                    {t('move-to-wishlist')}
                  </MoveToWishlist>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </ProductCartDesktopContainer>
  );
};

export default ProductCartDesktopComponent;

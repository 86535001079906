import {
  FILTER_CATEGORY_PAYLOAD
} from '../Actions/action.types';

const filterCategoryReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_CATEGORY_PAYLOAD:
      return { filterCategory: action.payload };
    default:
      return state
  }
};
export default filterCategoryReducer;

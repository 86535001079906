import React from 'react';

import { servicesRequest } from '../../Utils';
import { PRODUCT_URL } from '../../Utils/serviceUrl.utils';
import WineComponent from './Wine.component';

const WineContainer = () => {
  const [allProducts, setAllProducts] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState({
    page: '1',
    total_page: '1',
    total_item: '1',
    itemperpage: '10000'
  });
  const [isLoading, setIsLoading] = React.useState(true);

  const fetchingAllList = async () => {
    try {
      setIsLoading(true);
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_URL, {
        itemCode: '',
        itemName: '',
        region: '',
        appellation: '',
        country: '',
        itemPerPage: 10000,
        pageCount: 1,
        onlineStore: '1',
        sortByCountry: true
      });
      setAllProducts(data.products);
      setPagination(data.pagination);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const props = {
    allProducts,
    page,
    setPage,
    pagination,
    setPagination,
    isLoading
  };

  React.useEffect(() => {
    fetchingAllList().catch(console.error);
  }, [page]);

  return <WineComponent {...props} />;
};

export default WineContainer;

import styled, { css } from 'styled-components';
import { DisplayFlex } from '../../Assets/GlobalStyled';
import { Row } from 'react-bootstrap';

import { desktop, laptop, mobile, tablet } from '../../Assets/layout';

export const Container = styled.div`
  padding: 2em;
`;

export const TopFilter = styled(DisplayFlex)`
  justify-content: space-between;
`;

export const ListTableContainer = styled.div`
  ${desktop(css`
    display: block;
  `)};

  ${laptop(css`
    display: block;
  `)};

  ${tablet(css`
    display: block;
  `)};

  ${mobile(css`
    display: none;
  `)};
`;

export const FilterTopContainer = styled(Row)`
  width: 100vw;
`;

export const ButtonSized = styled.button`
  background: #4d1111;
  border: 1.5px solid #4d1111;
  border-radius: 5px;
  color: #fff;
  padding: 0.3em 0.5em;
  width: 170px;
  opacity: ${props => (props.isLoading ? 0.5 : 1)};

  ${mobile(css`
    padding: 0.3em 0.5em;
    width: 50%;
    font-size: 15px;
  `)};
`;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ImFacebook, ImWhatsapp } from 'react-icons/im';
import { AiFillInstagram } from 'react-icons/ai';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';

import { ROUTE } from '../../Constants';
import {
  MobileContainer,
  FooterContainer,
  FooterLinkMobile,
  FooterLinkColMobile,
  FooterParagraphMobile,
  SocialMedia,
  StyledHrefWa,
  FooterLabelContainer,
  FooterMainLabel,
  FooterSubLabel,
  TopMobileFooterContainer,
  TopFooterTitle,
  TopFooterInputContainer,
  Input,
  Button,
  LangContainer,
  ImageDiv,
  HkdDiv,
  EngDiv,
  LangDiv,
  FlagImage,
  FooterBottomContainer,
  Logo,
  AlignRight,
  CardsImage
} from './Footer.styled';
import { DisplayFlexLeft, StyledHref } from '../../Assets/GlobalStyled';
import Icon from '../Icon';
import { servicesRequest } from '../../Utils';
import { CONTENT_URL } from '../../Utils/serviceUrl.utils';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { WinebuffLogo, Cards, HkFlag, EnFlag } from '../../Assets/Images';

const iconWaStyle = {
  size: '1.8em'
};
const iconFooterStyle = {
  size: '28px'
};
const iconIgStyle = {
  size: '30px'
};

const FooterMobile = () => {
  const [links, setLinks] = React.useState({});
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState(localStorage.getItem('language') || 'en');;

  const formSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required')
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema)
  });

  const onSubmit = async data => {
    try {
      const data = data;
    } catch (error) {
      console.error(error);
    }
  };

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(CONTENT_URL + '/detail', {
        params: {
          type: 'SOCIAL_MEDIA'
        }
      });

      setLinks(JSON.parse(data?.content));
    } catch (e) {}
  };

  const handleLanguageChange = (lng) => {
    setLanguage(lng)
    i18n.changeLanguage(lng);
  };

  React.useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, [language, i18n]);

  return (
    <div className="footer-mobile">
      <MobileContainer>
        <Row>
          <FooterLinkColMobile>
            <FooterLinkMobile href={`/${ROUTE.faq}`}>
              {t('faq')}
            </FooterLinkMobile>
          </FooterLinkColMobile>
          <FooterLinkColMobile>
            <FooterLinkMobile href={`/${ROUTE.news}`}>
              {t('news')}
            </FooterLinkMobile>
          </FooterLinkColMobile>
          <FooterLinkColMobile>
            <FooterLinkMobile href={`/${ROUTE.images}`}>
              {t('gallery')}
            </FooterLinkMobile>
          </FooterLinkColMobile>
          <FooterLinkColMobile>
            <FooterLinkMobile href={`/${ROUTE.contactUs}`}>
              {t('contact')}
            </FooterLinkMobile>
          </FooterLinkColMobile>
          <FooterLinkColMobile>
            <FooterLinkMobile href={`/${ROUTE.termOfUse}`}>
              {t('terms-condition')}
            </FooterLinkMobile>
          </FooterLinkColMobile>
          <FooterLinkColMobile>
            <FooterLinkMobile href={`/${ROUTE.privacyPolicy}`}>
              {t('privacy')}
            </FooterLinkMobile>
          </FooterLinkColMobile>
          <FooterLinkColMobile>
            <FooterLinkMobile href={`/${ROUTE.wineCellar}`}>
              {t('wine-cellar')}
            </FooterLinkMobile>
          </FooterLinkColMobile>
        </Row>
        <Row>
          <DisplayFlexLeft className="my-3">
            <SocialMedia>
              <StyledHref href={links?.facebook} target="blank">
                <Icon style={iconFooterStyle} IconShow={ImFacebook} />
              </StyledHref>
            </SocialMedia>
            <SocialMedia>
              <StyledHref href={links?.instagram} target="blank">
                <Icon style={iconIgStyle} IconShow={AiFillInstagram} />
              </StyledHref>
            </SocialMedia>
          </DisplayFlexLeft>
        </Row>
        <Row>
          <FooterParagraphMobile>
            <span
              dangerouslySetInnerHTML={{
                __html: t('delivery-description').replace(
                  'over HKD 1,500 or 6 standard bottles',
                  '<u><strong>over HKD 1,500 or 6 standard bottles</strong></u>'
                )
              }}></span>
          </FooterParagraphMobile>
        </Row>
        <Row>
          <LangContainer>
            <ImageDiv>
              <FlagImage
                src={language === 'en' ? EnFlag : HkFlag}
              />
            </ImageDiv>
            <LangDiv>
              <HkdDiv onClick={() => handleLanguageChange('cn')}>HK</HkdDiv>
              <EngDiv onClick={() => handleLanguageChange('en')}>| Eng</EngDiv>
            </LangDiv>
          </LangContainer>
        </Row>
        <FooterBottomContainer >
          <Col>
            <HkdDiv>{t('we-accept')}</HkdDiv>
            <CardsImage
              src={Cards}
            />
          </Col>
          <AlignRight>
            <Logo
              src={WinebuffLogo}
            />
          </AlignRight>
        </FooterBottomContainer>
      </MobileContainer>
      <StyledHrefWa href="https://wa.link/1lxl2c" target="_blank">
        <Icon style={iconWaStyle} IconShow={ImWhatsapp} />
      </StyledHrefWa>
      {/* <FooterContainer className="py-2">
        <FooterParagraph className="text-center">
          <span>
            © {new Date().getFullYear()} {t('footer-copyright')}.
          </span>
        </FooterParagraph>
      </FooterContainer> */}
    </div>
  );
};

export { FooterMobile };

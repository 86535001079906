import styled, { css } from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { mobile } from '../../Assets/layout';

export const Container = styled(Row)`
  background: #f9f9f9;
  padding: 30px;
`;

export const MobileContainer = styled(Row)`
  background: #efeee8;
`;

export const FooterLinkTitle = styled.h6`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  font-family: 'Inter', sans-serif;
`;

export const FooterLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #b6b6b6;
  opacity: 0.8;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  &:hover {
    color: #b6b6b6;
    opacity: 1;
  }
`;

export const FooterLinkMobile = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 49px;
  color: #000000;
  opacity: 0.8;
  text-decoration: none;
  font-family: 'Playfair Display';
  cursor: pointer;
  padding: 20px;
  &:hover {
    color: #b6b6b6;
    opacity: 1;
  }
`;

export const FooterLinkCol = styled(Col)`
  padding-bottom: 0.5vh;
`;

export const FooterLinkColMobile = styled.div`
  border-bottom: 0.5px solid #969696;
  font-family: 'Playfair Display' serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 136.8%;
  color: #000000;
`;

export const SocialMedia = styled.div`
  background: #639599;
  border-radius: 50%;
  color: white;
  padding: 1vh 1.2vh;
  margin-left: 2vh;
  font-size: 14px;
  cursor: pointer;
`;

export const LocationLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #020202;
  margin-left: 0.5vw;
  font-family: 'Inter', sans-serif;
`;

export const FooterParagraph = styled.span`
  font-size: 14px;
  color: #020202;
`;

export const FooterParagraphMobile = styled.span`
  font-size: 14px;
  color: #020202;
  padding: 10px 20px;
  margin: 10px 10px;
`;

export const FooterContainer = styled(Row)`
  padding: 10px;
  background: #e0e0e0;
`;

export const StyledHrefWa = styled.a`
  text-decoration: none;
  color: white;
  background: #25d366;
  position: fixed;
  bottom: 13%;
  right: 70px;
  z-index: 999;
  width: 50px;
  height: 50px;
  padding: 11px;
  border-radius: 50%;

  &:hover {
    color: white;
    opacity: 1;
  }
  ${mobile(css`
    right: 30px;
    bottom: 10%;
  `)};
`;

export const FooterLabelContainer = styled.div`
  text-align: center;
  padding: 10px;
`;

export const FooterMainLabel = styled.div`
  font-family: 'Playfair Display SC';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  /* identical to box height, or 18px */

  text-align: center;
  letter-spacing: 0.205em;

  color: #000000;
`;

export const FooterSubLabel = styled.div`
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 30px;
  /* or 12px */

  text-align: center;
  letter-spacing: 0.065em;

  color: #969696;
`;

export const TopMobileFooterContainer = styled(Row)`
  background: #e6dbd3;
  justify-content: center;
  padding: 40px 0;
`;

export const TopFooterTitle = styled.div`
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  padding-bottom: 20px;

  color: #000000;
`;

export const TopFooterInputContainer = styled.div`
  display: flex;
  width: 80vw;
  justify-content: center;
`;

export const Input = styled.input`
  background: #ffffff;
  opacity: 0.8;
  border: ${props =>
    props.error ? '1px solid #DA2F24' : '1px solid #3e2f24;'};
  border-radius: 0px;
  width: 100%;
  padding: 0.3em 0.5em;
  display: block;

  ::placeholder,
  ::-webkit-input-placeholder {
    font-weight: 500;
    color: #3e2f24;
  }

  :-ms-input-placeholder {
    font-weight: 500;
    color: #3e2f24;
  }
`;

export const Button = styled.button`
  background: #4d1111;
  border: 1.5px solid #4d1111;
  border-radius: 0px;
  color: #fff;
  padding: 0.3em 0.5em;
  width: 40%;

  ${mobile(css`
    padding: 0.3em 0.5em;
    width: 50%;
  `)};
`;

export const LangContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 20px 30px;
`;

export const ImageDiv = styled.div``;

export const HkdDiv = styled.div`
font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #000000;`;

export const EngDiv = styled.div`padding-left: 5px;`;

export const LangDiv = styled.div`
  display: flex;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #000000;
  padding-left: 10px;
`;

export const FlagImage = styled.img`
  width: 25px;
`;

export const FooterBottomContainer = styled(Row)`
  padding: 0 30px 30px;
`;

export const Logo = styled.img`
  width: 100px;
`;

export const CardsImage = styled.img`
  padding-top: 10px;
  width: 150px;
`;

export const AlignRight = styled(Col)`
  text-align: right;
`;

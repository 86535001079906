import axios from 'axios';

const requestHandler = request => {
  const token = localStorage.getItem('token');
  request.headers.Authorization = `Bearer ${token}`;

  return request;
};

const responseHandler = response => {
  if (response.status === 401) {
    localStorage.removeItem('token');
    window.location = '/sign-in';
  }

  return response;
};

const errorHandler = error => {
  if (error.response.status === 401) {
    localStorage.removeItem('token');
    window.location = '/sign-in';
  } else {
    return Promise.reject(error);
  }
};

const servicesRequest = async () => {
  const customAxios = axios.create({
    headers: {
      'Content-Type': 'application/json'
    }
  });

  customAxios.interceptors.request.use(
    request => requestHandler(request),
    error => errorHandler(error)
  );

  customAxios.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error)
  );

  return customAxios;
};

export default servicesRequest;

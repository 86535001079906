import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import { NewsCard } from '../NewsCard';
import { Container, Title } from './NewsList.styled';

const NewsListComponent = ({ data }) => {
  const { t, i18n } = useTranslation();

  const _renderNews = data =>
    data.map((item, index) => (
      <Col key={index} xl={4} md={4} sm={4}>
        <NewsCard data={item} />
      </Col>
    ));

  return (
    <Container>
      <Title>{t('all-news-posts')}</Title>
      <Row>{_renderNews(data)}</Row>
    </Container>
  );
};

export default NewsListComponent;

import styled, { css } from 'styled-components';

import { mobile } from '../../Assets/layout';

export const Container = styled.div`
  padding: 5em 10em;

  ${mobile(css`
    padding: 1em 2em;
  `)};
`;

export const Title = styled.h4`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: #121212;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 20em;
  border-radius: 8px;
`;

export const Content = styled.div``;

export const Writer = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.489796px;
  color: #f5556e;
`;

export const Readtime = styled.span`
  font-family: 'Poppins', sans-serif;
  line-height: 12px;
  letter-spacing: 0.489796px;
  color: #121212;
  mix-blend-mode: normal;
  opacity: 0.3;
`;

export const GaleryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    grid-template-rows: repeat(4, 2fr);
    grid-gap: 15px;
`;

export const GaleryItem = styled.div`
    
`;
import {
  SELECTED_FILTER_VALUE_PAYLOAD
} from './action.types';

export const selectedFilterValuePayload = payload => dispatch => {
  dispatch({
    type: SELECTED_FILTER_VALUE_PAYLOAD,
    payload: payload
  });
};


import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import i18n from '../../../i18n';
import {
  Button,
  ButtonSmall,
  CancelButtonSmall,
  TextArea
} from '../../SignIn/SignIn.styled';
import { servicesRequest } from '../../../Utils';
import {
  ADDRESS_URL,
  DEFAULT_ADDRESS_URL
} from '../../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import { DisplayFlex } from '../../../Assets/GlobalStyled';
import { DISTRICT } from '../../../Constants';

const defaultDistrict = DISTRICT[0].value;

const AddAddressComponent = props => {
  const [isEdit, setIsEdit] = React.useState(false);
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      addressLine: '',
      district: defaultDistrict
    }
  });

  React.useEffect(() => {
    if (props?.addressToEdit !== null) {
      setValue('addressLine', props?.addressToEdit?.addressLine);
      setValue('district', props?.addressToEdit?.district);
      setIsEdit(true);
    }
  }, [props?.addressToEdit]);

  const dispatch = useDispatch();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const onAdd = async data => {
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.post(ADDRESS_URL, data);

      await props.fetchAddress();
      reset();
      showNotification('Address added');
    } catch (error) {
      showNotification('Sorry, we unable to add Address. Please try again');
    } finally {
      props.setModal(false);
      setIsEdit(false);
    }
  };

  const onUpdate = async data => {
    try {
      const payload = {
        ...data,
        id: props?.addressToEdit?.id
      };

      const authorizedRequest = await servicesRequest();
      await authorizedRequest.patch(ADDRESS_URL, payload);

      await props.fetchAddress();
      showNotification('Address updated');
    } catch (error) {
      showNotification('Sorry, we unable to update Address. Please try again');
    } finally {
      props.setModal(false);
      setIsEdit(false);
    }
  };

  const onSubmit = async data => {
    if (isEdit) {
      await onUpdate(data);
    } else {
      await onAdd(data);
    }
  };

  const onSetAsDefaultAddress = async () => {
    try {
      const payload = {
        id: props?.addressToEdit?.id
      };

      const authorizedRequest = await servicesRequest();
      await authorizedRequest.patch(DEFAULT_ADDRESS_URL, payload);

      await props.fetchAddress();
      showNotification('Address updated');
    } catch (error) {
      showNotification('Sorry, we unable to update Address. Please try again');
    } finally {
      props.setModal(false);
      setIsEdit(false);
    }
  };

  const handleDelete = async () => {
    try {
      const id = props?.addressToEdit?.id;
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.delete(ADDRESS_URL + `/${id}`);
      await props.fetchAddress();
      showNotification('Address deleted');
    } catch (error) {
      showNotification('Sorry, we unable to delete Address. Please try again');
    } finally {
      props.setModal(false);
      setIsEdit(false);
    }
  };

  return (
    <div className="gy-2 p-2">
      <Controller
        name="addressLine"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <TextArea
            value={value}
            className="mb-1"
            error={errors?.addressLine}
            type="text-area"
            placeholder={t('address')}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name="district"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            style={{ width: '100%' }}
            onChange={onChange}
            options={DISTRICT.map(option => ({
              ...option,
              label: i18n.language === 'en' ? option.label : option.label_ch
            }))}
          />
        )}
      />
      <DisplayFlex className="mt-2 justify-content-between">
        <ButtonSmall onClick={handleSubmit(onSubmit)}>{t('save')}</ButtonSmall>
        {isEdit && (
          <>
            <CancelButtonSmall onClick={handleDelete}>
              {t('delete')}
            </CancelButtonSmall>
            <Button onClick={onSetAsDefaultAddress}>
              {t('set-as-default-address')}
            </Button>
          </>
        )}
      </DisplayFlex>
    </div>
  );
};

export default AddAddressComponent;

import {
  FILTER_ACTIVE
} from './action.types';

export const filterActivePayload = payload => dispatch => {
  dispatch({
    type: FILTER_ACTIVE,
    payload: payload
  });
};


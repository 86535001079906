import {
  FILTER_REGION_PAYLOAD
} from '../Actions/action.types';

const filterRegionReducer = (state = '', action) => {
  switch (action.type) {
    case FILTER_REGION_PAYLOAD:
      return { filterRegion: action.payload };
    default:
      return state
  }
};
export default filterRegionReducer;

import styled from 'styled-components';

import { DisplayFlex } from '../../Assets/GlobalStyled';

export const Container = styled.div`
  border-radius: 13px 13px 0 0;
  min-width: 100%;
  min-width: 300px;
`;

export const Image = styled.div`
  border-radius: 13.6272px 13.6272px 0 0;
  width: 100%;
  height: 20vh;
  object-fit: cover;
  background-size: cover;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 18.1696px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.11em;
  padding: 1vh 3vw 0 3vw;
  color: #000000;
  min-height: 5vh;
`;

export const Description = styled.p`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13.6272px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #000000;
  padding: 0.5vh 3vw;
`;

export const Button = styled(DisplayFlex)`
  background: #867a5c;
  border-radius: 0 0 13.6272px 13.6272px;
  padding: 1vh 0.7vh;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
`;

export const JoinLabel = styled.span`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
`;

export const TitleContainer = styled.div`
  height: 65px;
  padding-top: 10px;
`;

export const DescriptionContainer = styled.div`
  height: 65px;
`;

import {
  WEBSITE_LISTING_PAYLOAD
} from '../Actions/action.types';

const filterWebsiteListingReducer = (state = '', action) => {
  switch (action.type) {
    case WEBSITE_LISTING_PAYLOAD:
      return { filterWebsiteListing: action.payload };
    default:
      return state
  }
};
export default filterWebsiteListingReducer;

import styled, { css } from 'styled-components';
import Card from 'react-bootstrap/Card';

import { DisplayFlex } from '../../Assets/GlobalStyled';
import { mobile } from '../../Assets/layout';
import { EventBanerImageExample } from '../../Assets/Images';
import Image from 'react-bootstrap/Image';
import { Row } from 'react-bootstrap';

export const Container = styled.div``;

export const SectionTitle = styled.h1`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  color: #13101e;

  ${mobile(css`
    font-size: 18px;
  `)};
`;

export const BannerCard = styled(Card)`
  border-radius: 0;
  border-width: 0;
  width: 100%;
`;

export const BannerImg = styled(Card.Img)`
  border-radius: 0;
`;

export const BannerOverlay = styled(Card.ImgOverlay)`
  top: unset;
  text-align: center;
`;

export const Section = styled.div`
  // justify-content: space-between;
`;

export const RightSide = styled(DisplayFlex)`
  cursor: pointer;
`;

export const View = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #cda257;

  ${mobile(css`
    font-size: 14px;
  `)};
`;

export const ImageStyled = styled(Image)`
  width: 100%;
`;
export const ImageBanner = styled.div`
  background-image: url(${EventBanerImageExample});
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;

  margin-bottom: 5vh;
  ${mobile(css`
    height: 14vh;
  `)};
`;

export const CardTitleStyled = styled(Card.Title)`
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 33.6774px;
  line-height: 45px;
  text-align: center;

  color: #ffffff;
`;

export const StyledFilterButton = styled.button`
  background: white;
  border-radius: 40.6px;
  padding: 3px 10px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  color: #666462;
  border: none;
  &.active {
    background: #cda257;
    color: white;
  }
`;

export const FilterButtonContainer = styled.div`
  display: flex;
  padding: 20px;
`;

export const MembarCardContainer = styled.div`
  padding: 40px;
`;

export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  justify-items: center;
  height: 100%;
  justify-content: center;
  padding: 0 70px;
  ${mobile(css`
    padding: 20px 0px;
  `)};
`;

export const SubTitleContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20.5882px;
  color: #000000;
  padding-top: 10px;
`;

export const TitleContent = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 31.9576px;
  color: #000000;
`;

export const MemberLineContainer = styled(Row)`
  padding-top: 70px;
`;
export const MemberLineContainer1 = styled(Row)`
  padding-top: 70px;
`;
export const MemberLineContainer2 = styled(Row)`
  padding-top: 70px;
  ${mobile(css`
    flex-direction: column-reverse;
  `)};
`;
export const MemberLineContainer3 = styled(Row)`
  padding-top: 70px;
`;
export const MemberLineContainer4 = styled(Row)`
  padding-top: 70px;
  ${mobile(css`
    flex-direction: column-reverse;
  `)};
`;

export const MemberLineBottomContainer = styled(Row)`
  padding-top: 70px;
  padding-bottom: 20%;
`;

export const MemberLineGaleryContainer = styled(Row)`
  padding-top: 70px;
  padding-bottom: 20px;
`;

export const StrongLabel = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 43.5012px;
  color: #000000;
  ${mobile(css`
    font-size: 30px;
  `)};
`;

export const AvatarPhotoContainer = styled.div`
  width: 160px;
  position: absolute;
  top: 90px;
  margin: auto;
  left: 43%;
  right: 50%;
  ${mobile(css`
    width: 60px;
    top: 20px;
  `)};
`;

export const AftarFrameContainer = styled.div`
  position: relative;
`;

export const TextInfoContainer = styled.div`
  position: absolute;
  top: 300px;
  left: 24%;
  ${mobile(css`
    top: 90px;
    left: 16%;
  `)};
`;

export const TitleInfo = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 42.2014px;
  text-align: center;
  line-height: 50px;
  color: #525252;
  ${mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)};
`;

export const SubTitleInfo = styled.div`
  padding-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 29.1511px;
  line-height: 47px;
  color: #525252;
  text-align: center;
  ${mobile(css`
    font-size: 12px;
    line-height: 17px;
  `)};
`;

export const GlasesContainer = styled.div`
  position: absolute;
  bottom: -230px;
  right: -140px;
  width: 35%;
  z-index: -10;
`;

export const LinePointContainer = styled.div`
  width: 50%;
`;

export const LineDotContainer = styled(Row)`
  padding-top: 20px;
  text-align: center;
  justify-content: center;
`;

export const SideVertical = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 49.3372px;
  color: #000000;
  transform: rotate(-90deg);
  margin-right: -270px;
  padding-bottom: 70px;
  ${mobile(css`
    display: none;
  `)};
`;

export const LabelUnderImage = styled.div`
  padding-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 29.6023px;
  text-align: center;
  color: #000000;
  ${mobile(css`
    font-size: 18px;
  `)};
`;

export const MainTitle = styled.div`
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 37.4922px;
  line-height: 54px;
  text-align: center;
  color: #cda257;
`;

export const SubMainTitle = styled.div`
font-family: 'Playfair Display';
font-style: normal;
font-weight: 600;
font-size: 18.7461px;
line-height: 26px;
text-align: center;
color: #000000;
padding-bottom 40px;
`;

export const SectionLineContainer = styled.div`
  justify-content: center;
  display: flex;
  padding-bottom: 30px;
`;

export const SectionLine = styled.div`
  border-bottom: 3px solid black;
  width: 50%;
`;

export const SloganText = styled.div`
  font-family: 'New York';
  font-style: normal;
  font-weight: 674;
  font-size: 44.9906px;
  text-align: center;
  color: #000000;
`;

export const SubSloganText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 22.4953px;
  text-align: center;
  color: #000000;
`;

export const JointButton = styled.button`
  background: #cda257;
  border-radius: 33.7429px;
  border: none;
  padding: 2px 20px;
  color: white;
  font-size: 24.3699px;
`;

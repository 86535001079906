import { Col, Row } from 'react-bootstrap';

import { Container } from '../BlogList/BlogList.styled';
import { EventCard } from '../EventCard';

const EventListComponent = ({ data }) => {
  const _renderEvent = data =>
    data.map((item, index) => (
      <Col key={index} xl={3} md={6} sm={6}>
        <EventCard event={item} />
      </Col>
    ));

  return (
    <Container>
      <Row className="g-3">{_renderEvent(data)}</Row>
    </Container>
  );
};

export default EventListComponent;

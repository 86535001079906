import React from 'react';
import moment from 'moment';

import { numberWithCommas, reformatString } from '../../../Utils/general.utils';
import { StyledTable } from '../../SignIn/SignIn.styled';
import { TableButton } from '../Account.styled';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

const OrderTableComponent = props => {
  const { data, setShowOrderDetails } = props;
  const { t, i18n } = useTranslation();


  const mapping = () => {
    return data.map(item => {
      return {
        ...item,
        key: item.id
      };
    });
  };

  const handleShow = data => {
    setShowOrderDetails(data);
  };

  const columns = [
    {
      title: `${t('code-number')}`,
      dataIndex: 'id',
      key: 'id',
      width: '20%'
    },
    {
      title: `${t('order-date')}`,
      align: 'center',
      render: (_, record) => (
        <>{moment(record?.createdAt).format('YYYY-MM-DD')}</>
      )
    },
    {
      title: `${t('qty')}`,
      align: 'right',
      render: (_, record) => <>{numberWithCommas(record?.totalQuantity)}</>
    },
    {
      title: `${t('total')}`,
      align: 'right',
      render: (_, record) => <>HKD {numberWithCommas(record?.totalFinal)}</>
    },
    {
      title: `${t('payment-methods')}`,
      align: 'center',
      render: (_, record) => (
        <>{reformatString(record?.payments?.paymentMethod)}</>
      )
    },
    {
      title: `${t('payment-status')}`,
      align: 'center',
      render: (_, record) => (
        <>{reformatString(record?.payments?.paymentStatus) || 'Pending'}</>
      )
    },
    {
      title: `${t('delivery-fee')}`,
      align: 'center',
      render: (_, record) => <>HKD {numberWithCommas(record?.deliveryFee)}</>
    },
    // {
    //   title: 'Delivery Status',
    //   align: 'center',
    //   render: (_, record) => (
    //     <>{reformatString(record?.address?.deliveryStatus) || 'Pending'}</>
    //   )
    // },
    {
      title: '',
      align: 'center',
      width: '20%',
      render: (_, record) => (
        <TableButton
          onClick={() => {
            handleShow(record);
          }}>
          {t('view-details')}
        </TableButton>
      )
    }
  ];

  return (
    <React.Fragment>
      <StyledTable columns={columns} dataSource={mapping()} size="small" />
    </React.Fragment>
  );
};

export default OrderTableComponent;

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  PaymentItem,
  PaymentItemDescription,
  PaymentItemTitle,
  RoundedButton
} from '../Cart.styled';

const DELIVERY_METHOD = [
  {
    label: 'Local Delivery',
    description: 'Send to your places with local shipment',
    value: 'Local Delivery',
    type: 'FEE'
  },
  // {
  //   label: 'Pick up from Central shop',
  //   description: 'Directly pickup your order',
  //   value: 'Pick up from Central shop',
  //   type: 'NO_FEE'
  // },
  {
    label: 'Pick up from North Point shop',
    description: 'Directly pickup your order',
    value: 'Pick up from North Point shop',
    type: 'NO_FEE'
  }
];

const DeliveryComponent = props => {
  const [type, setType] = React.useState('');

  return (
    <React.Fragment>
      <Row className="mt-4 g-1 mb-3">
        {DELIVERY_METHOD.map((item, index) => (
          <Col
            key={index}
            xs={6}
            sm={6}
            md={4}
            className="align-items-center justify-content-center">
            <PaymentItem type={type === item}>
              <RoundedButton
                type={type === item}
                className="float-end"
                onClick={() => {
                  setType(item);
                  props.setSelectedShipment(item);
                }}
              />
              <div className="mt-4">
                <PaymentItemTitle className="fw-bolder">
                  {item.label}
                </PaymentItemTitle>
                <PaymentItemDescription className="p-0">
                  {item.label}
                </PaymentItemDescription>
              </div>
            </PaymentItem>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default DeliveryComponent;

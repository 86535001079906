import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button, Input } from '../../SignIn/SignIn.styled';
import { servicesRequest } from '../../../Utils';
import { UPDATE_PASSWORD_URL } from '../../../Utils/serviceUrl.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

const UpdatePasswordComponent = ({ onClose }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();


  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: ''
    }
  });

  React.useEffect(() => {
    reset();
  }, []);

  const onSubmit = async data => {
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.post(UPDATE_PASSWORD_URL, data);
      showNotification(`${t('password-updated')}`);
    } catch (error) {
      showNotification(
        `${t('failed-to-update-password')}`
      );
    } finally {
      onClose();
      reset();
    }
  };

  return (
    <div className="mt-4">
      <Controller
        name="oldPassword"
        control={control}
        rules={{
          required: true
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            className="mb-1"
            error={errors?.oldPassword}
            type="password"
            placeholder={t('type-your-old-password')}
          />
        )}
      />
      <Controller
        name="newPassword"
        control={control}
        rules={{
          required: true
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            onChange={onChange}
            className="mb-1"
            error={errors?.newPassword}
            type="password"
            placeholder={t('type-your-new-password')}
          />
        )}
      />

      <Button className="mt-2" onClick={handleSubmit(onSubmit)}>
        {t('update-password')}
      </Button>
    </div>
  );
};

export default UpdatePasswordComponent;

import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTimer } from 'react-timer-hook';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import PaymeBarcodeComponent from './PaymeBarcode.component';
import { numberWithCommas } from '../../../Utils/general.utils';
import { PURCHASE_URL } from '../../../Utils/serviceUrl.utils';
import { servicesRequest } from '../../../Utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';
import { PaymeLogo } from '../../../Assets/Images';
import { DisplayFlexSpaceBetween } from '../../../Assets/GlobalStyled';

const PaymentRequestStatus = {
  ACTIVE: 'PR001',
  EXPIRED: 'PR007',
  ABORTED: 'PR004',
  COMPLETED: 'PR005'
};

const PaymeModalComponent = ({
  url = '',
  price = 0,
  paymentTraceId = '',
  onComplete,
  onFailed
}) => {
  let pollingTime = new Date();
  pollingTime.setSeconds(pollingTime.getSeconds() + 60);

  const dispatch = useDispatch();
  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const closeModal = () => {
    setTimeout(() => {
      onFailed();
    }, 2500);
  };

  const handleExpired = () => {
    showNotification(
      'The PayCode has expired and no longer scan able. You can go back to the order page to make payment again. Thank you.'
    );
    closeModal();
  };
  const handleAborted = () => {
    showNotification(
      'The PayCode has aborted and no longer scan able. You can go back to the order page to make payment again. Thank you.'
    );
    closeModal();
  };

  const handleCompleted = status => {
    onComplete(status);
  };

  const checkPaymentStatus = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const response = await authorizedRequest.get(
        PURCHASE_URL + '/payme/status/' + paymentTraceId
      );

      const errorCode = get(response, 'data.data.status', 200);

      if (errorCode === 400) {
        showNotification(
          'We apologize that your payment is unsuccessful due to some technical issues with the server. We are fixing it. Shall you have further questions, please contact the online store. Thank you.'
        );

        closeModal();
        return;
      }

      const responseData = response?.data?.data?.data;

      const statusCode = responseData?.statusCode;

      switch (statusCode) {
        case PaymentRequestStatus.EXPIRED:
          return handleExpired();
        case PaymentRequestStatus.ABORTED:
          return handleAborted();
        case PaymentRequestStatus.COMPLETED:
          return handleCompleted('COMPLETED');
        default:
          return handlePending();
      }
    } catch (error) {}
  };

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: pollingTime,
    onExpire: async () => {
      await checkPaymentStatus();
    }
  });

  const handlePending = () => {
    let pollingTime = new Date();
    pollingTime.setSeconds(pollingTime.getSeconds() + 10);
    restart(pollingTime);
  };

  useEffect(() => {
    const checkingPaymentStatus = async () => {
      try {
        const authorizedRequest = await servicesRequest();
        const {
          data: { data }
        } = await authorizedRequest.post(
          PURCHASE_URL + '/check-status-payment',
          { paymentTraceId }
        );

        if (data?.paymentStatus === 'COMPLETED') {
          onComplete();
        }
      } catch (error) {
        console.error(error);
      }
    };

    checkingPaymentStatus();

    const intervalId = setInterval(checkingPaymentStatus, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      <img src={PaymeLogo} alt="payme logo" width={200} />
      <h5 className="text-center mt-3">Scan this PayCode with PayMe</h5>
      <p className="text-center m-0">You are paying</p>
      <h3 className="text-center fw-bolder">
        HKD {numberWithCommas(price.toFixed(2))}
      </h3>
      <Row className="mx-5">
        <Col>
          <div className="text-center">
            <PaymeBarcodeComponent url={url} />
          </div>
          <div className="text-center">
            <small className="text-center">
              This PayCode expires in 10 minutes
            </small>
            <p className="text-center fw-semibold m-0">
              Please do not close this page before payment is complete
            </p>
          </div>
        </Col>
      </Row>
      <div className="text-center">
        <small className="text-center text-secondary">
          We will check your payment status in{' '}
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </small>
      </div>

      <hr />
      <div>
        <h6 className="mb-3">Paying with PayMe</h6>
        <DisplayFlexSpaceBetween>
          <p>1. Open the PayMe app.</p>
          <p>2. Scan the PayCode to authorise payment.</p>
          <p>3. Complete payment in the app and wait for confirmation.</p>
        </DisplayFlexSpaceBetween>
      </div>
    </div>
  );
};

export default PaymeModalComponent;

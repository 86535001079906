import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { servicesRequest } from '../../Utils';
import { PRODUCT_CART_URL } from '../../Utils/serviceUrl.utils';
import {
  ActionButtonDetail,
  ActionButtonList,
  BorderedList,
  CardListContainer,
  ContentLabel,
  ContentLabelBold,
  ContentLabelHeader,
  LeftColumnButton
} from '../ProductCard/ProductCard.styled';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';

const ProductCardListMobile = ({ data, carts = [] }) => {
  const [isCart, setIsCart] = React.useState(!carts?.includes(data?.code));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  React.useEffect(() => {
    setIsCart(carts?.includes(data?.code));
  }, [carts, data]);

  const addToCart = async product_code => {
    try {
      const authorizedRequest = await servicesRequest();
      await authorizedRequest.post(PRODUCT_CART_URL, {
        product_code: product_code,
        qty: 1
      });

      showNotification('Successfully added to cart');
      setIsCart(!isCart);
    } catch (e) {
      showNotification('Sorry, we unable to add to cart. Please try again');
    }
  };
  return (
    <>
      <CardListContainer>
        <Row className="p-0 gy-0">
          <Col sm={9} xs={9}>
            <ContentLabelHeader>{data?.name_en}</ContentLabelHeader>
          </Col>
        </Row>
        <BorderedList>
          <Col>
            <ContentLabelBold>{t('code-no')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.code}</ContentLabel>
          </Col>
        </BorderedList>
        <BorderedList>
          <Col>
            <ContentLabelBold>{t('region')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.region}</ContentLabel>
          </Col>
          <Col>
            <ContentLabelBold>{t('appellation')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.appellation}</ContentLabel>
          </Col>
        </BorderedList>
        <BorderedList>
          <Col>
            <ContentLabelBold>{t('price')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.bprice}</ContentLabel>
          </Col>
          {/*<Col>*/}
          {/*  <ContentLabelBold>Now</ContentLabelBold>*/}
          {/*</Col>*/}
          {/*<Col>*/}
          {/*  <ContentLabel>{data?.bprice}</ContentLabel>*/}
          {/*</Col>*/}
          <Col>
            <ContentLabelBold>{t('qty')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.size}</ContentLabel>
          </Col>
        </BorderedList>
        <BorderedList>
          <Col className="pl-0">
            <ActionButtonList
              className={isCart ? 'disabled' : ''}
              onClick={() => {
                addToCart(data?.code);
              }}>
              {t('add-to-cart')}
            </ActionButtonList>
          </Col>
          <LeftColumnButton>
            <ActionButtonDetail
              onClick={() => {
                navigate(`/product-detail/${data?.code}`);
              }}>
              {t('view-details')}
            </ActionButtonDetail>
          </LeftColumnButton>
        </BorderedList>
      </CardListContainer>
    </>
  );
};

export default ProductCardListMobile;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  filterAppellationPayload
} from '../../Redux/Actions/filterAppellation.action';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '../../Constants';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import Icon from '../../Components/Icon';

import { servicesRequest } from '../../Utils';
import { BURGUNDY_URL } from '../../Utils/serviceUrl.utils';
import { FirstTitle, SecondTitle } from '../Blog/Blog.styled';
import { Container, Content, Image, ProductsDisplayContainer, TitleProductDetail, ButtonContainer, StyledDetailButton, ColContainer } from '../Burgundy/Burgundy.styled';
import ProductComponent from './Components/Product.component';

const BurgundyDetailContainer = () => {
  const [burgundy, setBurgundy] = React.useState(null);
  const [filterAppellation, setFilterAppellation] = React.useState('');

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(BURGUNDY_URL + '/sub/detail/' + id);
      setBurgundy(data);
      setFilterAppellation(data.title)
    } catch (e) { }
  };


  const filterAppllationAction = () => {
    dispatch(filterAppellationPayload(filterAppellation));
    navigate(`/${ROUTE.product}`);
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  const iconStyle = {
    color: 'black',
    className: 'global-class-name',
    size: '1.3em',
    fontWeight: 'bold',
    marginLeft: '30px'
  };
  const iconStyle2 = {
    color: 'black',
    className: 'global-class-name',
    size: '1.3em',
    fontWeight: 'bold',
    marginRight: '10px'
  };

  return (
    <Container>
      <Row>
        <ColContainer md={12}>
          <FirstTitle>Map</FirstTitle>
          <SecondTitle>{burgundy?.title}</SecondTitle>
          <Image src={burgundy?.thumbnail}  />
          <Content
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: burgundy?.content }}
          />
        </ColContainer>
      </Row>
      <Row>
        <ProductsDisplayContainer>
          <TitleProductDetail>
            {filterAppellation}’s Wine Products
          </TitleProductDetail>
          <hr className='mb-5' />
          {
            filterAppellation && (
              <ProductComponent filterAppellation={filterAppellation} />
            )
          }
        </ProductsDisplayContainer>
        <ButtonContainer>
          <StyledDetailButton onClick={() => { filterAppllationAction() }}>
            <div className='d-flex align-items-center justify-content-center'>
              <TitleProductDetail>
                View all {filterAppellation}’s Wine
              </TitleProductDetail>
              <Icon style={iconStyle} IconShow={IoIosArrowForward} />
            </div>
          </StyledDetailButton>
        </ButtonContainer>
        <ButtonContainer>
          <StyledDetailButton onClick={() => { navigate('/burgundy') }}>
            <div className='d-flex align-items-center justify-content-center'>
              <Icon style={iconStyle2} IconShow={IoIosArrowBack} />
              <TitleProductDetail>
                Back to Burgundy Map
              </TitleProductDetail>
            </div>
          </StyledDetailButton>
        </ButtonContainer>
      </Row>
    </Container>
  );
};

export default BurgundyDetailContainer;

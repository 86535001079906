import {
  SHOW_HIDE
} from '../Actions/action.types';

const showHideReducer = (state = false, action) => {
  switch (action.type) {
    case SHOW_HIDE:
      return {showHide: action.payload}
    default:
      return state
  }
};
export default showHideReducer;
